import React, { useCallback, useEffect, useState } from "react";
import { Tabs, AutoComplete } from "antd";
import {
    getContactUsFaqList,
    getSearchContactUs,
} from "../../services/contactUs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CiYoutube } from "react-icons/ci";
import {
    MailOutlined,
    HomeOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaPinterestP } from "react-icons/fa";
import search_icon from "../../assets/images/faqs/Frame 1.png";
import { getCustomerSupport } from "../../services/assurance.js";
import Accordion from "react-bootstrap/Accordion";
import ContactForm from "../../components/forms/ContactForm.js";

function getHighlightedText(text, highlight) {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part?.toLowerCase() === highlight?.toLowerCase() ? (
                <span style={{ backgroundColor: "#e8bb49" }}>{part}</span>
            ) : (
                part
            )}
        </React.Fragment>
    ));
}

function ContactUs() {
    const user = useSelector((state) => state.user);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const [options, setOptions] = useState('');
    const [loading, setLoading] = useState(true);
    const handleSearch = (value) => {
        // Here, you would fetch suggestions based on the entered value
        // For simplicity, let's generate some mock suggestions
        // const mockOptions = value ? [`${value}-1`, `${value}-2`, `${value}-3`] : [];
        setOptions(value);
        setSearchValue(value);
    };
    useEffect(() => {
        const handleDataSearch = async () => {
            try {
                const formData = new URLSearchParams();
                formData.append("search_for", "1");
                formData.append("search_data", options);

                const response = await getSearchContactUs(formData, user.token);
                if (response?.status == "1") {
                    // toast.success(response?.message)
                    setFaqList(response?.data);
                } else {
                    // toast.error(STRINGS.FAILED_TO_ADD);
                }
            } catch (error) {
                console.error("Error fetching address data:", error);
            }
        };
        if (options) {
            handleDataSearch();
        }
    }, [options, user]);

    const handleValidationForMobileAndPincode = (e) => {
        const key = e.key;
        const input = e.target.value;
        if (key === "0" && (input === "" || e.target.selectionStart === 0)) {
            e.preventDefault();
        }
        if (
            !(key >= "0" && key <= "9") &&
            key !== "Backspace" &&
            key !== "Tab" &&
            key !== "ArrowLeft" &&
            key !== "ArrowRight"
        ) {
            // If the pressed key is not a number (0-9), the backspace key, or the tab key
            e.preventDefault(); // Prevent typing
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("text");
        const trimmedData = pastedData.trim(); // Remove leading and trailing white spaces
        document.execCommand("insertText", false, trimmedData);
    };

    const [faqList, setFaqList] = useState([]);
    // console.log(faqList,"faqList")

    const getFaqCategoryList = useCallback(async () => {
        setLoading(true);
        const formData = new URLSearchParams();
        formData.append("type", "1");
        try {
            let result = await getContactUsFaqList(formData, user?.token);
            setFaqList(result?.data);
        } catch (e) {
            console.error("error", e);
        } finally {
            setLoading(false);
        }
    }, [user?.token]);

    const [supportData, setSupportData] = useState();

    const handleSupport = async () => {
        try {
            let result = await getCustomerSupport();
            setSupportData(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };
    const [selectedTabKey, setSelectedTabKey] = useState(sessionStorage.getItem("selectedTabKey") || null);
    useEffect(() => {
        // Clear the stored selectedTabKey value from sessionStorage
        sessionStorage.removeItem("selectedTabKey");
        handleSupport();
        getFaqCategoryList();
        setSelectedTabKey(null);
    }, []);

    const items = faqList?.map((faqItem) => ({
        label: faqItem?.name,
        key: faqItem.id.toString(),
        children: (
            <div className="faqs_productDetails">
                <section className="faqs_accor_main_sec1">
                    <Accordion>
                        {faqItem.faq.map((faq, index) => (
                            <Accordion.Item
                                eventKey={index.toString()}
                                key={faq.id.toString()}
                            >
                                <Accordion.Header>{searchValue ? getHighlightedText(faq.question, searchValue) : (faq.question)}</Accordion.Header>
                                <Accordion.Body>
                                    {getHighlightedText(faq.answer, searchValue)}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </section>
            </div>
        ),
    }));

    const handleCheckOutOffer = () => {
        navigate("/offers"); // Assuming navigate is from the navigation prop
    };

    const handleClear = () => {
        setSearchValue("");
        getFaqCategoryList()
        setSelectedTabKey(sessionStorage.getItem("selectedTabKey") || null);
    }
    const handleTabChange = (key) => {
        // Update state with the selected tab key
        setSelectedTabKey(key);
        // Store the selected tab key in sessionStorage
        sessionStorage.setItem("selectedTabKey", key);
    };


    return (
        <>
            <div className="BD_feedback_wrapper">
                <h1>Contact Us!</h1>
                <div className="bd_model_left">
                    <ContactForm />
                    <div className="customerServices">
                        <div className="get_touch">
                            <p>Get in touch directly :</p>
                            <div className="one_lineDflex">
                                <MailOutlined /> <strong> Email us:</strong>{" "}
                                <Link to={`mailto:${supportData?.support_email}`}>
                                    {supportData?.support_email}
                                </Link>
                            </div>
                        </div>
                        <div className="get_touch">
                            <p>Customer Support Hours :</p>
                            <span>
                                <ClockCircleOutlined /> Customer Support Hours:{" "}
                                {supportData?.support_hour}
                            </span>
                        </div>
                        <div className="get_touch">
                            <p>Corporate Address :</p>
                            <span>
                                <HomeOutlined /> {supportData?.corporate_address}
                            </span>
                        </div>
                        <div className="get_touch">
                            <p>Need immediate assistance?</p>
                            <div className="one_lineDflex">
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M41.7796 20.6066C42.0324 18.9108 41.9495 17.1747 41.5309 15.5054C40.978 13.3002 39.8392 11.2118 38.1147 9.4873C36.3902 7.76281 34.3018 6.62409 32.0967 6.07115C30.4274 5.65257 28.6912 5.56967 26.9954 5.82245"
                                        stroke="#333"
                                        strokeWidth="3"
                                        clipRule="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M34.1896 19.8035C34.4604 17.9869 33.8966 16.0699 32.4982 14.6715C31.0997 13.2731 29.1827 12.7092 27.3662 12.98"
                                        stroke="#333"
                                        strokeWidth="3"
                                        clipRule="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M14.3755 8.79423C15.1021 8.79423 15.7715 9.18825 16.1241 9.82349L18.5706 14.2303C18.8909 14.8073 18.9059 15.5052 18.6108 16.0955L16.254 20.8091C16.254 20.8091 16.937 24.3206 19.7954 27.179C22.6538 30.0374 26.1535 30.7086 26.1535 30.7086L30.8664 28.3522C31.4571 28.0568 32.1555 28.0721 32.7327 28.393L37.152 30.85C37.7866 31.2028 38.1802 31.8719 38.1802 32.598L38.1802 37.6715C38.1802 40.2552 35.7803 42.1213 33.3322 41.2952C28.3043 39.5987 20.4996 36.3685 15.5528 31.4216C10.6059 26.4748 7.3757 18.6701 5.67916 13.6422C4.85314 11.1941 6.71923 8.79423 9.30288 8.79423L14.3755 8.79423Z"
                                        fill="none"
                                        stroke="#333"
                                        strokeWidth="3"
                                        strokeLinejoin="round"
                                    />
                                </svg>{" "}
                                <strong> Call us at :</strong>  <Link to={`tel:${supportData?.support_phone}`}>+91-{supportData?.support_phone}
                                </Link>
                            </div>
                        </div>
                        <div className="get_touch">
                            <p>Stay Updated :</p>
                            <span>
                                Join us on our social channels for the latest updates and
                                exclusive offers.
                            </span>
                            <div className="set_icons">
                                <Link
                                    to="https://www.instagram.com/mystorepedia1/"
                                    target="_blank"
                                >
                                    <FaInstagram />
                                </Link>
                                <Link
                                    to="https://www.facebook.com/profile.php?id=61556630913372&is_tour_completed=true"
                                    target="_blank"
                                >
                                    <FaFacebookF />
                                </Link>
                                <Link
                                    to="https://in.pinterest.com/mystorepedia/"
                                    target="_blank"
                                >
                                    <FaPinterestP />
                                </Link>
                                <Link
                                    to="https://www.youtube.com/@mystorepedia"
                                    target="_blank"
                                >
                                    <CiYoutube />
                                </Link>
                            </div>
                        </div>
                        <div className="get_touch">
                            <p>Check out our latest offers :</p>
                            <button onClick={handleCheckOutOffer}>Latest Offers</button>
                        </div>
                        <div className="get_touch">
                            <p>Corporate/Bulk Orders :</p>
                            <span>
                                Please contact our dedicated team for bulk or corporate order
                                inquiries at <Link to={`mailto:${supportData?.support_email}`}>{supportData?.support_email}</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="frequents_anser">
                    <h1>FREQUENTLY ASKED QUESTIONS</h1>
                    <div className="searchbar_faqs">
                        {searchValue == "" ?
                            <img src={search_icon} alt="" />
                            :
                            <div onClick={handleClear}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="svg_crosss"
                                >
                                    <path
                                        d="M8 8L40 40"
                                        stroke="#333"
                                        strokeWidth="3"
                                        clipRule="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 40L40 8"
                                        stroke="#333"
                                        strokeWidth="3"
                                        clipRule="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        }

                        <AutoComplete
                            style={{ width: 200 }}
                            onSearch={handleSearch}
                            placeholder="Search FAQ"
                            value={searchValue}
                        //  onChange={e => setText(e.target.value)}
                        >
                            {/* {options.map((option) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))} */}
                        </AutoComplete>
                    </div>
                    <div className="tabs_faqs">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            // <Tabs defaultActiveKey={items.length > 0 ? items[3].key : null} style={{ marginBottom: 32 }} items={items}  onChange={(key,index) => handleTabChange(key)}/>
                            <Tabs
                                defaultActiveKey={items.length > 0 ? selectedTabKey : null}
                                style={{ marginBottom: 32 }}
                                items={items}
                                onChange={(key) => handleTabChange(key)}
                            />

                        )}
                    </div>
                </div>
            </div >
        </>
    );
}

export default ContactUs;
