import React, { useRef, useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { LiaGreaterThanSolid } from "react-icons/lia";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductDetails } from "../../services/home";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../reducers/cartSlice";
import {
    addOnProduct,
    getAttributeTypeList,
    getPrimaryVarientAttribute,
    getProductRelated,
    getVarientWiseAttribute,
} from "../../services/product";
import {
    getReviewsList,
    getReviewsDetails,
    addLikeReview,
} from "../../services/reviews";
import Drawer from "../../components/drawer/Drawer";
import dayjs from "dayjs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    RightOutlined,
    PlusOutlined,
    MinusOutlined,
    LeftOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import img1 from "../../assets/images/drinkwarecup.png";
import {
    Input,
    Collapse,
    Avatar,
    Modal,
    Checkbox,
    Row,
    Col,
    Pagination,
    Image,
    Upload,
    Radio,
    message,
    Skeleton,
} from "antd";
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";
import axios from "axios";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import hoverImage from "../../assets/images/dining4.png";
import ProductDetailImgSlider from "../../components/ProductDetailImgSlider.jsx";
import NoDataimg from "../../assets/images/noData.png";
import likeButton from "../../assets/images/thumbs-up.png";
import ChangeImg from "../../assets/images/like.png";
import AddToCart from "../../function/AddToCart.js";
import { toast } from "react-toastify";
import { serviceability } from "../../services/ship/courierServiceability.js";
import { addReviews, editReviews } from "../../services/reviews";
import { getAddressData } from "../../services/user/getUserAddress.js";
import Accordion from "react-bootstrap/Accordion";
import BulkDrawer from "../../components/drawer/BulkDrawer.jsx";
import OutOfStock from "../../components/OutOfStock.js";
import BestSeller from "../../components/home/BestSeller.js";
import { addToBuy, clearBuy } from "../../reducers/buySlice.js";
import { comboProduct, getCustomerSupport } from "../../services/assurance.js";
import { addCart } from "../../services/cart/addCart.js";
import { STRINGS } from "../../constants/Constant.js";
import { getFaqDetailList } from "../../services/faq.js";
import Compare from "../../components/Compare.jsx";
import ColorWiseProduct from "../../components/ColorWiseProduct.jsx";
import ProductCoupon from "../../components/ProductCoupon.jsx";
import { addToSlug } from "../../reducers/slugSlice.js";
import { addToCompare, clearCompare } from "../../reducers/compareSlice.js";
import { clearUser } from "../../reducers/userSlice.js";
import ProductPrice from "../../components/ProductPrice.jsx";
import NoData from '../../assets/images/noData.png'
import TrendingProduct from "../../components/productDetails/TrendingProduct.jsx";
import RelatedProducts from "../../components/productDetails/RelatedProducts.jsx";
import ProductFAQ from "../../components/productDetails/ProductFAQ.jsx";
import ProductCombo from "../../components/productDetails/ProductCombo.jsx";

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <div
                style={{
                    color: "#000",
                    fontSize: "24px",
                    position: "absolute",
                    top: "-30px",
                    right: "13px",
                }}
            >
                <RightOutlined />
            </div>
        </div>
    );
};
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <div
                style={{
                    color: "#000",
                    fontSize: "24px",
                    position: "absolute",
                    top: "-30px",
                    left: "-20px",
                }}
            >
                <LeftOutlined />
            </div>
        </div>
    );
};
const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ProductDetail = () => {
    const { slug } = useParams();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("dimensions");
    const [productCount, setProductCount] = useState(1);
    const [productDetails, setProductDetails] = useState(null);
    const [ratingData, setProductRating] = useState(null);
    const [imgPath, setimgPath] = useState(null);
    const [trendingProduct, setTrendingProducts] = useState([]);
    const user = useSelector((state) => state.user);
    const { token } = user;
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [reviews, setReviews] = useState([]);
    const user_id = localStorage.getItem("user");
    const newUserID = JSON.parse(user_id);
    const [productVariant, setProductVariant] = useState([]);
    const [colorImgPath, setColorImgPath] = useState([]);
    const [reviewDetail, setReviewDetail] = useState(null);
    const [toggleAddToCart, settoggleAddToCart] = useState(true);
    const [is_wishlistt, setIs_wishlist] = useState(null);
    const [updatePinCode, setUpdatePinCode] = useState([]);

    const [isVariant, setIsVariant] = useState(false);
    const [showVariantListing, setShowVariantListing] = useState(false);
    const [productDefaultData, setProductDefaultData] = useState([]);
    const [defaulColor, setDafaultColor] = useState("");

    const [productAttributesData, setProductAttributesData] = useState([]);
    const [productIdd, setProductIdd] = useState(null);
    const [colorSelectedValue, setColorSelectedValue] = useState(null);
    const [sizeSelectedValue, setSizeSelectedValue] = useState(null);
    const [setOfSelectedValue, setSetOfSelectedValue] = useState(null);
    const [defaultSelected, setDefaultSelected] = useState({});

    const [attributeTypes, setAttributeTypes] = useState([]);
    const [sellerCategory, setSellerCategory] = useState([]);
    const [isProLoading, setsProLoading] = useState(false);
    const [comboProductId, setComboProductId] = useState(null);
    const [comboProductCategoryId, setComboProductCategoryId] = useState(null);

    const handleRadioChangeForColor = (e) => {
        setColorSelectedValue(e.target.value);
    };

    const handleRadioChangeForSize = (e) => {
        setSizeSelectedValue(e.target.value);
    };

    const handleRadioChangeForSetOf = (e) => {
        setSetOfSelectedValue(e.target.value);
    };

    // console.log(isOutOfStock,'OutOfStock===========================') ;
    let dataSlug;
    let route;
    let from;
    let menuSlug;
    let categorySlug;
    // let ProductId;
    if (location.state) {
        from = location.state.from;
        menuSlug = location.state.menuSlug;
        route = location.state.route;
        categorySlug = location.state.categorySlug;
        dataSlug = location.state.dataSlug;
        // ProductId = location.state.ProductId;
    }

    const navigate = useNavigate();

    useEffect(() => {
        const handleBackNavigation = (event) => {
            navigate(-1);
        };

        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navigate]);

    const onClose = () => {
        setOpenDrawer(false);
    };
    const [state, setState] = useState({
        backgroundImage: "",
        backgroundPosition: "0% 0%",
    });
    const [updateImageIndex, setUpdateImageIndex] = useState({
        images: [
            img1,
            img1,
            img1,
            // Add more image URLs as needed
        ],
    });
    const onChange = (key) => {
        // console.log(key);
    };

    const handleRatingClick = () => {
        setActiveTab("reviews");
    };

    const [submitted, setSubmitted] = useState(false);
    const ratingChanged = (newRating) => {
        setRating(newRating);
        setSubmitted(false);
    };
    const text = (
        <div className="SubCategory">
            {productDetails && productDetails?.producttypes?.type_name && (
                <div className="prodcut_collapse_items">
                    <p>Type : </p>
                    <span>{productDetails.producttypes?.type_name}</span>
                </div>
            )}
            {defaulColor && (
                <div className="prodcut_collapse_items">
                    <p>Color : </p>
                    <span>{defaulColor}</span>
                </div>
            )}
            {productDetails && productDetails?.productmaterials?.material_name && (
                <div className="prodcut_collapse_items">
                    <p>Material : </p>
                    <span> {productDetails?.productmaterials?.material_name}</span>
                </div>
            )}
            {productDetails && productDetails.Feature && (
                <div className="prodcut_collapse_items">
                    <p>Feature : </p>
                    <span>{productDetails?.Feature}</span>
                </div>
            )}
            {productDetails && productDetails.pr_variant?.product_size?.size && (
                <div className="prodcut_collapse_items">
                    <p>Size : </p>
                    <span>{productDetails.pr_variant.product_size.size}</span>
                </div>
            )}
            {productDetails && productDetails.pr_variant?.product_color?.color && (
                <div className="prodcut_collapse_items">
                    <p>Color : </p>
                    <span>{productDetails.pr_variant.product_color.color}</span>
                </div>
            )}
            {productDetails && productDetails.pack_contain && (
                <div className="prodcut_collapse_items">
                    <p>Pack Contain : </p>
                    <span>{productDetails.pack_contain}</span>
                </div>
            )}
            {productDetails && productDetails.description && (
                <p
                    dangerouslySetInnerHTML={{ __html: productDetails.description }}
                ></p>
            )}
        </div>
    );

    const textInfo = (
        <div className="SubCategory">
            {" "}
            <div className=" careInstruction">
                <h4>Product Details</h4>
                <div className="">
                    {/* <div className="prodcut_collapse_items">
                        <p>Manufacturing Area : </p>
                        <span>India</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Material : </p>
                        <span>Ceramic</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Dimension : </p>
                        <span>11.5 x 11.5 x 8 (LxBxH in centimeters)</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Weight : </p>
                        <span>350 grams (per piece)</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Capacity : </p>
                        <span>100 ml</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Quantity : </p>
                        <span>6, Six Tea Cup Sets</span>
                    </div>
                    <div className="prodcut_collapse_items">
                        <p>Feature : </p>
                        <span>Non-porous, easy to clean, handmade</span>
                    </div>
                    <div className="prodcut_collapseAdd">
                        <p>Electronics : </p>
                        <ul>
                            <li>Microwave: Yes</li>
                            <li>Dishwasher: Yes</li>
                            <li>Hand wash: Yes</li>
                            <li>Oven: No</li>
                            <li>Direct Fire: No</li>
                        </ul>
                    </div> */}

                    {productDetails && (
                        <div className="" dangerouslySetInnerHTML={{ __html: productDetails.additional_description }} />
                    )}
                </div>
            </div>
        </div>
    );

    // Not delete
    const textOver = (
        <span>
            {productDetails && productDetails.product_overview && (
                <p
                    dangerouslySetInnerHTML={{ __html: productDetails.product_overview }}
                ></p>
            )}
        </span>
    );
    const items = [
        {
            key: "1",
            label: " Product Description",
            children: <p>{text}</p>,
        },
        productDetails &&
        productDetails?.additional_description && {
            key: "2",
            label: "Additional Information",
            children: <p>{textInfo}</p>,
        },
        // productDetails &&
        // productDetails?.product_overview && {
        //     key: "3",
        //     label: "Overview",
        //     children: <p>{textOver}</p>,
        // },
    ].filter(Boolean); // code change by vivek
    const [reviewCount, setReviewCount] = useState(0);
    const [relatedData, setRelatedData] = useState([]);
    const [singleCategorySlug, setSingleCategorySlug] = useState(null);
    const [colorImgPathVar, setColorImgPathVar] = useState(null);
    const [ReviewsImagePath, setReviewsImagePath] = useState();
    // const [colorImgPathNonVar, setColorImgPathNoVar] = useState(null);

    const hasMounted = useRef(false);

    const [singleCompareData, setSingleCompareData] = useState(null);
    const [statusCheck, setStatusCheck] = useState(null);
    const fetchDataAndRelatedData = async (data) => {
        const newSlug = data || slug;
        const formData = new URLSearchParams();
        formData.append("user_id", user?.id || 0);
        // formData.append("id", ProductId);
        try {
            const response = await getProductDetails(newSlug, formData);
            // console.log('dataslugresponse=====vivek', response);
            setStatusCheck(response?.data?.status);

            if (response.status === "1") {
                setSingleCompareData(response);
                setSingleCategorySlug(response?.category[0]?.slug);
                // Destructure the required data from the response
                const { id = 0 } = response?.data;
                //variant is 1
                if (response?.is_variant == 1) {
                    //is variant = 1
                    setShowVariantListing(true);
                    setIsVariant(true);
                    setProductAttributesData(response?.product_attribute);

                    let sortedProductAttribute = response?.product_attribute?.sort(
                        (a, b) => a.attr_type_id - b?.attr_type_id
                    );
                    // console.log('sortedProductAttribute===323===>>>', sortedProductAttribute);

                    // To check which is the Primary variant:- Color, Size or Set-Of.

                    let primaryVariant = sortedProductAttribute.filter((item) => item?.attr_type_id == response?.primary_variant_detail?.variants?.attr_type_id
                    );

                    // console.log('primaryVariant[0]=====', primaryVariant[0]);
                    if (primaryVariant?.length > 0) {
                        fetchAttbtTypeList(
                            primaryVariant[0]?.attr_val_id,
                            id,
                            response?.product_attribute,
                            primaryVariant[0]?.attr_type_id
                        ); // 2nd Api
                    }
                }

                // When variant is 0 and default variant (product attribute array is not []) is not empty
                else if (
                    response?.is_variant == 0 &&
                    response?.product_attribute.length > 0
                ) {
                    //is variant = 0
                    setShowVariantListing(true);
                    setIsVariant(false);
                    setProductDefaultData(response?.product_attribute);
                    let defaultColorName = response?.product_attribute?.find(
                        (item) => item.attr_type_id == 2
                    )?.product_attr_value?.attr_val_name;
                    setDafaultColor(defaultColorName);
                } else if (
                    response?.is_variant == 0 &&
                    response?.product_attribute.length < 1
                ) {
                    setIsVariant(false);
                    setShowVariantListing(false);
                }
                //
                const productImages = response.data.productimages;
                const firstImageFileName = productImages[0]?.file_name;
                setProductDetails(response.data);
                fetchReviews(response.data);
                productRelatedData(response?.data);
                setSellerCategory(response?.category?.map((val) => val?.id));
                // getproductCoupon(response.data)
                handleComboProduct(response.data);
                setComboProductId(response.data?.id);
                setComboProductCategoryId(response.data?.category_id)
                setProductRating(response.rating);
                setProductVariant(response?.product_variant);
                setIs_wishlist(response?.is_wishlist);
                setimgPath(response.path);
                if (response?.product_buffer_days !== "") {
                    localStorage.setItem(
                        "bufferdays",
                        response?.data?.product_buffer_days
                    );
                }
                setColorImgPath(response.color_img_path);
                setReviewCount(response?.is_review_count);
                setState({
                    backgroundImage: `url(${response.path}${firstImageFileName})`,
                    backgroundPosition: "0% 0%",
                });
                setUpdateImageIndex({
                    img: `${response.path}${firstImageFileName}`,
                });

                //Fetch related data only if relatedId is not null
                if (response?.data?.category_id !== null) {
                    const formData = new URLSearchParams();
                    formData.append("product_id", Number(response?.data?.id));
                    formData.append("related_type", "related");
                    formData.append("user_id", newUserID?.id ? newUserID?.id : 0);
                    const responseRelatedData = await getProductRelated(formData);
                    // console.log('responseRelatedData', responseRelatedData);
                    setRelatedData(responseRelatedData?.data); // Store related data in state
                }
                // up sell
                if (response?.data?.category_id !== null) {
                    const formData = new URLSearchParams();
                    formData.append("product_id", Number(response?.data?.id));
                    formData.append("related_type", "up_sell");
                    formData.append("user_id", newUserID?.id ? newUserID?.id : 0);

                    const responseRelatedData = await getProductRelated(formData);
                    // console.log('responseRelatedData', responseRelatedData);
                    setTrendingProducts(responseRelatedData?.data);
                }
            }
            if (response.status === 204) {
                // alert('see');
                const slugIfo = `product/${slug}`;
                dispatch(addToSlug({ slug: slugIfo }));
                navigate(`/${slugIfo}`);
            }
        } catch (error) {
            if (error.response?.data?.status === "0") {
                // console.log('error.response?.data?.status', error.response?.data?.status);
                // navigate(Routes.Error);
            }
            console.error("Error fetching data:", error);
        }
    };

    const variantSlugNavigate = (slug) => {
        navigate(Routes?.ProductDetail + "/" + slug);
        // fetchDataAndRelatedData(slug);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchDataAndRelatedData();
        hasMounted.current = true;
    }, [slug]);

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    // Fetch Attribute types list
    const fetchAttbtTypeList = async (val, prodId, prodArr, primTypeId) => {
        try {
            const attributeTypeListresponse = await getAttributeTypeList(); // First API
            if (attributeTypeListresponse.status != 1) {
                throw new Error("Failed to fetch attribute type list");
            }
            setColorImgPathVar(attributeTypeListresponse?.path);

            const promises = attributeTypeListresponse?.data?.map(
                async (attribute, index) => {
                    const formData = new URLSearchParams();
                    formData.append("product_id", prodId);
                    formData.append("attr_type_id", attribute?.id);

                    const childResponse = await getPrimaryVarientAttribute(formData); // Second API
                    if (childResponse?.status != 1) {
                        throw new Error("Failed to fetch primary variant attribute");
                    }

                    const primaryObj = childResponse?.data?.find(
                        (item) => item?.attr_type_id == primTypeId
                    );

                    let thirdResponse = "";
                    if (index == attributeTypeListresponse?.data?.length - 1) {
                        // console.log('prodArr===451==', prodArr);
                        // console.log('prodArr[index].attr_val_id==452==',primTypeId==2 ? prodArr[index-1].attr_val_id : prodArr[index-2].attr_val_id);
                        const thirdFormData = new URLSearchParams();
                        thirdFormData.append("attr_val_id", val);
                        thirdFormData.append(
                            "attr_second_val_id",
                            primTypeId == 2
                                ? prodArr[index - 1].attr_val_id
                                : prodArr[index - 2].attr_val_id
                        );
                        thirdFormData.append("attr_type_id", attribute?.id);
                        thirdFormData.append(
                            "variant_id",
                            childResponse?.variant_detail?.variant_id
                        );

                        thirdResponse = await getVarientWiseAttribute(thirdFormData); // Third API
                        if (thirdResponse?.status != 1) {
                            throw new Error("Failed to fetch variant wise attribute");
                        }
                    } else {
                        // console.log('prodArr[index].attr_val_id==465', prodArr[index].attr_val_id);
                        const thirdFormData = new URLSearchParams();
                        thirdFormData.append("attr_val_id", val);
                        thirdFormData.append(
                            "attr_second_val_id",
                            attribute?.id == 3 ? prodArr[index - 1].attr_val_id : ""
                        );
                        thirdFormData.append("attr_type_id", attribute?.id);
                        thirdFormData.append(
                            "variant_id",
                            childResponse?.variant_detail?.variant_id
                        );

                        thirdResponse = await getVarientWiseAttribute(thirdFormData); // Third API
                        if (thirdResponse?.status != 1) {
                            throw new Error("Failed to fetch variant wise attribute");
                        }
                    }

                    // console.log('thirdResponse.data===465===>>>>>', thirdResponse);
                    ////
                    let newArr = thirdResponse?.data?.filter((item) =>
                        prodArr?.some((attr) => attr?.attr_val_id == item?.attr_val_id)
                    );

                    const mergeArrays = (allData, data) => {
                        // Create a set of data item identifiers for quick lookup
                        const dataSet = new Set(
                            data.map((item) => `${item.attr_type_id}-${item.attr_val_id}`)
                        );

                        // Get items from data array with 'active' key
                        const activeItems = data.map((item) => {
                            const identifier = `${item.attr_type_id}-${item.attr_val_id}`;
                            return {
                                ...item,
                                active: "active",
                            };
                        });

                        // Get items from allData array that are not in data array, with 'not active' key
                        const notActiveItems = allData
                            .filter(
                                (item) =>
                                    !dataSet.has(`${item.attr_type_id}-${item.attr_val_id}`)
                            )
                            .map((item) => ({
                                ...item,
                                active: "not active",
                            }));

                        // Combine active and not active items
                        return [...activeItems, ...notActiveItems];
                    };

                    const mergedArray = mergeArrays(
                        thirdResponse?.all_data,
                        thirdResponse?.data
                    );
                    // console.log('mergedArray===', mergedArray);
                    // setDefaultSelected(newArr[0]?.attr_val_id);
                    defaultSelected[attribute?.id] = newArr[0]?.attr_val_id;
                    // console.log('defaultSelected===',defaultSelected);
                    setDefaultSelected(defaultSelected);
                    // console.log('primTypeId=========448==',primTypeId);
                    return {
                        attribute,
                        primaryChildrenData: childResponse?.data,
                        childreData: mergedArray,
                        primaryVariantId: primTypeId,
                        primaryObj: primaryObj,
                    };
                }
            );

            const results = await Promise.all(promises);
            // console.log('results===456===>>>>>', results);

            results.sort((a, b) => {
                if (a.primaryObj && !b.primaryObj) {
                    return -1; // a comes before b
                } else if (!a.primaryObj && b.primaryObj) {
                    return 1; // b comes before a
                } else {
                    return 0; // No change in order
                }
            });

            setAttributeTypes(results);
        } catch (error) {
            console.error("Error:", error);
        }

        // Color based code

        // try {
        //     const attributeListResult = await getAttributeTypeList();
        //     if (attributeListResult.status == '1') {

        //         if (attributeListResult?.data[0]?.id) {
        //             const formData = new URLSearchParams();
        //             formData.append('product_id', prodId);
        //             formData.append('attr_type_id', attributeListResult?.data[0]?.id);
        //             try {
        //                 const primarayVarAtt = await getPrimaryVarientAttribute(formData);  // 2nd api
        //                 var defaultVal = primarayVarAtt?.data?.filter(item => item?.attr_val_id == val)

        //                 if (defaultVal.length == 1) {
        //                     setColorSelectedValue(defaultVal[0]?.attr_val_id); // For showing default selected color
        //                     const formData = new URLSearchParams();
        //                     formData.append('attr_val_id', defaultVal[0]?.attr_val_id);
        //                     formData.append('attr_type_id', attributeListResult?.data[1]?.id);
        //                     formData.append('variant_id', primarayVarAtt?.variant_detail?.variant_id);

        //                     let resultForSizes = await getVarientWiseAttribute(formData);        // 3rd APi
        //                     // console.log('resultForSizes', resultForSizes);
        //                     if (resultForSizes?.status === '1') {
        //                         // console.log(thirdApiResult?.data,'thirdApiResult?.data')
        //                         // For showing default selected 'Set'
        //                         let newArr = resultForSizes?.data?.filter(item => prodArr?.some(attr => attr?.attr_val_id == item?.attr_val_id));
        //                         setSizeSelectedValue(newArr[0]?.attr_val_id);
        //                         setSizeList(resultForSizes?.data);
        //                     }
        //                 }
        //                 setColorList(primarayVarAtt?.data);
        //                 setColorImgPathVar(primarayVarAtt?.path);

        //             } catch (error) {
        //                 console.log('error', error);
        //             }
        //         }
        //         if (attributeListResult?.data[1]?.id) {
        //             const formDataa = new URLSearchParams();
        //             formDataa.append('product_id', prodId);
        //             formDataa.append('attr_type_id', attributeListResult?.data[1]?.id);
        //             try {
        //                 const primarayVarAtt = await getPrimaryVarientAttribute(formDataa);
        //                 // console.log('primarayVarAtt.data==385', primarayVarAtt.data);
        //                 // setSizeList(primarayVarAtt.data)
        //                 const formDataSet = new URLSearchParams();
        //                 formDataSet.append('attr_val_id', defaultVal[0]?.attr_val_id);
        //                 formDataSet.append('attr_type_id', attributeListResult?.data[2]?.id);
        //                 formDataSet.append('variant_id', primarayVarAtt?.variant_detail?.variant_id);

        //                 let resultForSets = await getVarientWiseAttribute(formDataSet);        // 3rd APi
        //                 if (resultForSets?.status === '1') {
        //                     // console.log(resultForSets?.data, 'resultForSets?.data')

        //                     // For showing default selected 'Set'
        //                     let newArr = resultForSets?.data?.filter(item => prodArr?.some(attr => attr?.attr_val_id == item?.attr_val_id));
        //                     // setSetsOfList(newArr);
        //                     setSetOfSelectedValue(newArr[0]?.attr_val_id); // For showing default selected color
        //                     setSetsOfList(resultForSets?.data);
        //                 }
        //             } catch (error) {
        //                 console.log('error', error);
        //             }
        //         }

        //         // When user click on size and filter the Set-Of
        //         // if (dummyState) {
        //         //     let newSetArr = setsOfList?.filter(item =>prodArr?.some(attr=>attr?.attr_val_id==item?.attr_val_id));
        //         //     console.log('newSetArr===>>>',newSetArr);
        //         //     setSetsOfList(newSetArr);
        //         //     setDummyState(false);
        //         // }
        //         // if (attributeListResult?.data[2]?.id) {
        //         //     const formData = new URLSearchParams();
        //         //     formData.append('product_id', productIdd);
        //         //     formData.append('attr_type_id', attributeListResult?.data[2]?.id);
        //         //     try {
        //         //         const primarayVarAtt = await getPrimaryVarientAttribute(formData);
        //         //         // console.log('primarayVarAtt.data==399', primarayVarAtt.data);
        //         //         setSetsOfList(primarayVarAtt.data)

        //         //     } catch (error) {
        //         //         console.log('error', error);
        //         //     }
        //         // }
        //     }
        // } catch (error) {
        //     console.log(error, 'e');
        // }
    };

    // Changes and optimizations:
    const handleCounts = () => {
        if (productCount <= 1) {
            return;
        }
        setProductCount((prevCount) => prevCount - 1);
    };
    const handleCountInc = (stock_quantity) => {
        if (productCount < stock_quantity) {
            setProductCount(productCount + 1);
        } else {
            toast.error("Oops, the count has exceeded the available stock", {
                toastId: "1",
            });
        }
    };
    let cart = useSelector((state) => state.cart);
    const handleBuyNowClick = () => {
        if (user?.id) {
            // setOpenDrawer(true);
            let newItems = [];
            const {
                id,
                product_name,
                compare_price,
                product_slug,
                is_wishlist,
                weight,
                unit,
                stock_quantity,
                shipping_amount_type,
                shipping_charge,
            } = productDetails;
            const image = `${imgPath}${productDetails?.productimages[0]?.file_name}`;
            // Create a new item with the required information
            const price = applied ? appliedPrice : productDetails?.price;
            const newItem = {
                id,
                product_name,
                product_slug,
                is_wishlist,
                price,
                compare_price,
                shipping_amount_type,
                shipping_charge,
                image,
                count: productCount,
                p_variant_id: 0,
                weight,
                unit,
                stock_quantity,
            };
            // Check if the item already exists in the cart
            // const existingItemIndex = cart.findIndex(item => item.id === newItem.id);
            newItems.push(newItem);
            // if (existingItemIndex === -1) {
            //     if (Object.isExtensible(cart)) {
            //         cart.push(newItem);
            //     } else {
            //         // If the cart is not extensible, create a new array with the existing items and the new item
            //         cart = [...cart, newItem];
            //     }
            // }

            dispatch(addToBuy(newItems));
            localStorage.setItem("buy", JSON.stringify(newItems));
            applied &&
                localStorage.setItem("productCoupon", JSON.stringify(productCouponId));
            navigate("/checkout");
        } else {
            const from = `${Routes?.ProductDetail}/${slug}`; // Corrected the template literal syntax
            navigate(Routes?.SignIn, { state: { from } });
        }
    };
    const buy = useSelector((state) => state.buy) || [];
    useEffect(() => {
        if (buy.length > 0) {
            const cart = JSON.parse(localStorage.getItem("cart")) || [];
            buy.forEach((item) => {
                const existingItemIndex = cart.findIndex(
                    (cartItem) => cartItem.id === item.id
                );
                if (existingItemIndex !== -1) {
                    // Update the count of the existing item
                    const updatedCartItem = {
                        ...cart[existingItemIndex],
                        count: cart[existingItemIndex].count + item.count,
                    };
                    cart[existingItemIndex] = updatedCartItem;
                } else {
                    // Add the new item to the cart
                    cart.push(item);
                }
            });

            localStorage.setItem("cart", JSON.stringify(cart));
            dispatch(addToCart(cart));
            dispatch(clearBuy());
            localStorage.removeItem("buy");
        }
    }, []);

    const updateCart = (updatedCart) => {
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        // Assuming you have a dispatch function available
        dispatch(addToCart(updatedCart));
        settoggleAddToCart(true);
    };
    const handleRemoveToCart = (id) => {
        const updatedCart = cart.filter((item) => item.id !== id);
        updateCart(updatedCart);
    };

    const [rating, setRating] = useState(0);
    const [feedbackText, setFeedbackText] = useState("");

    const handleCancel = () => {
        fetchDataAndRelatedData();
        if (reviewDetail !== null) {
            setRating(reviewDetail?.rating);
            setFeedbackText(reviewDetail?.review);
        }
    };
    const [isModalOpenPin, setIsModalOpenPin] = useState(false);
    const [isChecked, setIsChecked] = useState();

    const [addressList, setAddressList] = useState([]);
    const showPincode = async () => {
        setIsModalOpenPin(true);
        try {
            let result = await getAddressData(token);
            setAddressList(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };

    useEffect(() => {
        const defaultChecked = addressList?.find((val) => val?.is_default === 1);
        if (defaultChecked) {
            setIsChecked(defaultChecked);
        }
    }, [addressList]);

    const handleOkPin = (defaultValue) => {
        handlePincode(defaultValue?.zip_code);
        setPincode(defaultValue?.zip_code);
        setIsModalOpenPin(false);
    };
    const handleCancelPin = () => {
        setIsModalOpenPin(false);
    };

    const handleCheckboxChange = (index) => {
        setIsChecked(index);
    };
    const handleFeedbackTextChange = (e) => {
        const text = e.target.value;
        setFeedbackText(text);
        setSubmitted(false);
    };

    const [oldImage, setOldImage] = useState([]);
    const handleSubmit = async () => {
        setSubmitted(true);
        if (user?.id) {
            if (!rating) {
                toast.error("Rating is required");
                return;
            }
            const formData = new FormData();
            formData.append("rating", rating);
            formData.append("review", feedbackText);
            fileList.forEach((file) => {
                formData.append("file", file.originFileObj);
            });
            formData.append("old_image_id", JSON.stringify(oldImage));
            // const ttoken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjM2LCJyb2xlX2lkIjoyLCJpYXQiOjE3MTUyNTYzNzksImV4cCI6MTcxNzg0ODM3OX0.DyA7s8O6ELDJ27ZyHu7RG0hVclCAPcHeXhTo81oVYCI";
            if (reviewCount !== 0) {
                formData.append("id", reviewDetail?.id);
                try {
                    const response = await editReviews(formData, token);
                    // console.log("editReviewseditReviewseditReviewseditReviewseditReviews", response);
                    if (response.status === "1") {
                        handleCancel();
                        setRating(response?.data?.rating);
                        setFeedbackText(response?.data?.review);
                        toast.success("Review updated successfully");
                    }
                } catch (error) {
                    if (error?.response?.data?.message === "Invalid token") {
                        const from = `${Routes?.ProductDetail}/${slug}`; // Corrected the template literal syntax
                        dispatch(clearUser());
                        navigate(Routes?.SignIn, { state: { from } });
                    }
                    // console.error("Error fetching data:", error);
                }
            } else {
                formData.append("product_id", productDetails?.id);
                try {
                    const response = await addReviews(formData, token);
                    if (response.status === "1") {
                        // handleCancel()
                        fetchDataAndRelatedData();
                        toast.success("Review added successfully");
                    }
                } catch (error) {
                    if (error?.response?.data?.message === "Invalid token") {
                        const from = `${Routes?.ProductDetail}/${slug}`; // Corrected the template literal syntax
                        dispatch(clearUser());
                        navigate(Routes?.SignIn, { state: { from } });
                    }
                }
            }
        } else {
            navigate("/signin");
        }
    };

    const fetchReviews = async (p_id) => {
        const formData = new URLSearchParams();
        formData.append("product_id", p_id?.id);
        formData.append("user_id", user?.id || 0);
        try {
            const response = await getReviewsList(formData);
            if (response.status === "1") {
                setReviews(response?.data);
                setReviewsImagePath(response?.path);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const relatedSettings = {
        dots: true,
        infinite: relatedData?.length > 4 ? true : false,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const trendingSettings = {
        dots: true,
        infinite: trendingProduct?.length > 4 ? true : false,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [pincode, setPincode] = useState();
    const [pinVerified, setPinVerified] = useState(false);
    const [ip, setIP] = useState("");
    const [country, setCountry] = useState("");

    const getIP = async () => {
        try {
            const res = await axios.get("https://api.ipify.org/?format=json");
            setIP(res.data.ip);
            return res.data.ip;
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    };

    const getCountry = async (ip) => {
        try {
            const res = await axios.get(`https://ipapi.co/${ip}/country/`);
            setCountry(res.data);
        } catch (error) {
            console.error("Error fetching country:", error);
        }
    };

    const fetchData = async () => {
        const ip = await getIP();
        if (ip) {
            await getCountry(ip);
        }
    };
    const [productCoupon, setProductCoupon] = useState([]);

    // const getproductCoupon = async (p_id) => {
    //     // console.log('getproductCoupon',productDetails);
    //     // console.log('getproductCoupon p_id',p_id);
    //     const formData = new URLSearchParams();
    //     formData.append('product_id', p_id?.id);
    //     try {
    //         const response = await getProductCoupon(formData);
    //         if (response.status === '1') {
    //             setProductCoupon(response?.data)
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // }
    const [productCombo, setProductCombo] = useState([]);
    // console.log(productCombo, "productCombo")
    const [suggetOthers, setSuggestOthers] = useState([]);
    // console.log(suggetOthers, "suggetOtherssuggetOthers")
    const [firstIndexDataChecked, setFirstIndexDataChecked] = useState(true);
    const [firstIndexData, setFirstIndexData] = useState();
    // console.log(firstIndexData, "firstIndexData")
    const [comboImages, setComboImages] = useState();
    const [page, setPage] = useState();
    const [comboChecked, setComboChecked] = useState(
        Array(productCombo.length).fill(true)
    );

    const handleComboProduct = async (p_id) => {
        const formData = new URLSearchParams();
        formData.append("product_id", p_id?.id);
        formData.append("category_id", p_id?.category_id);

        try {
            const response = await comboProduct(formData);
            if (response.status === "1") {
                const firstIndexId = response?.data[0]?.id;
                const filteredData = response?.data.filter(item => item.id !== firstIndexId);
                setProductCombo(response?.data.slice(1, 3));
                setFirstIndexData(response?.data[0]);
                setSuggestOthers(filteredData);
                setComboImages(response?.path);
                setPage(response?.pagination);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleComboCheckbox = (index) => {
        const newCheckedStates = [...comboChecked];
        newCheckedStates[index] = !newCheckedStates[index];
        setComboChecked(newCheckedStates);
    };

    const checkedData = productCombo.filter(
        (checkedComboItem, index) => !comboChecked[index]
    );
    const newArray = [...checkedData, firstIndexData];
    const totalPrice = newArray.reduce(
        (total, comboItem) => total + comboItem?.price,
        0
    );

    const handleCombo = () => {
        const addItemToCart = (item) => {
            const cartData = JSON.parse(localStorage.getItem("cart")) || [];
            const {
                id,
                product_name,
                product_slug,
                is_wishlist,
                price,
                compare_price,
                weight,
                unit,
                stock_quantity,
                shipping_amount_type,
                shipping_charge,
            } = item;
            const image = `${comboImages}${item?.productimages?.[0]?.file_name_120_x_120}`;
            const newItem = {
                id,
                product_name,
                product_slug,
                is_wishlist,
                price,
                image,
                shipping_amount_type,
                shipping_charge,
                count: 1,
                p_variant_id: 0,
                compare_price,
                weight,
                unit,
                stock_quantity,
            };

            const productForDB = {
                product_id: newItem.id,
                product_variant_id: newItem.p_variant_id,
                quantity: newItem.count,
                amount: newItem.price,
            };

            const existingItemIndex = cartData?.findIndex(
                (cartItem) => cartItem.id === newItem.id
            );

            let updatedCart;
            if (existingItemIndex === -1) {
                updatedCart = Object.isExtensible(cartData)
                    ? [...cartData, newItem]
                    : [...cartData, newItem];
            } else {
                updatedCart = [...cartData];
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    count: updatedCart[existingItemIndex].count + 1,
                };
            }
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            dispatch(addToCart(updatedCart));

            if (user.token) {
                addCartItemToDB([productForDB]);
            }
        };

        for (const item of newArray) {
            addItemToCart(item);
        }
    };

    const addCartItemToDB = async (product) => {
        const result = await addCart(product, user.token);
        if (result?.status == "1") {
            toast.success(STRINGS.ADDED_TO_CART_SUCCESSFULLY, { toastId: "1" });
        }
    };

    const handleBuyCombo = () => {
        if (user?.id) {
            const addItemToCart = (item) => {
                const existingBuyItems = JSON.parse(localStorage.getItem("buy")) || [];
                let newItem;
                const {
                    id,
                    product_name,
                    price,
                    compare_price,
                    product_slug,
                    is_wishlist,
                    weight,
                    unit,
                    stock_quantity,
                    shipping_amount_type,
                    shipping_charge,
                } = item;
                const image = `${imgPath}${item?.productimages?.[0]?.file_name}`;
                newItem = {
                    id,
                    product_name,
                    product_slug,
                    is_wishlist,
                    price,
                    compare_price,
                    shipping_amount_type,
                    shipping_charge,
                    image,
                    count: productCount,
                    p_variant_id: 0,
                    weight,
                    unit,
                    stock_quantity,
                };
                const updatedBuyItems = [...existingBuyItems, newItem];
                dispatch(addToBuy(updatedBuyItems));
                localStorage.setItem("buy", JSON.stringify(updatedBuyItems));
                navigate("/checkout");
            };

            for (const item of newArray) {
                addItemToCart(item);
            }
        } else {
            navigate(Routes?.SignIn);
        }
    };
    const [remainingIndex, setRemainingIndex] = useState(2);

    const handleOthersCombo = async () => {
        let nextIndex = (remainingIndex + 1) % suggetOthers.length;

        const nextComboProducts = [
            suggetOthers[nextIndex],
            suggetOthers[(nextIndex + 1) % suggetOthers.length],
        ];
        setRemainingIndex(nextIndex);
        setProductCombo(nextComboProducts);
    };

    const [faq, setFaq] = useState([]);
    useEffect(() => {
        if (productDetails?.id) {
            const faqList = async () => {
                try {
                    const formData = new URLSearchParams();
                    formData.append("product_id", productDetails.id);
                    const response = await getFaqDetailList(formData);
                    if (response.status === "1") {
                        setFaq(response?.data?.faq);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            faqList();
        }
    }, [productDetails?.id]);

    useEffect(() => {
        fetchData();
        handleSupport();
        // if(productDetails?.product_buffer_days !== ""){
        //     console.log('productDetails?.product_buffer_days',productDetails?.product_buffer_days);
        //     localStorage.setItem('bufferdays', productDetails?.product_buffer_days)
        // }
    }, []);

    useEffect(() => {
        if (reviewDetail?.id) {
            setRating(reviewDetail?.rating);
            setFeedbackText(reviewDetail?.review);
        }
        if (reviewCount === 0) {
            setRating(0);
            setFeedbackText("");
        }
    }, [reviewDetail?.id, reviewCount]);
    const [pincodeError, setPincodeError] = useState();

    const pincodeChange = (e) => {
        const newPincode = e.target.value;
        setPincode(e.target.value);
        setPinVerified(false);
        if (newPincode?.length > 5) {
            handlePincode(newPincode);
            setPincodeError("");
        }
    };

    const [supportData, setSupportData] = useState({});

    const handleSupport = async () => {
        try {
            let result = await getCustomerSupport();
            setSupportData(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };

    const [addOn, setAddOn] = useState([]);

    const productRelatedData = async (p_id) => {
        const formData = new URLSearchParams();
        formData.append("product_id", p_id?.id);
        formData.append("related_type", "add_on");
        formData.append("user_id", user?.id);
        try {
            let result = await addOnProduct(formData);
            setAddOn(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };
    //shipRocket /auth/login
    // const authLoginData = {
    //     "email": process.env.REACT_APP_API_USER_EMAIL,
    //     "password": process.env.REACT_APP_API_USER_PASSWORD
    // }
    const handlePincode = async (NewPin) => {
        if (NewPin) {
            // const authResponse = await generateToken(authLoginData);
            // console.log('authResponse',pincode);
            // if (authResponse.token) {
            // const token=authResponse.token;
            const formData = new URLSearchParams();
            formData.append("delivery_postcode", NewPin);
            formData.append("pickup_postcode", "302014");
            formData.append("weight", "0.5");
            formData.append("cod", "1");

            // const orpickup_postcode="302014";
            // const delivery_postcode=302017;
            // const weight="0.5";
            // const cod="1";
            try {
                // const response = await serviceability(orpickup_postcode, delivery_postcode, weight, cod);
                const response = await serviceability(formData);
                const availableCourierCompanies =
                    response?.data?.available_courier_companies || [];
                setPinVerified(availableCourierCompanies.length > 0); //set like true
                setUpdatePinCode(availableCourierCompanies);

                if (!availableCourierCompanies.length) {
                    setPincodeError("No delivery found with this pincode");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("An error occurred while checking the pincode");
            }
            // }
        } else {
            toast.error("Please Enter Pincode");
        }
        // setIsModalOpenPin(true);
    };
    const handleKeyDown = (e) => {
        const key = e.key;
        const input = e.target.value;
        if (key === "0" && (input === "" || e.target.selectionStart === 0)) {
            e.preventDefault();
        }
        if (
            !(key >= "0" && key <= "9") &&
            key !== "Backspace" &&
            key !== "Tab" &&
            key !== "ArrowLeft" &&
            key !== "ArrowRight"
        ) {
            e.preventDefault();
        }
    };

    const handleRelated = (data) => {
        fetchDataAndRelatedData(data);
        window.scrollTo(0, 0);
        setProductCount(1);
    };
    const [likedReviews, setLikedReviews] = useState(false);

    const handleLikeClick = async (itemId) => {
        const formData = new URLSearchParams();
        formData.append("product_review_id", itemId?.id || 0);
        try {
            const response = await addLikeReview(formData, user.token);
            if (response.status === "1") {
                fetchDataAndRelatedData();
                itemId.is_review_like === 1
                    ? (itemId.is_review_like = 0)
                    : (itemId.is_review_like = 1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const backBreadCum = (from, menuSlug) => {
        navigate(`${Routes.ProductCategory}/${categorySlug}`, {
            state: { from, menuSlug },
        });
    };

    const handleSingleReviews = async () => {
        if (reviewCount == "1") {
            const ReviewId = reviews.find((val) => val?.user_id === newUserID?.id);
            if (ReviewId) {
                const formData = new URLSearchParams();
                formData.append("id", ReviewId.id);
                try {
                    const response = await getReviewsDetails(formData, token);
                    if (response.status === "1") {
                        setReviewDetail(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        }
    };

    useEffect(() => {
        if (reviews.length > 0) {
            handleSingleReviews();
        }
    }, [reviews]);
    const [open, setOpen] = useState(false);
    const [bulkProductId, setBulkProductId] = useState("0");
    const showDrawer = (id) => {
        setOpen(true);
        setBulkProductId(id);
    };
    const bulkDrawerOnClose = () => {
        setOpen(false);
    };
    const [openCompare, setOpenCompare] = useState(false);

    // const showCompare = () => {
    //     setOpenCompare(true);
    // };

    const onCloseCompare = () => {
        setOpenCompare(false);
    };
    const isProductOutOfStock = OutOfStock({
        productId: productDetails?.id,
        stockQuantity: productDetails?.stock_quantity,
    });

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelect = (slug) => {
        fetchDataAndRelatedData(slug);
        setSelectedOption(slug);
    };

    const [applied, setApplied] = useState(false);
    const [appliedPrice, setAppliedPrice] = useState(null);
    const [productCouponId, setProductCouponId] = useState([]);

    // console.log('appliedPriceappliedPriceappliedPrice',appliedPrice);
    // const handleCopyClick = (couponData) => {
    //     const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];
    //     // console.log('productCouponId',productCouponId);
    //     setApplied(!applied);
    //     const newPrice = (productDetails?.price * couponData?.type_val) / 100
    //     setAppliedPrice(productDetails?.price - newPrice)
    //     const newIds = {
    //         couponId: couponData?.id,
    //         productId: productDetails?.id
    //     };
    //     setProductCouponId([...productCouponId, newIds])
    //     if(applied === false){
    //         const storeUpdatePrice={
    //             productId:productDetails.id,
    //             newPrice:applied === false ? productDetails?.price - newPrice : productDetails?.price,
    //             discountApply: applied
    //         }
    //         localStorage.setItem('productCoupon',JSON.stringify([...productCouponId,newIds]))
    //         dispatch(updateCartItem(storeUpdatePrice));
    //     }else{
    //         localStorage.setItem('productCoupon',JSON.stringify([]))
    //     }
    // };

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file?.type === "image/jpeg" || file?.type === "image/png";
        const isVideo = file?.type.startsWith("video/");
        if (!isJpgOrPng) {
            if (isVideo) {
                message.error("Video uploads are not allowed!");
            } else {
                message.error("You can only upload JPG/PNG files!");
            }
            return false;
        }

        const isLimit = file.size / 1024 / 1024 < 2;
        if (!isLimit) {
            message.error("Image must be smaller than 2MB!");
            return false;
        }

        if (fileList.length >= 5) {
            message.error("You can upload only up to 5 images!");
            return false;
        }

        return true;
    };

    const handleUpload = ({ fileList: newFileList }) => {
        const deletedImageIds = fileList
            .filter(
                (oldFile) => !newFileList.some((newFile) => newFile.uid === oldFile.uid)
            )
            .map((deletedFile) => deletedFile.id)
            .filter((id) => id !== undefined && id !== null);

        setOldImage((prevOldImages) => [...prevOldImages, ...deletedImageIds]);

        setFileList(newFileList);
        setSubmitted(false);
    };

    useEffect(() => {
        if (reviewDetail?.review_images) {
            const fileListData = reviewDetail?.review_images?.map((imageObj) => ({
                uid: imageObj.image,
                name: imageObj.image,
                status: "done",
                url: `${ReviewsImagePath}${imageObj.image}`,
                id: imageObj.id,
            }));
            setFileList(fileListData);
            // setFileList(fileListData)
        }
    }, [reviewDetail]);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleCompare = () => {
        // Retrieve and parse compare data from localStorage
        let compareData = JSON.parse(localStorage.getItem("compareData")) || {};

        // Retrieve and parse compare IDs from localStorage
        let compareIDs = JSON.parse(localStorage.getItem("comp")) || [];


        // Ensure compareIDs is an array
        if (!Array.isArray(compareIDs)) {
            compareIDs = [];
        }

        // Extract IDs from compareIDs
        const ids = compareIDs.map((item) => item.id);

        // Assuming singleCompareData is available in scope
        if (!singleCompareData?.data) {
            console.error("singleCompareData is not available");
            return;
        }

        // Destructure the required data from the response
        const {
            product_name = "",
            productimages = [],
            price = 0,
            id = 0,
        } = singleCompareData.data;

        const imgPath = singleCompareData.path || "";
        const comimage = `${imgPath}${productimages[0]?.file_name_120_x_120 || ""}`;
        const catSlug = singleCompareData.category?.[0]?.slug || "";
        const category_name = singleCompareData.category?.[0]?.name || "";

        // Create the data object to store
        const dataToStore = {
            product_name,
            price,
            comimage,
            id,
            catSlug,
            category_name,
        };
        if (compareIDs?.[0]?.slug && catSlug !== compareIDs[0]?.slug) {
            dispatch(clearCompare());
            localStorage.removeItem('comp');
        }
        // Check if the product ID is already in the compare list
        if (!ids.includes(id)) {
            // Update localStorage with the new data
            localStorage.setItem("compareData", JSON.stringify(dataToStore));
            setOpenCompare(true);
        } else {
            // Update localStorage with the new data
            localStorage.setItem("compareData", JSON.stringify(dataToStore));
            // Update localStorage with the updated compareIDs
            compareIDs.push(compareData);
            localStorage.setItem("comp", JSON.stringify(compareIDs));
            // Remove the existing ID from compareIDs
            const newCompareIDs = compareIDs.filter((item) => item.id !== id);

            // Update localStorage with the new compareIDs
            dispatch(addToCompare(newCompareIDs));
            localStorage.setItem("comp", JSON.stringify(newCompareIDs));

            // Set the open state to true to show the compare data
            setOpenCompare(true);
        }

    };

    const settingsAdd = {
        dots: false,
        infinite: addOn?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    if (statusCheck === "0") {
        return (
            <div className='noDataCont' style={{ marginTop: 60 }}>
                <img src={NoData} alt='' />
                <h5>No Data Found</h5>
            </div>
        )
    }

    return (
        <>
            <div className="dinnerplate-container">
                <div className="path_drinkware">
                    <Link to="/">Home</Link>
                    <LiaGreaterThanSolid />
                    {from && (
                        <>
                            {/* <LiaGreaterThanSolid /> */}
                            <span onClick={() => navigate(`/${menuSlug}`)}>{from}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    {route && (
                        <>
                            <span onClick={() => backBreadCum(from, menuSlug)}>{route}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    {dataSlug && (
                        <>
                            <span onClick={() => navigate(-1)}>{dataSlug}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    <p>{productDetails?.product_name}</p>
                </div>
                <div className="main-box">
                    <div className="left-slide">
                        <ProductDetailImgSlider
                            productDetails={productDetails}
                            imgPath={imgPath}
                        />
                    </div>
                    <div className="right-slide">
                        <div className="headding">
                            <h1>{productDetails && productDetails.product_name}</h1>
                        </div>
                        <div className="testimonial">
                            {productDetails?.stock_quantity < 3 && (
                                <p>
                                    Status{" "}
                                    {productDetails?.stock_quantity !== 0 ? (
                                        <span>{productDetails?.stock_quantity} in stock</span>
                                    ) : (
                                        <div className="sold_produ_chips">
                                            <span>Sold Out</span>
                                        </div>
                                    )}{" "}
                                </p>
                            )}

                            <div className="rating_testimonail">
                                Rating:
                                {ratingData !== null ? (
                                    <>
                                        <ReactStars
                                            count={5}
                                            key={`react-stars-${ratingData?.StarRating}`}
                                            value={parseFloat(ratingData?.StarRating)}
                                            size={20}
                                            isHalf={true}
                                            activeColor="#ffd700"
                                            color="#d1d1d1"
                                            edit={false}
                                        />
                                        <span
                                            onClick={handleRatingClick}
                                            style={{ cursor: "pointer" }}
                                        >
                                            ({ratingData?.Rating})
                                        </span>
                                    </>
                                ) : (
                                    <span>Loading rating...</span>
                                )}
                            </div>
                        </div>
                        <div className="price">
                            <ProductPrice product={productDetails} />
                            {productDetails?.discount !== 0 && productDetails?.discount !== null && (
                                <div className="off_chipsDetail">
                                    <span>{productDetails?.discount}% off</span>
                                </div>
                            )}
                        </div>
                        <div className="button-box">
                            <div className="buttons_threeNumber">
                                <button
                                    className="btn-incriment"
                                    disabled={isProductOutOfStock}
                                >
                                    <span onClick={handleCounts}>
                                        <FaMinus />
                                    </span>
                                    {productCount}
                                    <span
                                        onClick={() =>
                                            handleCountInc(productDetails?.stock_quantity)
                                        }
                                    >
                                        <FaPlus />
                                    </span>
                                </button>
                                <AddToCart
                                    imgPath={imgPath}
                                    productList={productDetails}
                                    productCount={productCount}
                                    appliedPrice={appliedPrice}
                                    applied={applied}
                                    productCouponId={productCouponId}
                                    myClass="add-to-cart"
                                />
                                {!isProductOutOfStock && (
                                    <button onClick={handleBuyNowClick} className="buy-now">
                                        BUY NOW
                                    </button>
                                )}

                                <div className="wishlist">
                                    {is_wishlistt !== null && (
                                        <Wishlist
                                            is_wishlist={is_wishlistt}
                                            pId={productDetails?.id}
                                            path={`${Routes.ProductDetail}/${productDetails?.product_slug}`}
                                            mode="HeartOutlined"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="comapre_buton">
                                <button onClick={() => handleCompare()}>Compare</button>
                            </div>
                        </div>
                        <div className="bulk_linkDetails">
                            Want to buy this in bulk?
                            <span onClick={() => showDrawer(productDetails?.id)}>
                                {" "}
                                Click here
                            </span>
                        </div>

                        {/* {productVariant?.map(product_val => (
                            product_val?.title === "Color" && (
                                <div className='choooseSize' key={product_val.title}>
                                    <h4>Choose {product_val.title}:</h4>
                                    <div className='chooseSizeButton'>
                                        {product_val?.product_datas?.map(product_data => (
                                            <Radio.Group key={product_data.slug}>
                                                <Radio.Button
                                                    value={product_data.slug}
                                                    onClick={() => fetchDataAndRelatedData(product_data.slug)}
                                                    className=""
                                                >
                                                    <div className='radioBtn_color'>
                                                        <img src={`${colorImgPath}${product_data.product_color_image}`} width={25} alt={product_data.product_name} />
                                                    </div>
                                                </Radio.Button>
                                            </Radio.Group>
                                        ))}
                                    </div>
                                </div>
                            )
                        ))} */}

                        {showVariantListing ? (
                            <>
                                {isVariant ? (
                                    // Product variants
                                    <div className="choooseSize">
                                        {attributeTypes?.map(
                                            ({
                                                attribute,
                                                childreData,
                                                primaryChildrenData,
                                                primaryVariantId,
                                            }) => (
                                                <div key={attribute?.id}>
                                                    <h4>{attribute?.attr_type_name}</h4>
                                                    <div className='chooseSizeButton chossewidth'>
                                                        {attribute?.id == primaryVariantId
                                                            ? primaryChildrenData?.map((child) => (
                                                                <Radio.Group
                                                                    key={child?.attr_val_id}
                                                                    value={defaultSelected[attribute?.id]}
                                                                    onChange={handleRadioChangeForColor}
                                                                >
                                                                    <Radio.Button
                                                                        value={child?.attr_val_id}
                                                                        // onClick={() => fetchDataAndRelatedData(child?.products?.product_slug)}
                                                                        onClick={() =>
                                                                            variantSlugNavigate(
                                                                                child?.products?.product_slug
                                                                            )
                                                                        }

                                                                        className={attribute?.attr_type_name === "Color" ? "noborder" : ""}
                                                                    >
                                                                        {attribute?.attr_type_name === "Color" ? (
                                                                            attribute?.attr_type_name === "Color" && defaultSelected[attribute?.id] == child?.attr_val_id ? (
                                                                                <div className="radioBtn_color">
                                                                                    <div className="checked_icon">
                                                                                        <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 24L20 34L40 14" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                                                    </div>
                                                                                    <img
                                                                                        src={
                                                                                            colorImgPath +
                                                                                            child?.product_attr_value?.image
                                                                                        }
                                                                                        alt={
                                                                                            child?.product_attr_value
                                                                                                ?.attr_val_name
                                                                                        }
                                                                                    />

                                                                                </div>
                                                                            ) :
                                                                                <div className="radioBtn_color">
                                                                                    {/* <div className="checked_icon">
                                                                                    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 24L20 34L40 14" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                                                </div> */}
                                                                                    <img
                                                                                        src={
                                                                                            colorImgPath +
                                                                                            child?.product_attr_value?.image
                                                                                        }
                                                                                        alt={
                                                                                            child?.product_attr_value
                                                                                                ?.attr_val_name
                                                                                        }
                                                                                    />

                                                                                </div>


                                                                        ) : (
                                                                            child?.product_attr_value?.attr_val_name
                                                                        )}
                                                                    </Radio.Button>
                                                                </Radio.Group>
                                                            ))
                                                            : childreData?.map((child) => (
                                                                <Radio.Group
                                                                    key={child?.attr_val_id}
                                                                    value={defaultSelected[attribute?.id]}
                                                                    onChange={handleRadioChangeForColor}
                                                                >
                                                                    <Radio.Button
                                                                        value={child?.attr_val_id}
                                                                        // onClick={() => fetchDataAndRelatedData(child?.products?.product_slug)}
                                                                        onClick={() =>
                                                                            variantSlugNavigate(
                                                                                child?.products?.product_slug
                                                                            )
                                                                        }
                                                                        // className=""
                                                                        disabled={
                                                                            child?.active === "not active"
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        {attribute?.attr_type_name === "Color" ? (
                                                                            <div className="radioBtn_color">
                                                                                <img
                                                                                    src={
                                                                                        colorImgPath +
                                                                                        child?.product_attr_value?.image
                                                                                    }
                                                                                    alt={
                                                                                        child?.product_attr_value
                                                                                            ?.attr_val_name
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            child?.product_attr_value?.attr_val_name
                                                                        )}
                                                                    </Radio.Button>
                                                                </Radio.Group>
                                                            ))}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    // Default product variant (Is variant = 0)
                                    // <div className='choooseSize'>
                                    //     {productDefaultData?.map((item, index) => (

                                    //         <div key={item?.id}>
                                    //             <h4>{item?.product_attr_type?.attr_type_name}</h4>
                                    //             <div className='chooseSizeButton chossewidth'>
                                    //                 <Radio.Group key={item?.id} value={item?.attr_val_id} >
                                    //                     <Radio.Button
                                    //                         value={item?.attr_val_id}
                                    //                     >
                                    //                         {
                                    //                             item?.product_attr_type?.attr_type_name === 'Color' ? <div className='radioBtn_color'>
                                    //                                 <img src={colorImgPath + item?.product_attr_value?.image} alt={item?.product_attr_value?.attr_val_name} />

                                    //                             </div> : item?.product_attr_value?.attr_val_name
                                    //                         }

                                    //                     </Radio.Button>
                                    //                 </Radio.Group>
                                    //                 {/* ))} */}
                                    //             </div>
                                    //         </div>
                                    //     ))}
                                    // </div>
                                    ""
                                )}
                            </>
                        ) : (
                            //  When isVariant is 0 and No data found in default product attribute.
                            ""
                        )}

                        {country === "IN" && (
                            <>
                                <span className='pinCode_availability mt-3'>Please enter PIN code to check delivery time & Pay on Delivery Availability</span>
                                <div className="pincode-btn">
                                    {isProLoading ? (
                                        <Skeleton height={40} width={200} />
                                    ) : (
                                        <>
                                            <Input
                                                placeholder="Enter Your Pincode"
                                                onKeyDown={handleKeyDown}
                                                value={pincode}
                                                className='pincode'
                                                onChange={(e) => pincodeChange(e)}
                                                maxLength={6}
                                            />
                                            {pincode?.length > 5 && (
                                                <button className='checkout'>Check</button>
                                            )}
                                        </>
                                    )}
                                </div>
                                <h5 className='pinCode_delivery'>{pinVerified === true ?
                                    <p>Estimated Delivery, {updatePinCode[0]?.etd && new Date(new Date(updatePinCode[0]?.etd).getTime() + 3 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                    :
                                    pinVerified === false && pincode?.length > 5 &&
                                    <p style={{ color: "red" }}>{pincodeError}</p>}</h5>
                                {/* <h5 className='pinCode_delivery'>Estimated delivery: May 16, 2022</h5> */}
                                {/* <div className='product_details_delivery'>
                            <div className='experss_deliverySpan'><div><img src={expressIMg} className='tryImg' /></div><div>Express delivery <span> might be available</span></div></div>
                            <div className='experss_deliverySpan'><div><img src={CodImg} className='tryImg' /></div> <div>Pay on delivery <span> might be available</span></div></div>
                            <div className='experss_deliverySpan'><div><img src={hassleImg} className='tryImg' /></div><div>Hassle free 7, 15 or 30 days <span> might be available</span></div></div> 
                            
                            </div> */}
                            </>
                        )}
                        {/* {productCoupon?.length > 0 &&
                            <div className='coupons_containers'>
                                <h4>Best offers for you!</h4>
                                <div className='product_best_offer_row'>
                                    {productCoupon?.map(item => (
                                        <div className='product_best_offer_item'>
                                            <div className='product_best_offer_info'>
                                                <strong>{item?.coupon_desc}</strong> No minimum spend
                                            </div>
                                            <div className='product_best_offer_code'>
                                                <span className='code_info'>Use Code:</span>
                                                <div className={applied ? 'offer_code appliedOffer' : 'offer_code'}>
                                                    <span className='copy_code' onClick={() => handleCopyClick(item)}>
                                                        {applied ? (
                                                            <div className='applied_span'>
                                                                <p>COUPON APPLIED!</p>
                                                            </div>
                                                        ) : item?.coupon_code}
                                                    </span>
                                                </div>
                                                {applied &&
                                                    <div className='applied_couponsSpan'>
                                                        <h6><span>After applying the coupon, Your New Price :</span> ₹{appliedPrice}  </h6>
                                                    </div>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>} */}
                        <ProductCoupon
                            productDetails={productDetails}
                            appliedPrice={appliedPrice}
                            setAppliedPrice={setAppliedPrice}
                            applied={applied}
                            setApplied={setApplied}
                        />
                        <div className="product_collapse">
                            <Collapse
                                onChange={onChange}
                                items={items}
                                expandIcon={({ isActive }) =>
                                    isActive ? <MinusOutlined /> : <PlusOutlined />
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="tabs_sub">
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                        <Tab eventKey="dimensions" title="The Perfect Partner">
                            {addOn?.length > 0 &&
                                <div className="special_addons">
                                    <div className="special_addonSlider">
                                        <Slider {...settingsAdd}>
                                            {addOn?.map(item => (
                                                <div className="special_image">
                                                    <Link to={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`}>
                                                        <img src={item?.relatedproduct?.productimages.length > 0 ? `${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}` : img1} alt="" />
                                                        <div className='dinnerSlider_details'>
                                                            <p className="makeOurkit_para">{item?.relatedproduct?.product_name}</p>

                                                            {item?.relatedproduct?.stock_quantity <= 0 ?
                                                                <div className="sold_off_chips">
                                                                    <p>Sold Out</p>
                                                                </div> :
                                                                item?.relatedproduct?.discount > 0 &&
                                                                <div className="off_chips">
                                                                    <p>{item?.relatedproduct?.discount}% off</p>
                                                                </div>}
                                                            <div className='dinnerSliderSpan'>
                                                                <span>₹{item?.relatedproduct?.price}</span>
                                                                {item?.relatedproduct?.price !== parseFloat(item?.relatedproduct?.compare_price) && item?.relatedproduct?.compare_price > 0 &&
                                                                    <p>₹{item?.relatedproduct?.compare_price}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="dinnerAddon_btn">
                                                        <Wishlist is_wishlist={item?.relatedproduct?.is_wishlist} pId={item?.relatedproduct?.id} path={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`} mode='HeartOutlined' />
                                                        <AddToCart productList={item?.relatedproduct} p_id={item?.relatedproduct?.id} imgPath={imgPath} />
                                                    </div>
                                                </div>
                                            ))}

                                        </Slider>
                                    </div>
                                </div>}
                        </Tab>
                        <Tab eventKey="whyLove" title="Why We Love It">
                            <div
                                className="tabs_description"
                                dangerouslySetInnerHTML={{
                                    __html: productDetails && productDetails.why_we_love_it,
                                }}
                            ></div>
                        </Tab>
                        <Tab eventKey="shippingReturns" title="Shipping & Return & Exchange">
                            <div
                                className="tabs_description"
                                dangerouslySetInnerHTML={{
                                    __html: productDetails && productDetails.dimension,
                                }}
                            ></div>
                            <div
                                className="tabs_description mt-2"
                                dangerouslySetInnerHTML={{
                                    __html: productDetails && productDetails.shipping_return,
                                }}
                            ></div>
                        </Tab>
                        <Tab eventKey="reviews" title="Reviews">
                            <div className="tabs_description">
                                <Row gutter={48}>
                                    <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                        <h3>Customers Review</h3>
                                        {reviews?.map((item, index) => (
                                            <div key={index} className="mainContainer_rating">
                                                <div
                                                    key={`react-stars-${index}`}
                                                    className="review_container"
                                                >
                                                    {/* <img src={hoverImage} width={30} alt='' /> */}
                                                    <Avatar style={{ width: "35px", height: "35px" }}>
                                                        <span
                                                            style={{
                                                                lineHeight: "224%",
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {item?.user?.userfullname !== null
                                                                ? item?.user?.userfullname?.charAt(0)
                                                                : item?.user?.userfullname.charAt(0)}
                                                        </span>
                                                    </Avatar>
                                                    <div className="reviewDetail_cont">
                                                        <div className="review_widthDesign">
                                                            <h6>{item?.user?.userfullname}</h6>
                                                            <div className="containerMain_revirw">
                                                                {/* <div className='verifiedChceked'><FormOutlined /></div> */}
                                                                <h5>
                                                                    {dayjs(item?.created_at)?.format(
                                                                        "DD MMM YYYY"
                                                                    )}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="tabsReviewCont">
                                                            <ReactStars
                                                                key={`react-stars-${index}-${item?.rating}`}
                                                                count={5}
                                                                size={24}
                                                                isHalf={true}
                                                                activeColor="#ffd700"
                                                                color="#d1d1d1"
                                                                value={parseFloat(item?.rating)}
                                                                edit={false}
                                                            />
                                                        </div>
                                                        <div className="tabsReviewImage">
                                                            {item?.review_images?.map((imagesItem) => (
                                                                <img
                                                                    src={`${ReviewsImagePath}${imagesItem?.image}`}
                                                                    alt=""
                                                                />
                                                            ))}
                                                        </div>
                                                        <p>{item?.review}</p>
                                                        <div className="paragraph_review">
                                                            <div className="review_likesCont">
                                                                <img
                                                                    src={
                                                                        item.is_review_like === 1
                                                                            ? ChangeImg
                                                                            : likeButton
                                                                    }
                                                                    className="likeButton"
                                                                    onClick={() => handleLikeClick(item)}
                                                                    alt=""
                                                                />
                                                                <span>{item?.review_like_count}</span>
                                                                {/* <div className='disbuttton_margn'>
                                                            <img src={likeButton} className='dislikeButton' alt='' />
                                                        </div> */}
                                                            </div>
                                                            <div className="containerMain_revirw">
                                                                <div className="verifiedChceked">
                                                                    <CheckOutlined />
                                                                </div>
                                                                <span>Verified Buyer</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                        <div className="model_reviewBody">
                                            <h4>
                                                {reviewCount !== 0 ? " Edit review" : "Write a Review"}
                                            </h4>
                                            <span>
                                                How likely are you to recommend this product ?
                                            </span>
                                            <ReactStars
                                                key={`react-stars-${rating}`}
                                                count={5}
                                                onChange={ratingChanged}
                                                value={rating ? rating : 0}
                                                size={28}
                                                isHalf={true}
                                                color="#d1d1d1"
                                                activeColor="#ffd700"
                                                edit={true}
                                            />

                                            {/* <h5>What can we improve ?</h5> */}
                                            <h5>Add your product images</h5>
                                            <div className="upload_imge">
                                                <Upload
                                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                                    listType="picture-card"
                                                    name="file"
                                                    onPreview={handlePreview}
                                                    onChange={handleUpload}
                                                    fileList={fileList}
                                                    beforeUpload={beforeUpload}
                                                    multiple
                                                >
                                                    {fileList?.length >= 5 ? null : uploadButton}
                                                </Upload>
                                                {previewImage && (
                                                    <Image
                                                        wrapperStyle={{
                                                            display: "none",
                                                        }}
                                                        preview={{
                                                            visible: previewOpen,
                                                            onVisibleChange: (visible) =>
                                                                setPreviewOpen(visible),
                                                            afterOpenChange: (visible) =>
                                                                !visible && setPreviewImage(""),
                                                        }}
                                                        src={previewImage}
                                                    />
                                                )}
                                            </div>

                                            <h5>What Feature can we add to improve?</h5>
                                            <TextArea
                                                rows={4}
                                                placeholder="Tell us more (in 250 characters please)"
                                                maxLength={250}
                                                value={feedbackText}
                                                onChange={handleFeedbackTextChange}
                                            />

                                            <button
                                                key="submit"
                                                onClick={handleSubmit}
                                                disabled={submitted}
                                            >
                                                {" "}
                                                {reviewCount !== 0
                                                    ? "Update Feedback"
                                                    : "Submit Feedback"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                {reviews?.length > 0 && (
                                    <div className="blog_pagination">
                                        <Pagination defaultCurrent={1} total={5} />
                                    </div>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="assurance" title="Kairaus Assurance">
                            <div className="tabs_description">
                                <Row gutter={24}>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="border_assurenceCrd"
                                    >
                                        <div className="assurence_cards">
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 48 48"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M44 24V9H24H4V24V39H24"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M35 39L43 32L39 28L31 35V39H35Z"
                                                    fill="none"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M4 9L24 24L44 9"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="assurance_span">
                                                <span>Email Us</span>
                                                <p>
                                                    <Link to={`mailto:${supportData?.support_email}`}>
                                                        {supportData?.support_email}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="border_assurenceCrd"
                                    >
                                        <div className="assurence_cards">
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 48 48"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M41.7796 20.6066C42.0324 18.9108 41.9495 17.1747 41.5309 15.5054C40.978 13.3002 39.8392 11.2118 38.1147 9.4873C36.3902 7.76281 34.3018 6.62409 32.0967 6.07115C30.4274 5.65257 28.6912 5.56967 26.9954 5.82245"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M34.1896 19.8035C34.4604 17.9869 33.8966 16.0699 32.4982 14.6715C31.0997 13.2731 29.1827 12.7092 27.3662 12.98"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M14.3755 8.79423C15.1021 8.79423 15.7715 9.18825 16.1241 9.82349L18.5706 14.2303C18.8909 14.8073 18.9059 15.5052 18.6108 16.0955L16.254 20.8091C16.254 20.8091 16.937 24.3206 19.7954 27.179C22.6538 30.0374 26.1535 30.7086 26.1535 30.7086L30.8664 28.3522C31.4571 28.0568 32.1555 28.0721 32.7327 28.393L37.152 30.85C37.7866 31.2028 38.1802 31.8719 38.1802 32.598L38.1802 37.6715C38.1802 40.2552 35.7803 42.1213 33.3322 41.2952C28.3043 39.5987 20.4996 36.3685 15.5528 31.4216C10.6059 26.4748 7.3757 18.6701 5.67916 13.6422C4.85314 11.1941 6.71923 8.79423 9.30288 8.79423L14.3755 8.79423Z"
                                                    fill="none"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="assurance_span">
                                                <span>Support Helpline</span>
                                                <p>
                                                    <Link to={`tel:${supportData?.support_phone}`}>
                                                        +91-{supportData?.support_phone}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="border_assurenceCrd"
                                    >
                                        <div className="assurence_cards">
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 48 48"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="24"
                                                    cy="11"
                                                    r="7"
                                                    fill="none"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M4 41C4 32.1634 12.0589 25 22 25"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M31.85 28C29.7237 28 28 30.0086 28 32.4864C28 36.9727 32.55 41.0513 35 42C37.45 41.0513 42 36.9727 42 32.4864C42 30.0086 40.2763 28 38.15 28C36.8479 28 35.6967 28.7533 35 29.9062C34.3033 28.7533 33.1521 28 31.85 28Z"
                                                    fill="none"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="assurance_span">
                                                <span>Contact Our Team</span>
                                                <p>{supportData?.support_person}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="border_assurenceCrd"
                                    >
                                        <div className="assurence_cards">
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 48 48"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 6C10 4.89543 10.8954 4 12 4H36C37.1046 4 38 4.89543 38 6V44L31 39L24 44L17 39L10 44V6Z"
                                                    fill="none"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M18 22L30 22"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M18 30L30 30"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M18 14L30 14"
                                                    stroke="#333"
                                                    strokeWidth="4"
                                                    strokeLinecapp="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="assurance_span">
                                                <span>Product Warranty</span>
                                                <p>{supportData?.manufacturing_warranty}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                {comboProductId &&<ProductCombo 
                comboProductId={comboProductId}
                comboProductCategoryId={comboProductCategoryId}
                productDetails={productDetails}
            />}
                

                {/* {productCombo?.length > 1 && (
                    <div className="combo_section">
                        <h2>Pairs Well With</h2>
                        <div className="combo_card_products">
                            <div className="combo_section_design">
                                <div className="combo_first_product">
                                    <div className="combo_products">
                                        <div className="combo_image">
                                            <img
                                                src={`${comboImages}${firstIndexData?.productimages?.[0]?.file_name}`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="combo_priceSpan">
                                            <span>{firstIndexData?.product_name}</span>
                                            <div className="combo_price">
                                                <h4>₹{firstIndexData?.price}</h4>
                                                {firstIndexData?.price !==
                                                    parseFloat(firstIndexData?.compare_price) &&
                                                    firstIndexData?.compare_price > 0 && (
                                                        <span>₹{firstIndexData?.compare_price}</span>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="combo_plus">
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M24.0605 10L24.0239 38"
                                            stroke="#333"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="miter"
                                        />
                                        <path
                                            d="M10 24L38 24"
                                            stroke="#333"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="miter"
                                        />
                                    </svg>
                                </div>
                                {productCombo?.map((comboItem, index) => (
                                    <>
                                        <div className="combo_first_product">
                                            <div className="combo_products">
                                                <div className="combo_image">
                                                    <Checkbox
                                                        checked={!comboChecked[index]}
                                                        onChange={() => handleComboCheckbox(index)}
                                                    ></Checkbox>
                                                    <img
                                                        src={comboItem?.productimages.length > 0 ? `${comboImages}${comboItem?.productimages[0]?.file_name}` : img1}
                                                        alt=""
                                                        onClick={() =>
                                                            variantSlugNavigate(
                                                                comboItem?.product_slug
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="combo_priceSpan" onClick={() =>
                                                    variantSlugNavigate(
                                                        comboItem?.product_slug
                                                    )
                                                }>
                                                    <span>{comboItem?.product_name}</span>
                                                    <div className="combo_price">
                                                        <h4>₹{comboItem?.price}</h4>
                                                        <span>₹{comboItem?.compare_price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {index !== productCombo.length - 1 && (
                                            <div className="combo_plus">
                                                <svg
                                                    width="32"
                                                    height="32"
                                                    viewBox="0 0 48 48"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M24.0605 10L24.0239 38"
                                                        stroke="#333"
                                                        strokeWidth="3"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="miter"
                                                    />
                                                    <path
                                                        d="M10 24L38 24"
                                                        stroke="#333"
                                                        strokeWidth="3"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="miter"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div className="combo_total">
                                <div className="combo__product">
                                    <h6>Total Price </h6>
                                    <h5>₹ {totalPrice}</h5>
                                    <div className="combo_buttons">
                                        <button onClick={handleCombo} className="add_combo">Add to Cart</button>
                                        <button className="combo_buy" onClick={handleBuyCombo}>
                                            Buy Combo
                                        </button>
                                    </div>
                                    <div className="suggest_combo">
                                        <button onClick={() => handleOthersCombo(productDetails)}>
                                            Suggest Other Combos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}

                <BestSeller
                    productDetails={productDetails}
                    sellerCategory={sellerCategory}
                />
                <ColorWiseProduct
                    productAttributesData={
                        productAttributesData
                    }
                />
                <RelatedProducts
                    relatedData={relatedData}
                    relatedSettings={relatedSettings}
                    imgPath={imgPath}
                    handleRelated={handleRelated}
                    Routes={Routes}
                />


                <TrendingProduct
                    trendingProduct={trendingProduct}
                    relatedSettings={relatedSettings}
                    imgPath={imgPath}
                    handleRelated={handleRelated}
                    Routes={Routes}
                />

                <ProductFAQ faq={faq} />

            </div>

            <Drawer onClose={onClose} openDrawer={openDrawer} />

            {/* <RatingModal reviewDetail={reviewDetail} isModalOpen={isModalOpen} handleCancel={handleCancel} productDetails={productDetails} setRating={setRating} rating={rating} setFeedbackText={setFeedbackText} feedbackText={feedbackText} /> */}

            <Modal
                title="Change Pincode"
                open={isModalOpenPin}
                onCancel={handleCancelPin}
                maskClosable={false}
                className="addressChanges_model"
                footer={[
                    <button
                        key="cancel"
                        onClick={handleCancelPin}
                        className="cancel_Button"
                    >
                        Cancel
                    </button>,
                    <button
                        key="submit"
                        onClick={() => handleOkPin(isChecked)}
                        className="submitBtn"
                    >
                        Submit
                    </button>,
                ]}
            >
                <div className="address_cards">
                    {addressList?.length > 0 ? (
                        addressList?.map((addressItem) => (
                            <div className="address_mainContainer">
                                <div className="checkbox_cards">
                                    <Checkbox
                                        checked={addressItem?.id === isChecked?.id}
                                        onChange={() => handleCheckboxChange(addressItem)}
                                    />
                                    <h6>{addressItem?.name}</h6>
                                </div>
                                <p>+91-{addressItem?.mobile_number}</p>
                                <p>
                                    {addressItem?.land_mark !== "undefined" &&
                                        `${addressItem?.land_mark},`}{" "}
                                    {addressItem?.address}, {addressItem?.city?.city_name},{" "}
                                    {addressItem?.state?.state_name},{" "}
                                    {addressItem?.country?.country_name}
                                </p>
                                <h4>{addressItem?.zip_code}</h4>
                            </div>
                        ))
                    ) : (
                        <div className="noDataCont">
                            <img src={NoDataimg} alt="" />
                            <h5>No Address Found</h5>
                        </div>
                    )}
                </div>
            </Modal>
            <BulkDrawer
                onClose={bulkDrawerOnClose}
                openDrawer={open}
                productId={bulkProductId}
            />
            <Compare
                openCompare={openCompare}
                onCloseCompare={onCloseCompare}
                dataSlug={singleCategorySlug}
            />
        </>
    );
};
// DrawerCart
export default ProductDetail;
