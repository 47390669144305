import React, { lazy, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/app.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/Home';
import Routing from './Routes/Routes';
import DiningPlates from './pages/category/DiningPlates';
// import DrinkwareCategory from './pages/category/ProductCategory';
import ProductDetail from './pages/category/ProductDetail';
import ProductCategory from './pages/category/ProductCategory';
import Faqs from './components/faqs/Faqs.jsx';
import BlogsCategory from './components/blogCategory/BlogCategory';
import BlogDetails from './pages/blogDetails/BlogDetails.jsx'
import Aboutus from './pages/aboutUs/Aboutus.jsx';
import Checkout from './pages/checkout/Checkout';
import Payment from './pages/checkout/Payment';
// import SignIn from './pages/Auth/SignIn.jsx';
import ThankuCheck from './pages/checkout/Thanku.jsx';
import SignIn from './pages/signIn/SignIn.jsx';
import SignUp from './pages/signIn/Signup.jsx';
import Forgotpassword from './pages/signIn/Forgotpassword.jsx'
// import Feedback from './pages/signIn/Feedback.jsx';
import ThankYouSign from './pages/signIn/ThankYouSign.jsx';
import AccountProfile from './pages/accountDetails/AccountProfile.jsx';
import ScrollToTop from "./components/ScrollToTop"
import NewArrivalProducts from './pages/newArrivals/NewArrivalProducts.jsx';
import ContactUs from './pages/signIn/ContactUs.jsx';
import ErrorPage from './pages/Errorpage/Error.jsx'
import ResetPassword from './pages/signIn/ResetPassword.jsx';
import MyComponent from './MyComponent.js';
import AllProduct from './pages/allProduct/AllProduct.jsx';
import PaymentFailed from './pages/checkout/PaymentFailed.jsx';
import Collection from './pages/collection/Collection.jsx';
import BestSeller from './pages/newArrivals/BestSeller.jsx';
import ProductByColor from './pages/newArrivals/ProductByColor.jsx';
import Shipping from './pages/termCondition/Shipping.jsx';
import Privacy from './pages/termCondition/Privacy.jsx';
import Exchanges from './pages/termCondition/Exchanges.jsx';
import TermCondition from './pages/termCondition/TermsCondition.jsx';
import CompareDetail from './pages/category/CompareDetail.jsx';
import Viewed from './pages/home/Viewed.jsx';
import Rewards from './pages/rewards/Rewards.jsx';
import BigSaving from './pages/newArrivals/BigSaving.jsx';
import FinestProduct from './pages/newArrivals/FinestProduct.jsx';
import Confirmation from './pages/checkout/Confirmation.jsx';
import OurStory from './pages/OurStory/OurStory.jsx';
import AllItems from './pages/category/AllItems.jsx';
import ReturnPage from './pages/return/ReturnPage.jsx';
import { useSelector } from 'react-redux';
import ExchangeRate from './components/ExchangeRate.jsx';
import OccasionCards from './pages/collection/OccasionCards.jsx';
const Header = lazy(() => import('./components/include/Header'));
const Footer = lazy(() => import('./components/include/Footer'));

const RemoveTrailingSlash = ({ children }) => {
  const location = useLocation();


  if (location.pathname.match(/\/+$/)) {
    return (
      <Navigate to={location.pathname.replace(/\/+$/, '') + location.search} />
    );
  }

  return children;
};



const App = () => {
  const { slug } = useSelector((state) => state.slug);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          {/* <ExchangeRate/> */}
          <MyComponent />
          <Header />
          {/* <SeeionTimeout/> */}
        </Suspense>
        <ScrollToTop />
        <RemoveTrailingSlash />
        <Routes >
          <Route path={Routing?.Home} element={<Home />} />
          <Route path={Routing?.DiningPlates} element={<DiningPlates />} />
          <Route path={`${Routing?.ProductCategory}/:slug`} element={<ProductCategory />} />
          <Route path={`${Routing?.ProductDetail}/:slug`} element={<ProductDetail />} />
          <Route path={Routing?.Faqs} element={<Faqs />} />
          <Route path={Routing?.BlogCategory} element={<BlogsCategory />} />
          <Route path={Routing?.AboutUs} element={<Aboutus />} />
          <Route path={Routing?.OurStory} element={< OurStory />} />
          <Route path={Routing?.Checkout} element={<Checkout />} />
          <Route path={Routing?.Payment} element={<Payment />} />
          <Route path={Routing?.ThankuCheck} element={<ThankuCheck />} />
          <Route path={Routing?.SignIn} element={<SignIn />} />
          <Route path={Routing?.SignUp} element={<SignUp />} />
          <Route path={Routing?.ThankYouSign} element={<ThankYouSign />} />
          <Route path={Routing?.AccountProfile} element={<AccountProfile />} />
          <Route path={Routing?.NewArrivalProducts} element={<NewArrivalProducts />} />
          <Route path={Routing?.ContactUs} element={<ContactUs />} />
          <Route path={`${Routing?.MenuPlates}/:slug`} element={<DiningPlates />} />
          {/* {`${Routing?.AllItems}/:slug`}  */}
          <Route path={Routing?.OccasionCards} element={<OccasionCards />} />
          <Route path={Routing?.Forgotpassword} element={<Forgotpassword />} />
          <Route path={Routing?.AllItems} element={<AllItems />} />
          <Route path={Routing?.ResetPassword} element={<ResetPassword />} />
          <Route path={Routing?.AllProduct} element={<AllProduct />} />
          <Route path={Routing?.PaymentFailed} element={<PaymentFailed />} />
          <Route path={Routing?.Collection} element={<Collection />} />
          {/* <Route path={Routing?.SiteMap} element={<Sitemap />} /> */}
          <Route path={Routing?.BestSeller} element={<BestSeller />} />
          <Route path={`${Routing?.productByColor}/:slug`} element={<ProductByColor />} />
          <Route path={Routing?.Shipping} element={<Shipping />} />
          <Route path={Routing?.Privacy} element={<Privacy />} />
          <Route path={Routing?.Exchanges} element={<Exchanges />} />
          <Route path={Routing?.TermCondition} element={<TermCondition />} />
          <Route path={Routing?.CompareDetail} element={<CompareDetail />} />
          <Route path={Routing?.Viewed} element={<Viewed />} />
          <Route path={Routing?.Rewards} element={<Rewards />} />
          <Route path={Routing?.BigSaving} element={<BigSaving />} />
          <Route path={Routing?.Confirmation} element={<Confirmation />} />
          <Route path={Routing?.FinestProduct} element={<FinestProduct />} />
          <Route path={`${Routing?.BlogDetails}/:slug`} element={<BlogDetails />} />
          <Route path={Routing?.ReturnPage} element={<ReturnPage />} />
          <Route path={`${Routing?.AllItems}/:slug`} element={<AllItems />} />
          <Route path={Routing?.ExchangeRate} element={<ExchangeRate />} />
          {/* <Route path={Routing?.Sitemap} element={<SitemapViewer/>} /> */}
          <Route path={slug} element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        <Suspense fallback={<div></div>}>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
