import React, { useState, useRef, useEffect } from 'react'
import { LiaGreaterThanSolid } from "react-icons/lia";
import sliderImg from '../../assets/images/aboutus/who_ware_header.png';
import { getStaticPage } from '../../services/staticPage';

const Aboutus = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const hasMounted = useRef(false);

  const fetchData = async () => {
    try {
      const aboutUsResponse = await getStaticPage('about-us');
      const aboutUsData = aboutUsResponse.data;
      if (aboutUsResponse.status === '1') {
        setAboutUsData(aboutUsData);
      }

      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hasMounted.current) return;
    fetchData();
    hasMounted.current = true;
  }, []);

  return (
    <>

      <section className="section_one_whower">
        <img src={sliderImg} alt="#" />
        <div className="full_img_text">
          <h1>Who we are</h1>
        </div>
      </section>
      <div className="path_about_drinkware">
        <p> Home <LiaGreaterThanSolid /><span> Who we are</span></p>
      </div>

      <div dangerouslySetInnerHTML={{ __html: aboutUsData && aboutUsData.description }}></div>
    </>

  )
}

export default Aboutus;