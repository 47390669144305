import { useSelector } from 'react-redux';

const OutOfStock = ({ productId, stockQuantity }) => {
    const cart = useSelector(state => state.cart);

    const calculateFilteredCartCount = () => {
        const filteredProduct = cart.find(product => product.id === productId);
        return filteredProduct ? filteredProduct.count : 0;
    };

    const isOutOfStock = stockQuantity === 0 || stockQuantity === calculateFilteredCartCount();

    // Return boolean value indicating if product is out of stock
    return isOutOfStock;
};

export default OutOfStock;
