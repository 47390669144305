import React, { useEffect, useRef, useState } from "react";
import { Input, Form, Row, Col, Button, Modal, DatePicker, Select, } from "antd";
import { authApi } from "../../services/auth/authApi";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import moment from "moment";
import OtpInput from "react-otp-input";
import { updateUserFullname } from "../../reducers/userSlice";

function ProfilePage({ setUserName }) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [mobileOtp, setMobileOtp] = useState([]);
  const dispatch = useDispatch();
  const [match, setMatch] = useState(true);
  const [modalValue, setModalValue] = useState()
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState();
  const otpInputs = useRef(Array.from({ length: 4 }, () => React.createRef()));

  const user = useSelector((state) => state.user);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer <= 0) {
      setTimer(0);
      setResendDisabled(false);
    } else {
      setResendDisabled(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleOk = async () => {
    // console.log('mobileOtp', mobileOtp);
    const formData = new URLSearchParams();
    formData.append("otp", mobileOtp);
    formData.append("firstname", modalValue.firstname);
    formData.append("lastname", modalValue.lastname);
    formData.append("contactnumber", modalValue.contactnumber);
    formData.append("email", modalValue.email);
    formData.append("date_of_birth", modalValue?.date_of_birth !== undefined && modalValue?.date_of_birth !== null ? dayjs(modalValue?.date_of_birth).format("YYYY-MM-DD") : '');
    formData.append("gender", modalValue.gender);
    formData.append("father_name", modalValue.father_name);
    formData.append("mother_name", modalValue.mother_name);
    formData.append("marital_status", modalValue.marital_status);
    formData.append("anniversary_date", modalValue?.anniversary_date !== undefined && modalValue?.anniversary_date !== null ? dayjs(modalValue?.anniversary_date).format("YYYY-MM-DD") : '');

    try {
      const response = await authApi.verifyOtp(formData, user?.token);
      if (response.status === "1") {
        fetchEditDetail()
        setIsModalOpen(false);
        setTimer(0);
        setResendDisabled(false);
        setMobileOtp([]); // Clear mobileOtp state
        toast.success("Profile Updated Successfully")
        setMatch(true)
      }
    } catch (error) {
      if (mobileOtp?.length <= 0) {
        toast.error("please enter OTP")
      } else {
        toast.error("You entered incorrect OTP");
      }
    }
    // setIsModalOpen(false);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTimer(0);
    setMobileOtp([]);

  };
  const fetchEditDetail = async () => {
    let result = await authApi.getProfileDetail(user.token);
    if (result.status === "1") {
      setUserName(result?.data?.firstname + " " + result?.data?.lastname);
      setSelectedOption(result?.data);
      const updateData = {
        firstname: result?.data?.firstname,
        lastname: result?.data?.lastname,
        contactnumber: result?.data?.contactnumber,
        email: result?.data?.email,
        date_of_birth: result?.data?.date_of_birth !== "Invalid Date" && result?.data?.date_of_birth !== null ? dayjs(result?.data?.date_of_birth) : undefined,
        gender: result?.data?.gender,
        father_name: result?.data?.father_name !== "null" ? result?.data?.father_name : null,
        mother_name: result?.data?.mother_name !== "null" ? result?.data?.mother_name : null,
        marital_status: result?.data?.marital_status,
        anniversary_date: result?.data?.anniversary_date !== "Invalid Date" && result?.data?.anniversary_date !== null ? dayjs(result?.data?.anniversary_date) : undefined,
      }
      form.setFieldsValue(updateData);
      const userfullname = result?.data?.firstname + " " + result?.data?.lastname;
      dispatch(updateUserFullname(userfullname));
      setShowAnniversary(updateData?.marital_status == "married")
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchEditDetail();
    }
  }, []);

  const onFinish = async (values) => {
    setModalValue(values)
    const formData = new URLSearchParams();
    formData.append("firstname", values.firstname ? values?.firstname : modalValue.firstname);
    formData.append("lastname", values.lastname ? values?.lastname : modalValue?.lastname);
    formData.append("contactnumber", values.contactnumber ? values?.contactnumber : modalValue?.contactnumber);
    formData.append("email", values.email ? values?.email : modalValue?.email);
    formData.append("date_of_birth", values?.date_of_birth !== undefined && values?.date_of_birth !== null ? dayjs(values?.date_of_birth).format("YYYY-MM-DD") : '');
    formData.append("gender", values?.gender || '');
    formData.append("father_name", values?.father_name || '');
    formData.append("mother_name", values?.mother_name || '');
    formData.append("marital_status", values?.marital_status || '');
    formData.append("anniversary_date", values?.anniversary_date !== undefined && values?.anniversary_date !== null ? dayjs(values?.anniversary_date).format("YYYY-MM-DD") : '');

    try {
      let result = await authApi.editUserProfile(formData, user.token);
      if (result.status === "1") {
        alert(result?.otp)
        setTimer(120);
        setIsModalOpen(true);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const [showAnniversary, setShowAnniversary] = useState(false)

  const handleFormChange = () => {
    const currentValues = form.getFieldsValue();
    const hasChanged =
      currentValues.firstname !== selectedOption.firstname ||
      currentValues.lastname !== selectedOption.lastname ||
      currentValues.contactnumber !== selectedOption.contactnumber ||
      currentValues.email !== selectedOption.email ||
      currentValues.gender !== selectedOption.gender ||
      currentValues.father_name !== selectedOption.father_name ||
      currentValues.mother_name !== selectedOption.mother_name ||
      currentValues.marital_status !== selectedOption.marital_status ||
      currentValues.date_of_birth !== selectedOption.date_of_birth ||
      currentValues.anniversary_date !== selectedOption.anniversary_date;
    setMatch(!hasChanged);
    setShowAnniversary(currentValues?.marital_status == "married")
  };

  const handleKeyPressForMobile = (e) => {
    const key = e.key;
    const value = e.target.value;

    if (value.length === 0 && key === '0') {
      e.preventDefault();
      return;
    }
    if (!(key >= "0" && key <= "9") && key !== "Backspace" && key !== "Tab") {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (key >= "0" && key <= "9" || !(key.match(/[a-zA-Z\s]/))) {
      e.preventDefault();
    }
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;



  const renderStarInputs = () => {
    const starInputs = [];
    for (let i = 0; i < 4; i++) {
      starInputs.push(
        <input
          key={i}
          ref={otpInputs.current[i]}
          type="password"
          maxLength={1}
          value={mobileOtp[i] || ''}
          onChange={(e) => handleInputChange(e, i)}
          style={{ width: '3rem', height: '3rem', margin: '0 0.5rem', fontSize: '2rem', borderRadius: '4px', border: '1px solid #ccc', textAlign: 'center' }}
        />
      );
    }
    return starInputs;
  };
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...mobileOtp];
    newOtp[index] = value;

    // Concatenate the individual digits into a single string
    const otpValue = newOtp.join('');

    setMobileOtp(otpValue);

    if (value && index < otpInputs.current.length - 1 && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1].current.focus();
    }
  };

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' }
  ];

  const martialOptions = [
    { label: 'Single', value: 'single' },
    { label: 'Married', value: 'married' },
    { label: 'Other', value: 'other' }
  ];

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }


  return (
    <>
      <div className="profile_form">
        <h1>Edit Details</h1>
        <div className="profile_tabs_form">
          <Form name="control-hooks" layout="vertical" autoComplete="off"
            form={form}
            initialValues={selectedOption}
            onFinish={onFinish}
            onValuesChange={handleFormChange}
          >
            <Row gutter={18}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="firstname" label={<>First Name</>}
                  rules={[{ required: true, message: 'First name is required' }]}

                >
                  <Input
                    className="input_signin"
                    placeholder="Enter your Firstname"
                    onKeyDown={handleKeyPress} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ firstname: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="lastname" label={<>Last Name</>}
                  rules={[{ required: true, message: 'Last name is required' }]}
                >
                  <Input
                    className="input_signin"
                    placeholder="Enter your Lastname"
                    onKeyDown={handleKeyPress} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ lastname: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item
                  name="contactnumber"
                  label={<>Mobile Number</>}
                  rules={[
                    { required: true, message: 'Mobile number is required' },
                    {
                      pattern: /^[0-9]{10}$/, // Validate for a 10-digit number
                      message: 'Please enter a valid mobile number',
                    },
                  ]}
                >
                  <Input
                    className="input_signin"
                    placeholder="Enter your Mobile Number"
                    onKeyDown={handleKeyPressForMobile}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="email" label={<>Email</>}
                  rules={[{ required: true, message: 'Email is required' }, { type: "email", message: "Please enter a valid email" },]}>
                  <Input
                    className="input_signin"
                    placeholder="Enter your Email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="date_of_birth" label={<>Date of Birth</>}
                >
                  <DatePicker className="input_signin" placeholder="Enter your date of birth" disabledDate={disabledDate} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="gender" label={<>Gender</>}>
                  <Select placeholder="Select gender">
                    {genderOptions.map(option => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="father_name" label={<>Father Name</>}>
                  <Input
                    className="input_signin"
                    placeholder="Enter Father name"
                    onKeyDown={handleKeyPress} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ fatherName: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="mother_name" label={<>Mother Name</>}>
                  <Input
                    className="input_signin"
                    placeholder="Enter Mother name"
                    onKeyDown={handleKeyPress} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ motherName: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Form.Item name="marital_status" label={<>Marital Status</>}>
                  <Select placeholder="Select Martial Status">
                    {martialOptions.map(option => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {showAnniversary &&
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="anniversary_date" label={<>Anniversary Date</>}>
                    <DatePicker className="input_signin" placeholder="Enter your date of Anniversary" disabledDate={disabledDate} />
                  </Form.Item>
                </Col>}
              <div className={match ? "payBtn" : "disablepayBtn"}>
                <button type="submit" disabled={match} block>Save Details</button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        className='otpopUp_model'
        footer={
          <div className='custom-modal-footer mt-4'>
            <Button disabled={resendDisabled} onClick={() => onFinish(modalValue)} className='cancel_Button'>Resend OTP</Button>
            <Button key="verify" className='deleteButton' onClick={handleOk}>
              Verify
            </Button>
          </div>
        }
      >
        <div className="otpModel_heading">
          <div className="otpSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12 20.962q-3.013-.895-5.007-3.651Q5 14.554 5 11.1V5.692l7-2.615l7 2.615V11.1q0 3.454-1.993 6.21q-1.994 2.757-5.007 3.652Zm0-1.062q2.425-.75 4.05-2.962T17.95 12H12V4.144L6 6.375v5.156q0 .194.05.469H12v7.9Z" />
            </svg>
          </div>
          <span>Verify with OTP</span>
          <h6>OTP sent on +91 xxxxxx{modalValue?.contactnumber.slice(-4)}</h6>
        </div>
        <div className="otp_inputBox" >
          {renderStarInputs()}
        </div>
        <div className="resend_tym">
          <span>Resend in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
        </div>
      </Modal>
    </>
  );
}

export default ProfilePage;
