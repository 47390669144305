import axios from 'axios';
const apiConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user', 
};  
const api = axios.create(apiConfig);

const handleApiError = (error) => {
    console.errror('API Error:', error);
    throw error;
}

//  About Us API
export const getAboutUs = async () => {
    try {
        const response = await api.get('/about-us');
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`API Error: ${response.statusText}`)
        }
    } catch (error) {
        return handleApiError(error);
    }
}
