import React, { useState } from 'react';
import { Row, Col } from 'antd';
import ReturnProduct from '../../assets/images/1st.png';
import { Form, Checkbox, Select, Input, Image, Upload, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ReturnPage = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [showSuggestedAddress, setShowSuggestedAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleAddressSelect = (e) => {
        setSelectedAddress(e.target.value);
    };


    return (
        <div className='return_container'>
            <h1>Request a Return</h1>
            <Row gutter={[24, 16]}>
                <Col span={18}>
                    <div className='return_mainCards'>
                        <h5>Items From This Order</h5>
                        <div className='product_detailsReturn'>
                            <div className='order_date_return'>
                                <div className='return_date'>
                                    <p>Order Date : </p>
                                    <span>23 June 2023</span>
                                </div>
                                <div className='return_date'>
                                    <p>Order ID: </p>
                                    <span>589345641525</span>
                                </div>
                            </div>
                            <Row>
                                <Col span={4}>
                                    <img src={ReturnProduct} />
                                </Col>
                                <Col span={20}>
                                    <div className='return_details'>
                                        <p>Royal Crimson & White Cup and Saucer Set of 2</p>
                                        <div className='return_option'>
                                            <div className='retuen_color_div'>
                                                <p>Color : </p>
                                                <span>Red</span>
                                            </div>
                                            <div className='retuen_color_div'>
                                                <p>Material  : </p>
                                                <span>porcelain</span>
                                            </div>
                                            <div className='retuen_color_div'>
                                                <p>Pack Contains : </p>
                                                <span>Cups, 2 Saucers</span>
                                            </div>
                                            <div className='retuen_color_div'>
                                                <p>Color : </p>
                                                <span>Red</span>
                                            </div>
                                        </div>
                                        <div className='return_checkout'>
                                            <Checkbox onChange={onChange}>Select to return</Checkbox>

                                            <div className='reason_retuen'>
                                                <Form className='slect_help new-form'>
                                                    <Form.Item
                                                        name="query_id"
                                                        label="Please select your reason"
                                                        className="selectedOption"
                                                        rules={[{ required: true, message: 'Please select your reason!' }]}
                                                    >
                                                        <Select
                                                            className='mt-2'
                                                            placeholder="Please select your reason"
                                                            onChange={onChange}
                                                            filterOption={filterOption}
                                                            options={[
                                                                { value: 1, label: 'Changed my mind' },
                                                                { value: 2, label: 'Received the wrong product' },
                                                                { value: 3, label: 'Issue with the received product' },
                                                                { value: 4, label: 'Payment and billing issues' },
                                                                { value: 5, label: 'Technical support needed' },
                                                            ]}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item name="inquiry" label="Your Reason" className="selectedOption">
                                                        <TextArea placeholder="Write your inquiry" rows={2} />
                                                    </Form.Item>

                                                    <Form.Item name="uploadImg" label="Product Image" className='selectedOption'
                                                        rules={[{ required: true, message: 'Add Product Images!' }]}>
                                                        <div className='upload_files'>
                                                            <Upload
                                                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                                listType="picture-card"
                                                                fileList={fileList}
                                                                onPreview={handlePreview}
                                                                onChange={handleChange}
                                                                multiple
                                                            >
                                                                <div>
                                                                    {fileList.length >= 8 ? null : uploadButton}
                                                                </div>
                                                            </Upload>
                                                            {previewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: previewOpen,
                                                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                    }}
                                                                    src={previewImage}
                                                                />
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='return_mainCards mt-3'>
                        <h5>How Would You Like to Return Your Order?</h5>
                        <div className='return_location'>
                            <Form className='slect_help new-form'>
                                <Form.Item name="email" label="Write a Drop Off Point" className="selectedOption" rules={[{ required: true, message: 'Please enter your email ID!' }, { type: 'email', message: 'Please enter a valid address!' }]}>
                                    <Input placeholder="Enter your address" />
                                    <span
                                        onClick={() => setShowSuggestedAddress((prev) => !prev)}
                                    >
                                        Change Address
                                    </span>
                                </Form.Item>
                            </Form>
                            {showSuggestedAddress && (
                                <Radio.Group onChange={handleAddressSelect} value={selectedAddress}>
                                    <div className='suggested_returnAddress'>
                                        <div className='suggestion_return' onClick={() => setSelectedAddress('address1')}>
                                            <div className='checkout_name'>
                                                <div>
                                                    <Radio value="address1" checked={selectedAddress === 'address1'} />
                                                    <span>Mansi Gupta</span>
                                                </div>
                                                <div className='default_btn'>Default</div>
                                            </div>
                                            <div className='mt-3'>
                                                <p>shanti gali kannu mohalla, bherapur
                                                    Noida, Uttar Pradesh, India-110091</p>
                                            </div>
                                        </div>
                                        <div className='suggestion_return' onClick={() => setSelectedAddress('address2')}>
                                            <div className='checkout_name'>
                                                <div>
                                                    <Radio value="address2" checked={selectedAddress === 'address2'} />
                                                    <span>Mansi Gupta</span>
                                                </div>
                                                <div className='default_btn'>Default</div>
                                            </div>
                                            <div className='mt-3'>
                                                <p>shanti gali kannu mohalla, bherapur
                                                    Noida, Uttar Pradesh, India-110091</p>
                                            </div>
                                        </div>
                                        <div className='suggestion_return' onClick={() => setSelectedAddress('address3')}>
                                            <div className='checkout_name'>
                                                <div>
                                                    <Radio value="address3" checked={selectedAddress === 'address3'} />
                                                    <span>Mansi Gupta</span>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <p>shanti gali kannu mohalla, bherapur
                                                    Noida, Uttar Pradesh, India-110091</p>
                                            </div>
                                        </div>
                                    </div>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                    <div className='return_mainCards mt-3'>
                        <h5>How Can We Make It Right?</h5>
                        <div className='return_money'>
                            <div className='return_changeMethod'>
                                <p>Refunding to your MasterCard ending in 3698</p>
                                <button>Change Method</button>
                            </div>
                            <Form className='slect_help new-form'>
                                <Form.Item
                                    name="query_id"
                                    label="Select Return Method"
                                    className="selectedOption"
                                    rules={[{ required: true, message: 'Please select your return method!' }]}
                                >
                                    <Select
                                        className='mt-2'
                                        placeholder="Please select your return method"
                                        onChange={onChange}
                                        filterOption={filterOption}
                                        options={[
                                            { value: 1, label: 'Refund to Original Payment Method' },
                                            { value: 2, label: 'Store Credit' },
                                            { value: 3, label: 'Exchange for Another Product' },
                                            { value: 4, label: 'Gift Card' },
                                            { value: 5, label: 'Replacement' },
                                        ]}
                                    />
                                </Form.Item>

                            </Form>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='returned_amoutnted'>
                        <button>Confirm Your Return</button>
                        <p>Return by 29 Nov, 2024</p>
                        <div className='amouted_details'>
                            <strong>Refund Summary</strong>
                            <div className='amoutned_para'>
                                <p>Refund Subtotal</p>
                                <span>₹499</span>
                            </div>
                            <div className='amoutned_para'>
                                <p>Shipping <span>(Circle Design Whiskey Glass Set of 2-Open 7 Days a week)</span></p>
                                <span>₹499</span>
                            </div>
                            <div className='amoutned_para'>
                                <p>Total Estimated Refund</p>
                                <span>₹499</span>
                            </div>
                        </div>
                        <div className='mt-2 returm_imf'>
                            <p>Items You're Returning</p>
                            <img src={ReturnProduct} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='para_return'>
                <p><strong>Please Note : </strong>Items must be returned unused and in their original condition (including all labels and tags intact). Products must not contain personal data or be registered to the customer with the manufacturer (e.g., mobile phones, tablets, computers). Read our <span> return policy </span> to find out more.</p>
            </div>
            <div className='request_btn'>
                <button>Place Request</button>
            </div>
        </div>
    )
}

export default ReturnPage