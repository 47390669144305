import React, { useEffect, useRef, useState } from 'react';
import { getStaticPage } from '../../services/staticPage';


function Privacy() {

  const [privacyData, setPrivacyData] = useState(null);
  const hasMounted = useRef(false);

  const fetchData = async () => {
    try {
      const response = await getStaticPage('privacy-policy');
      // console.log('responseresponse', response);
      if (response.status === '1') {
        setPrivacyData(response.data);
      }

      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hasMounted.current) return;
    fetchData();
    hasMounted.current = true;
  }, []);
  return (
    <div className='shipping_container'>
      <h1>{privacyData?.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: privacyData && privacyData?.description }} className='shipping_content'></div>
    </div>
  )
}

export default Privacy