// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

let storeInstance; // store instance

export const initializeUserSlice = (store) => {
  storeInstance = store; // store instance is set during initialization
};

// Retrieve user from local storage
const storedUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user')) || {} : {};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...storedUser, lastLoginTime: null },
  reducers: {
    addToUser: (state, action) => {
      return { ...state, ...action.payload, lastLoginTime: new Date().getTime() };
    },

    updateUserFullname: (state, action) => {
      state.userfullname = action.payload;
    },

    clearUser: () => {
      return { lastLoginTime: Date.now() };
    },
  },
});

export const { addToUser, clearUser, updateUserFullname } = userSlice.actions;

// Add an event listener to detect changes in local storage
window.addEventListener('storage', (event) => {
  // console.log(event);
  if (event.key === 'user') {
    const updatedUser = JSON.parse(event.newValue) || {};
    // Dispatch addToUser action with the updated user from local storage
    storeInstance.dispatch(addToUser(updatedUser));
  }
  if (event.key === 'logoutEvent') {
    // Dispatch clearUser action when handling 'logoutEvent'
    storeInstance.dispatch(clearUser());
  }
});


export default userSlice.reducer;
