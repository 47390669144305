import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { addToCompare, clearCompare } from '../reducers/compareSlice';
import React, { useEffect } from 'react';
import Routes from '../Routes/Routes';

const Compare = ({ openCompare, onCloseCompare, dataSlug }) => {
    const comp = useSelector(state => state.comp);
    const navigate = useNavigate();

    useEffect(() => {
        const compareData = JSON.parse(localStorage.getItem('compareData')) || {};
        if (dataSlug !== null) {
            compareData.catSlug = dataSlug;
        }

        // if (dataSlug !== comp[0]?.slug) {
        //     dispatch(clearCompare());
        //     localStorage.removeItem('comp');
        // }
    }, []);
    const handleClearAll = () => {
        dispatch(clearCompare());
        localStorage.removeItem('comp');
    }
    const handleCompareAll = () => {
        if(comp.length >0){
            navigate(`${Routes.Home}compareDetail`);
        }
    }
    const compareData = JSON.parse(localStorage.getItem('compareData')) || {};
    const { product_name = '', price = 0, comimage } = compareData;
    const dispatch = useDispatch();
    const handleCloseIcon = (id) => {
        const updatedComp = comp.filter(item => item.id !== id);
        dispatch(addToCompare(updatedComp));
        localStorage.setItem('comp', JSON.stringify(updatedComp));
        // console.log('Close icon clicked!');
    };
    return (
        <Drawer
            title="Compare Your Product"
            open={openCompare}
            placement='bottom'
            className='compare_productDrawer'
            onClose={onCloseCompare}
        >
            <div className='compare_drawerContent'>
                {/* Render first comparison with local storage data */}
                <div className='compare_Content comapre_btnComapre'>

                    <img src={comimage} alt={product_name} />
                    <span>{product_name}</span>
                    <p>₹{price}</p>
                    {/* <div className='close_icon'>
                        <CloseIcon />
                    </div> */}
                </div>
                {/* Render remaining comparison slots */}
                {[...Array(3)].map((_, index) => (
                    comp[index] && comp[index].id ? (
                        <div key={index} className='compare_Content'>
                            <img src={comp[index].comimage} alt={comp[index].product_name} />
                            <span>{comp[index].product_name}</span>
                            <p>₹{comp[index].price}</p>
                            <div className='close_icon' onClick={() => handleCloseIcon(comp[index].id)}>
                                <CloseIcon />
                            </div>
                        </div>
                    ) : (
                        <EmptyComparisonIcon key={index} dataSlug={dataSlug} />
                    )
                ))}

                {/* Render buttons */}
                <div className='compare_buttons'>
                <button onClick={handleCompareAll} style={{ cursor: comp.length === 0 ? 'not-allowed' : 'pointer' }}>Compare All</button>
                    <button className='clear_btn' onClick={handleClearAll}>Clear All</button>
                </div>
            </div>
        </Drawer>
    );
}

const CloseIcon = () => (
    <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 8L40 40" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 40L40 8" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const EmptyComparisonIcon = ({ dataSlug }) => (
    <Link to={`/collections/${dataSlug}`} state={{ compare: true }}>
        <div className='compare_Content_empty'>
            <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0605 10L24.0239 38" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 24L38 24" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    </Link>
);

export default Compare;
