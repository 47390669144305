import React from 'react';
import { Carousel } from 'react-bootstrap';
import DinninhImg from '../../assets/images/soulsip.png';
import { LiaGreaterThanSolid } from "react-icons/lia";
import { Pagination } from 'antd';
import collectImg from '../../assets/images/dining1.png';
import collectImg1 from '../../assets/images/dining3.png';
import collectImg2 from '../../assets/images/drinkwareBox.png';
import collectImg3 from '../../assets/images/basket.png';
import collectImg4 from '../../assets/images/2nd.png';
import collectImg5 from '../../assets/images/drinkwarebot.png'
import collectImg6 from '../../assets/images/drinkwarecup1.png';
import collectImg7 from '../../assets/images/gift1.png';

const Collection = () => {
    return (
        <div className="collection_div">
            <div className="drinkware_top_img_sec">
                <Carousel>
                    <Carousel.Item>
                        <img src={DinninhImg} alt='' />
                        <Carousel.Caption>
                            <div className='giftly_heading_para'>
                                <h1>Collections</h1>
                                <p>Explore similar pieces you've been eyeing!</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='path_drinkware'>
                Home <LiaGreaterThanSolid /> <p>Our Collection</p>
            </div>
            <div className='collection_all'>
                <div className='collection_crds'>
                    <div className='collection_mainDiv'>
                        <img src={collectImg1} />
                        <p>10%-15% off</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg2} />
                        <p>22 Pieces Dinner Set</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg} />
                        <p>20%-25% off</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg1} />
                        <p>28 Pieces Dinner Set</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg2} />
                        <p>Accessories</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg} />
                        <p>Artificial Flowers</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg3} />
                        <p>Baking Dishes</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg4} />
                        <p>Ceramic Dishes</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg5} />
                        <p>Glass Bottles</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg6} />
                        <p>Ceramic Cups</p>
                    </div>
                    <div className='collection_mainDiv'>
                        <img src={collectImg7} />
                        <p>Gifts Items</p>
                    </div>
                </div>
            </div>
            <div className='collect_pagination'>
                <Pagination defaultCurrent={1} total={50} />
            </div>
        </div>
    )
}

export default Collection
