// eventkeySlice.js
import { createSlice } from '@reduxjs/toolkit';

const eventkeySlice = createSlice({
  name: 'eventkey',
  initialState: {},
  reducers: {
    addToEventKey: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToEventKey } = eventkeySlice.actions;
export default eventkeySlice.reducer;
