import React, { useState } from 'react'
import { Checkbox } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import hdfc from '../../assets/images/hdfc.png';
import Axis from '../../assets/images/axis.png';
import Sbi from '../../assets/images/sbi.png';
import Icici from '../../assets/images/icici.png';
import Kotak from '../../assets/images/kotak.png';
import PaytmBank from '../../assets/images/paytm.png';
import yesBank from '../../assets/images/yes.png';
import bank from '../../assets/images/banks.png';

function NetBanking() {
    const [isChecked, setIsChecked] = useState(false);

    const handleCardClick = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className='netbanking_form'>
                <h2>Pay with Net Banking</h2>
                <div className='netBanking_warning'>
                    <WarningOutlined />
                    <span>Vijaya & Punjab & Sind are experiencing a low success rate. KIndly use another way to pay.</span>
                </div>
                <div className='netbanking_checkout'>
                    <div className='netbanking_card' onClick={handleCardClick}>
                        <div className='netBanking_img'>
                            <img src={Axis} />
                            <span>AXIS</span>
                        </div>
                        <Checkbox checked={isChecked} />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={hdfc} />
                            <span>HDFC</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={Sbi} />
                            <span>SBI</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={Icici} />
                            <span>ICICI</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={Kotak} />
                            <span>KOTAK</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={PaytmBank} />
                            <span>PAYTM BANK</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={yesBank} />
                            <span>YES BANK</span>
                        </div>
                        <Checkbox />
                    </div>
                    <div className='netbanking_card'>
                        <div className='netBanking_img'>
                            <img src={bank} />
                            <span>Other Bank</span>
                        </div>
                        <Checkbox />
                    </div>
                </div>
                <div className='payBtn'>
                    <button>Pay 1000 </button>
                </div>
            </div>
        </>
    )
}

export default NetBanking