import React, { useMemo } from 'react';
import Slider from 'react-slick';
import Wishlist from '../../function/Wishlist';
import AddToCart from '../../function/AddToCart';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import ProductPrice from '../ProductPrice';

const RelatedProducts = ({ relatedData, relatedSettings, imgPath, handleRelated, Routes }) => {
    const navigate = useNavigate();

    const variantSlugNavigate = (slug) => {
        navigate(`${Routes.ProductDetail}/${slug}`);
    };

    const relatedProductList = useMemo(() => (
        relatedData?.length > 0 && !relatedData?.every(item => item.relatedproduct == null) && (
            <div className="product_all_mainSec">
                <h2>You may also like</h2>
                <div className="dinner_slider">
                    <Slider {...relatedSettings}>
                        {relatedData?.map((item, index) => (
                            item?.relatedproduct !== null && (
                                <div key={index} className="dinner_sliderImg">
                                    <div className="dinnerCategory_cont">
                                        <div
                                            className="imageContainer"
                                            onClick={() => variantSlugNavigate(item?.relatedproduct?.product_slug)}
                                        >
                                            <img
                                                src={`${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}`}
                                                alt=""
                                            />
                                            <img
                                                src={!item?.relatedproduct?.productimages[1]
                                                    ? `${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}`
                                                    : `${imgPath}${item?.relatedproduct?.productimages[1]?.file_name}`}
                                                className="hoverImage"
                                                alt=""
                                            />
                                        </div>
                                        {item?.relatedproduct?.stock_quantity < 1 ? (
                                            <div className="sold_off_chips">
                                                <p>Sold Out</p>
                                            </div>
                                        ) : (
                                            item?.relatedproduct?.discount > 0 && (
                                                <div className="off_chips">
                                                    <p>{item.relatedproduct.discount}% off</p>
                                                </div>
                                            )
                                        )}

                                        <Wishlist
                                            is_wishlist={item?.relatedproduct?.is_wishlist}
                                            pId={item?.related_product_id}
                                            path={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`}
                                            mode="HeartOutlined"
                                        />
                                        <AddToCart
                                            imgPath={imgPath}
                                            productList={item?.relatedproduct}
                                        />
                                    </div>
                                    <div
                                        className="dinnerSlider_details"
                                        onClick={() => handleRelated(item?.relatedproduct?.product_slug)}
                                    >
                                        <p>{item?.relatedproduct?.product_name}</p>
                                        <div className="dinnerSliderSpan">
                                            <ProductPrice product={item?.relatedproduct} />
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </Slider>
                </div>
            </div>
        )
    ), [relatedData, relatedSettings, imgPath, handleRelated, Routes]);

    return relatedProductList;
};

export default RelatedProducts;
