import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { deleteUserAddress, updateUserAddress } from '../../services/user/updateUserAddress';
import { addUserAddress } from '../../services/user/addUserAddress';
import { Drawer, Form, Modal, Button } from 'antd';
import AddressForm from '../../components/forms/AddressForm';
import { getSingleAddress } from '../../services/user/singleAddress';
import { getAddressData } from '../../services/user/getUserAddress';
import { STRINGS } from '../../constants/Constant';
import NoDataimg from '../../assets/images/noData.png'
import trashLottie from '../../assets/images/trash-lottie.json'
import Lottie from 'react-lottie'


const CustomDrawer = ({ onClose, open, editMode, existingData, fetchAddressList, setIsSaving, isSaving, addressList, setShowBillingForm, showBillingForm, form, resetFields }) => {
    // console.log(addressList,"addressList")
    const user = useSelector((state) => state.user);
    const { token } = user;
    // const [form] = Form.useForm();
    const [addressTypeValue, setAddressTypeValue] = useState('');
    const [defaultValue, setDefaultValue] = useState(false);

    // const resetFields = () => {
    //     form.resetFields();
    // }
    const handleSubmit = async (values) => {
        setIsSaving(true);
        // console.log('values==>>>>29>>>', values);
        const { country, state, city, pincode, address, name, phone, land_mark, title, is_default, isGst, gstNumber, gstCompanyName, gstAddress, is_billing_address, billing_address, billing_city_id, billing_country_id, billing_state_id, billing_name, billing_phone, billing_pincode, is_shipping_address } = values;
        const newDefaultValue = is_default ? 1 : 0;
        // console.log(is_default, "isDefault")
        let formData = new URLSearchParams();

        formData.append('title', addressTypeValue);
        formData.append('name', name);
        formData.append('country_id', country);
        formData.append('state_id', state);
        formData.append('city_id', city);
        formData.append('pincode', pincode);
        formData.append('address', address);
        formData.append('land_mark', land_mark !== "undefined" ? land_mark : "");
        formData.append('mobile_number', phone);
        formData.append('is_default', addressList?.length == '0' || addressList?.length === undefined ? 1 : newDefaultValue);
        formData.append('apartment', '');
        // GST
        formData.append('is_gst', isGst ? '1' : '0');
        formData.append('gst_number', isGst ? gstNumber : '')
        formData.append('company_name', isGst ? gstCompanyName : '');
        formData.append('company_address', isGst ? gstAddress : '');
        // Billing_Address
        formData.append('is_shipping_address', is_shipping_address ? '1' : is_billing_address == false ? '1' : '0');
        formData.append('is_billing_address', is_billing_address ? '1' : '0');

        formData.append('billing_name', billing_name);
        formData.append('billing_phone', billing_phone);
        formData.append('billing_country_id', billing_country_id);
        formData.append('billing_state_id', billing_state_id);
        formData.append('billing_city_id', billing_city_id);
        formData.append('billing_pincode', billing_pincode);
        formData.append('billing_address', billing_address);


        try {
            if (editMode && existingData) {
                formData.append('id', existingData.id);

                const updateResponse = await updateUserAddress(formData, token);
                if (updateResponse.status === '1') {
                    setIsSaving(false);
                    toast.success(STRINGS.ADDRESS_UPDATED_SUCCESSFULLY);
                    onClose(false)
                    fetchAddressList();
                    setAddressTypeValue('')
                } else {
                    toast.error(STRINGS.FAILED_TO_UPDATE_ADDRESS);
                }
            } else {
                const addResponse = await addUserAddress(formData, token);
                if (addResponse.status === '1') {
                    setIsSaving(false);
                    toast.success(STRINGS.ADDRESS_ADDED_SUCCESSFULLY);
                    onClose(false)
                    resetFields()
                    fetchAddressList();
                    setAddressTypeValue('')
                    setDefaultValue(false)
                } else {
                    toast.error(STRINGS.FAILED_TO_ADD_ADDRESS);
                }
            }
        } catch (error) {
            console.error('AP', error);
            // message.error('Address submission failed.');
            if (addressTypeValue === "") {
                toast.error("Please select type of address", { toastId: "" })
            } else {
                toast.error(error?.message);
            }
        }finally {
            setIsSaving(false); // Reset loading state after API response
        }
    };
    return (
        <Drawer title={`${editMode ? 'Edit the address' : 'Add new address'}`} placement="right" onClose={onClose} open={open} width={550}>
            <div className="model_address_text">
                {/* <h3>Address</h3> */}
                <AddressForm onSubmit={handleSubmit} editMode={editMode} existingData={existingData} form={form} onClose={onClose} setAddressTypeValue={setAddressTypeValue} addressTypeValue={addressTypeValue} setDefaultValue={setDefaultValue} defaultValue={defaultValue} isSaving={isSaving} open={open} setShowBillingForm={setShowBillingForm} showBillingForm={showBillingForm} />
            </div>
        </Drawer>
    );
};


function AddressPage() {
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [existingData, setExistingData] = useState(null);
    const [addressList, setAddressList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addressId, setAddressId] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [catchError, setCatchError] = useState(true)
    const [showBillingForm, setShowBillingForm] = useState(false);
    const [form] = Form.useForm();

    const userData = useSelector((state) => state.user);
    const { token } = userData;
    const onClose = () => {
        setOpen(false);
        setIsSaving(false);
    };

    const resetFields = () => {
        form.resetFields();
    }


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (isDeleting) return;

        setIsDeleting(true);
        try {
            const formData = new URLSearchParams();
            formData.append('id', addressId);

            let result = await deleteUserAddress(formData);
            if (result.data.status === '1') {
                fetchAddressList();
                toast.success(STRINGS.ADDRESS_REMOVED_SUCCESSFULLY);
            }

        }
        catch (e) {
            // console.log('e=>', e)
            toast.error(STRINGS.ADDRESS_NOT_REMOVED);

        }
        setIsDeleting(false);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showDrawer = async (edit, data) => {
        setEditMode(edit);
        if (edit) {
            try {
                const formData = new URLSearchParams();
                formData.append('id', data);
                const addressData = await getSingleAddress(formData, token);
                if (addressData.status === '1') {
                    setExistingData(addressData.data);
                }
            } catch (error) {
                console.error('Error fetching address data:', error);
            }
        } else {
            setExistingData(null);
            setShowBillingForm(false);
            resetFields();
        }
        setOpen(true);
    };

    const fetchAddressList = async () => {
        try {
            let result = await getAddressData(token);
            setAddressList(result?.data);
            setCatchError(true)
        }
        catch (e) {
            // console.log('error', e);
            setCatchError(false)
        }
    }

    const removeAddress = async (addressId) => {
        showModal();
        setAddressId(addressId);
    }


    useEffect(() => {
        fetchAddressList();
    }, [])


    // const showAddressList = () => {
    //     return (
    //         catchError === true ?
    //             addressList?.map((item) => {
    //                 // console.log(item, "item")
    //                 // console.log(item,"item")
    //                 return (
    //                     <>
    //                     {item.address&&
    //                         <div className="default_card">
    //                             <div className='defualts_cards_address'>
    //                                 <div className='name_addresssed'>
    //                                     <h3>{item.name}</h3>
    //                                     <span className={`${item?.is_default === 1 || addressList.length === 1 ? 'defaultAddress' : ''}`}>{item?.is_default === 1 || addressList.length == 1 ? 'Default' : ''}</span>
    //                                 </div>
    //                                 <div>
    //                                     <div className='shippingAddr'>
    //                                         <h4>Shipping Address</h4>
    //                                     </div>
    //                                     <div className='address_spn'>
    //                                         <span>{item?.address} ,</span>
    //                                         <span>{item?.city?.city_name},</span>
    //                                         <span>{item?.state?.state_name}</span>
    //                                         <p> {item?.country?.country_name}, {item?.zip_code}</p>
    //                                         {item?.land_mark !== "undefined" && item?.land_mark !== "" ?
    //                                             <p><strong>Landmark : </strong> {item?.land_mark !== "undefined" ? item?.land_mark : ''}</p>
    //                                             : ""}
    //                                         <p><strong>Address type : </strong> {item?.title}</p>
    //                                     </div>
    //                                     <div className='mobileSpn'>
    //                                         <span><strong>Mobile : </strong> {item?.mobile_number}</span>
    //                                     </div>
    //                                 </div>
    //                                 {/* {item.gst_number !== null && item.gst_number !== "" &&
    //                                     <div className='mobileSpn'>
    //                                         <span><strong>GST No:</strong> {item.gst_number}</span>
    //                                     </div>} */}

    //                                 {item?.is_billing_address == '1'&&
    //                                 <div>
    //                                     <div className='shippingAddr'>
    //                                         <h4>Billing Address</h4>
    //                                     </div>

    //                                     <div className='address_spn'>
    //                                         <span>{item?.billing_name}</span><br/>
    //                                         <span style={{paddingTop:8}}>{item?.billing_address} {item?.billing_city?.city_name}, </span>
    //                                         <span>{item?.billing_state?.state_name}</span>
    //                                         <p>{item?.billing_country?.country_name}, {item?.billing_pincode} </p>
    //                                         <p style={{paddingBottom:10}}><strong>Phone : </strong> +91-{item?.billing_phone}</p>
    //                                     </div>
    //                                 </div>}
    //                             </div>
    //                             <div className='address_btns'>
    //                                 <button onClick={() => showDrawer(true, item.id)}>Edit</button>
    //                                 {item?.is_default !== 1 &&
    //                                     <button onClick={() => removeAddress(item.id)}>Remove</button>}
    //                             </div>
    //                         </div>
    //                         }
    //                     </>
    //                 )
    //             })
    //             : (
    //                 <div className='noDataCont'>
    //                     <img src={NoDataimg} alt='' />
    //                     <h5>No Data Found</h5>
    //                 </div>
    //             )
    //     )
    // }
    const showAddressList = () => {
        return (
            catchError === true ? (
                addressList?.map((item) => (
                  
                        <div key={item.id} className="default_card">
                            <div className='defualts_cards_address'>
                                <div className='name_addresssed'>
                                    <h3>{item.name}</h3>
                                    <span className={item.is_default === 1 || addressList.length === 1 ? 'defaultAddress' : ''}>{item.is_default === 1 || addressList.length === 1 ? 'Default' : ''}</span>
                                </div>
                                <div>
                                    <div className='shippingAddr'>
                                        <h4>Shipping Address</h4>
                                    </div>
                                    <div className='address_spn'>
                                        <span>{item.address},</span>
                                        <span>{item.city?.city_name},</span>
                                        <span>{item.state?.state_name}</span>
                                        <p>{item.country?.country_name}, {item.zip_code}</p>
                                        {item.land_mark!=='null' && item?.land_mark !=='' && item?.land_mark !== "undefined" && <p><strong>Landmark :</strong> {item.land_mark}</p>}
                                        {item?.title!=='null' &&
                                        <p><strong>Address type :</strong> {item.title}</p>}
                                    </div>
                                    <div className='mobileSpn'>
                                        <span><strong>Mobile :</strong> +91-{item?.mobile_number?.toUpperCase()}</span>
                                    </div>
                                </div>
                                {item.is_billing_address == '1' && (
                                    <div>
                                        <div className='shippingAddr'>
                                            <h4>Billing Address</h4>
                                        </div>
                                        <div className='address_spn'>
                                            <span>{item.billing_name}</span><br/>
                                            <span style={{ paddingTop: 8 }}>{item.billing_address} {item.billing_city?.city_name},</span>
                                            <span>{item.billing_state?.state_name}</span>
                                            <p>{item.billing_country?.country_name}, {item.billing_pincode}</p>
                                            <p style={{ paddingBottom: 10 }}><strong>Mobile :</strong> +91-{item.billing_phone}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='address_btns'>
                                <button onClick={() => showDrawer(true, item.id)}>Edit</button>
                                {item.is_default !== 1 && <button onClick={() => removeAddress(item.id)}>Remove</button>}
                            </div>
                        </div>
                    
                ))
            ) : (
                <div className='noDataCont'>
                    <img src={NoDataimg} alt='' />
                    <h5>No Data Found</h5>
                </div>
            )
        );
    };
    
    
    
    


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: trashLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <div className='address_Page'>
                <div className='SavedAddresses'>
                    <h1>Saved Addresses</h1>
                    <button onClick={() => showDrawer(false, 6)}>+ Add New Address</button>
                </div>

                <div className={catchError === true && 'multiple_cardAdrress'}>
                    {showAddressList()}
                </div>

            </div>
            <CustomDrawer onClose={onClose} open={open} editMode={editMode} existingData={existingData} fetchAddressList={fetchAddressList} setIsSaving={setIsSaving} isSaving={isSaving} addressList={addressList} setShowBillingForm={setShowBillingForm} showBillingForm={showBillingForm} form={form} resetFields={resetFields} />

            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={isDeleting}
                className='deletePopUp_model'
                footer={
                    <div className='custom-modal-footer'>
                        <Button key="cancel" onClick={handleCancel} className='cancel_Button'>
                            Cancel
                        </Button>
                        <Button key="delete" onClick={handleOk} loading={isDeleting} className='deleteButton'>
                            Remove
                        </Button>
                    </div>
                }
            >
                <div className='delete_popUpDesign'>
                    <p>Are you sure to remove this address?</p>
                    <Lottie options={defaultOptions}
                        max-height={200}
                        width={250}
                    />

                </div>
            </Modal>

        </>
    )
}

export default AddressPage