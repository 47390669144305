import { createSlice } from '@reduxjs/toolkit';

const storedCoupon = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('coupon')) || [] : [];
// console.log('storedcoupon', storedCoupon);

const couponSlice = createSlice({
  name: 'couponDis',
  initialState: storedCoupon,  // You can define an initial state if needed
  reducers: {
    applyCoupon: (state, action) => action.payload,
  },
});

export const { applyCoupon } = couponSlice.actions;

export default couponSlice.reducer;
