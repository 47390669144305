import React from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { clearBuy } from '../../reducers/buySlice';

const ProceedSection = ({ grandTotal = 0, onClose,showGSTForm,isINR,currencySymbol}) => {
  const { id } = useSelector((state) => state.user);
  const cartIds = useSelector((state) => state.cartId);
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const excRate = useSelector(state => state.excrate);

  const renderProceedButton = () => {
    if (grandTotal !== 0 && !showGSTForm) {
      return (
        <button className='proceed_btn' onClick={handleProceedClick} style={{ cursor: 'pointer' }}>
          Proceed
          <i><svg width="23" height="23" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 12L24 24L12 36" stroke="#fff" strokeWidth="3" clipRule="round" strokeLinejoin="round" /><path d="M24 12L36 24L24 36" stroke="#fff" strokeWidth="3" clipRule="round" strokeLinejoin="round" /></svg>
          </i>
        </button>
      );
    } else {
      return (
        <button className='proceed_btn' disabled style={{ cursor: 'not-allowed' }}>
          Proceed
          <i>
            <svg width="23" height="23" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 12L24 24L12 36" stroke="#fff" strokeWidth="3" clipRule="round" strokeLinejoin="round" /><path d="M24 12L36 24L24 36" stroke="#fff" strokeWidth="3" clipRule="round" strokeLinejoin="round" /></svg>
          </i>
        </button>
      );
    }
  };
  const buy = useSelector((state) => state.buy) || [];
  const handleProceedClick = () => {
    if (buy.length > 0) {
      dispatch(clearBuy());
      localStorage.removeItem('buy');
    }

    if (cartIds.length === cart.length) {
      localStorage.setItem('localCartIds', JSON.stringify([]));
    }
    if (id) {
      navigate(Routes?.Checkout);
    } else {
      navigate(Routes?.SignIn, { state: { from: Routes?.Checkout } });
    }
    onClose();
  };

  return (
    <div className='proceed_drawer'>
      <div className='proceed_money'>
        <p className='bold_thousand'>{isINR ?"₹":currencySymbol} {grandTotal.toFixed(2)}</p>
        <p>Grand Total</p>
      </div>
      {renderProceedButton()}
    </div>
  );
};

export default ProceedSection;
