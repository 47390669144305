import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { toast } from "react-toastify";
import { createGstInfo, getGstInfo } from "../services/user/addUserAddress";
import { useSelector } from "react-redux";

const GstInfo = ({ showGSTForm, setShowGSTForm }) => {
  const [loading, setLoading] = useState(false);
  const { token: userToken } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchGstData = async () => {
      try {
        setLoading(true);
        const { status, data } = await getGstInfo(userToken);
        if (status === "1") {
          form.setFieldsValue(data);
          sessionStorage.setItem("Gst_number", data?.gst_number)
        }
      } catch (error) {
        console.error("Error fetching GST data:", error);
        // toast.error("Failed to fetch GST data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchGstData();
  }, [userToken, form, showGSTForm]);

  const handleSubmit = async ({
    gst_number,
    company_name,
    company_address,
  }) => {
    try {
      setLoading(true);
      const formData = new URLSearchParams();

      if (showGSTForm) {
        formData.append("gst_number", gst_number);
        formData.append("company_name", company_name);
        formData.append("company_address", company_address);
      }

      const addResponse = await createGstInfo(formData, userToken);
      if (addResponse.status === "1") {
        toast.success("Gst address added successfully");
        form.resetFields();
        setShowGSTForm(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Invalid GST Number");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setShowGSTForm(e.target.checked);
    if (!e.target.checked) {
      form.resetFields(["gst_number", "company_name", "company_address"]);
      form.validateFields(["gst_number", "company_name", "company_address"]);
    }
  };

  return (
    <div className="gst_cartCont">
      <Checkbox onChange={handleCheckboxChange}>
        Do you want to give your GST information?
      </Checkbox>
      {showGSTForm && (
        <div className="gst_form">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="gst_number"
              label="GST Number"
              rules={[
                {
                  pattern: /^[0-9A-Za-z]{15}$/,
                  required: true,
                  message: "Please enter a valid 15-digit GST number",
                },
              ]}
            >
              <Input
                placeholder="Enter GST number"
                maxLength={15}
                type="text"
              />
            </Form.Item>

            <Form.Item
              name="company_name"
              label="Company Name"
              rules={[
                { required: true, message: "Please enter your company name" },
              ]}
            >
              <Input placeholder="Enter your Company name" />
            </Form.Item>

            <Form.Item
              name="company_address"
              label="Address"
              rules={[{ required: true, message: "Please enter your address" }]}
            >
              <Input placeholder="Enter Address" />
            </Form.Item>

            <Form.Item>
              <button htmlType="submit" className="gst_submitBtn">Submit</button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default GstInfo;
