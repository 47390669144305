import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you have Bootstrap CSS included

const Banner = ({ category, categoryImgPath, sliderPath }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = useCallback((selectedIndex) => {
    setIndex(selectedIndex);
  }, []);

  const renderCarouselItems = useMemo(() => {
    if (category?.assign_slider !== null) {
      return category?.assign_slider?.page_slider?.page_slider_images.map((slider, index) => (
        <Carousel.Item key={index}>
          <img src={`${sliderPath}${slider.image}`} alt={slider.image_title} />
          <Carousel.Caption>
            <div className='giftly_heading_para'>
              <h1>{slider.image_title}</h1>
              <p>{slider.description}</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ));
    } else {
      return (
        <Carousel.Item>
          <img
            src={category?.image ? `${categoryImgPath}${category?.image}` : ''}
            alt={category?.name || ''}
          />
          <Carousel.Caption>
            <div className='giftly_heading_para'>
              <h1>{category?.name}</h1>
              <p>{category?.description}</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      );
    }
  }, [category, categoryImgPath, sliderPath]);

  return (
    <div className="drinkware_top_img_sec">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {renderCarouselItems}
      </Carousel>
    </div>
  );
};

Banner.propTypes = {
  category: PropTypes.object,
  categoryImgPath: PropTypes.string.isRequired,
  sliderPath: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  category: null,
};

export default React.memo(Banner);
