import { createSlice } from '@reduxjs/toolkit';

const storedCartId = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('cartId')) || [] : [];

const cartIdSlice = createSlice({
  name: 'cartId',
  initialState: storedCartId,
  reducers: {
    addToCartId: (state, action) => {
      return action.payload; // This replaces the entire state with the new payload
    
    },
  },
});

export const { addToCartId} = cartIdSlice.actions;

export default cartIdSlice.reducer;
