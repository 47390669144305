import React, { useEffect, useState } from 'react'

const VerticalSlider = () => {

    const [data, setData] = useState([
        {
            id: 1,
            isActive: true,
            title: "Serra da Freita, Vale de Cambra, Portugal",
            subTitle: "@hed",
            image: "https://images.unsplash.com/photo-1524572217604-17e96c89e56f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80"
        },
        {
            id: 2,
            isActive: false,
            title: "París, Paris, France",
            subTitle: "@lolaguti",
            image: "https://images.unsplash.com/photo-1444384851176-6e23071c6127?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80"
        },
        {
            id: 3,
            isActive: false,
            title: "Serra da Freita, Vale de Cambra, Portugal",
            subTitle: "@hed",
            image: 'https://images.unsplash.com/photo-1483663167873-0cfcc893b78c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2096&q=80'
        },
        {
            id: 4,
            isActive: false,
            title: "París, Paris, France",
            subTitle: "@lolaguti",
            image: "https://images.unsplash.com/photo-1482406611936-43ea538e39d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2110&q=80"
        },
        {
            id: 5,
            isActive: false,
            title: 'Kitchen style',
            subTitle: "@kitchenStyle",
            image: 'https://images.unsplash.com/photo-1475116127127-e3ce09ee84e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80'
        }
    ])


    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % data.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [data.length]);


    return (
        <div className="general-container" >
            {data.map((item, index) => (
                <div onClick={() => handleClick(index)} className={`content ${index === activeIndex ? 'active' : ''}`} style={{ backgroundImage: `url(${item.image})` }}>
                    <h3 className="card-title">
                        {item.title}
                        <span className="subtitle">{item.subTitle}</span>
                    </h3>
                </div>
            ))}
        </div>
    )
}

export default VerticalSlider