import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToExcRate } from '../reducers/excRateSlice';

const ExchangeRate = () => {
  const [country, setCountry] = useState('');
  const [currencyCodey, setCurrencyCode] = useState('');
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCountryAndConvertCurrency = async () => {
      try {
        let countryName;
        // step 1:
        // Get country from IP
        // const ip = '8.8.8.8'; // Example IP address
          const response = await axios.get('https://ipapi.co/json/');
          countryName = response?.data.country_name;
            // const response = await axios.get('https://ipinfo.io/json');
            console.log('countryName',countryName);
            // const ipAddress = response?.data?.ip;
            
            // let countryName;

            // if(ipAddress === "8.8.8.8") {
            //   countryName = "United States";
            // } else {
            //     countryName = "India";
            // }
            // const ipaddress = { "ipaddress": "106.206.152.123" };
            // const countryResponse = await axios.post(
            //   'https://shootbazaar.in/ash/index.php/getcountries', 
            //   ipaddress, 
            //   {
            //       headers: {
            //           'Content-Type': 'application/json'
            //       }
            //   }
            // );
          // console.log(countryResponse.data);
        // const countryName=countryResponse?.data?.country;
        // setCountry(countryName);


        // const countryResponse = await axios.get(`http://ip-api.com/json/${ipAddress}`);
        // const countryName=countryResponse?.data?.country;
        // setCountry(countryName);
        // step 2: 
        // Get Currency Code Using Country Name;
        // Step 2: Fetch currency information using Restcountries API
        const restCountriesResponse = await axios.get(`https://restcountries.com/v3.1/name/${countryName}`);
        const countryData = restCountriesResponse.data[1].currencies;
        const currencyCode = Object.keys(countryData)[0];
        setCurrencyCode(currencyCode);
        const currencySymbol = countryData?.INR?.symbol;
        const countryFlag = restCountriesResponse.data[1].flag;
        // step 3:
        // Convert currency
        const amountInUSD = 100; // Example amount in Doll
        const conversionResponse = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`);
        // console.log('conversionResponse',conversionResponse);
        const rates = conversionResponse.data.rates;
        const convertedAmount = amountInUSD * rates[currencyCode];
        setConvertedAmount(rates[currencyCode]);
        dispatch(addToExcRate({ rate: rates[currencyCode],currencyCode:currencyCode,currencySymbol:currencySymbol}));
      } catch (err) {
        setError('Error fetching data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCountryAndConvertCurrency();
  }, []);
  const dispatch = useDispatch();
//   useEffect(()=>{
//     dispatch(addToExcRate({ rate: convertedAmount,currencyCode:currencyCodey}));
//   },[]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div>
//       <p>Country: {country}</p>
//       <p>Converted Amount: {convertedAmount} {currencyCodey}</p>
//     </div>
//   );
};

export default ExchangeRate;
