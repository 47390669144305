// checkout / Payment.js
import React, { useEffect, useState } from 'react';
import { Popover, Steps, Row, Col, Collapse, Tabs } from 'antd';
import DebitCrd from '../../assets/images/debitCrd.png';
import UpiImg from '../../assets/images/upi.png';
import netBanking from '../../assets/images/banks.png';
import cashDeliver from '../../assets/images/cashdeliver.png';
import payPal from '../../assets/images/ccAvenue.png';
import UpiForm from '../PaymentTabs/UpiForm';
import CreditForm from '../PaymentTabs/CreditForm';
import NetBanking from '../PaymentTabs/NetBanking';
import CashDelivery from '../PaymentTabs/CashDelivery';
import PayPalForm from '../PaymentTabs/PayPalForm';
import PriceDetails from "../checkout/PriceDetails"
import { useDispatch, useSelector } from 'react-redux';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import {createOrder} from '../../services/orders/order';
import { toast } from 'react-toastify';
import NoCartimg from '../../assets/images/nocart.png'
import { STRINGS } from '../../constants/Constant';
// import CalculateTotals from '../../utils/cartUtils';
import { deleteCart } from '../../services/cart/addCart';
import { addToCart, clearCart } from '../../reducers/cartSlice';
import CheckoutCartProduct from '../../components/drawer/CheckoutCartProduct';
import CalculateTotals from "../../components/checkout/priceCalculateTotals";
import { applyCoupon } from '../../reducers/couponSlice';
import { clearBuy } from '../../reducers/buySlice';



const customDot = (dot, { status, index }) => (
    <Popover
        content={
            <span>
                step {index} status: {status}
            </span>
        }
    >
        {dot}
    </Popover>
);
//
const Payments = () => {
    const couponSlice = useSelector((state) => state.coupon);
    const couponId = couponSlice?.id || 0;
    // console.log('couponSlice',couponId);
    const user = useSelector((state) => state.user);
    const { total = { totalCount: 0, totalPrice: 0 }, savingAmnt = 0, disAmount = 0, newGrandTotal=0,shippingFees=0,newShippingCharges=0, cartLength,filteredCartData,rewardSlice=0, firstOrderCouponDiscount = 0} = CalculateTotals();
    // const [filteredCartData ,setFilteredCartData ]=useState([]);
    const cart = useSelector((state) => state.cart);
    const cartCount = cart.reduce((total, item) => total + item.count, 0);
    
   
 
    // const { state: { from } = {} } = useLocation();
    
    const from = JSON.parse(sessionStorage.getItem('selectedAddress'));
    let firstOrderCouponDetail = JSON.parse(localStorage.getItem('FirstOrderCouponDetails'));
    // from=from||selectedAddress||{}
    // console.log('from',from);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Destructuring with default values
    const { id = '', address = '',gst_number='',land_mark='', mobile_number = '', city = {}, zip_code = '', name = '', state = {}, country = {} } = from;

    // Using optional chaining to handle potential undefined values
    const city_name = city?.city_name || '';
    const state_name = state?.state_name || '';
    const country_name = country?.country_name || '';
    let buy = useSelector((state) => state.buy)||[];
    const newProducts =  buy.map(product => ({
        product_id: product.id,
        product_variant_id:product?.p_variant_id,
        quantity:product?.count,
        amount:product?.price,
    }));
    

    const textDeliver = (
        <div className='deliver_spn'>
            <span>{land_mark !== "undefined" && land_mark !== "" ? `${land_mark},`: "" } {address}, {city_name}, {state_name} {zip_code}</span>
            <p>{mobile_number}</p>
        </div>
    );

    const text_bag = (

        <div className='drawer_cupns_space'>
            {/* DrawerCartProduct */}
            {
                cartLength === 0 ? (
                    <div className='noDataCont'>
                        <img src={NoCartimg} alt='' />
                        <h5> No Product In cart.</h5>
                        <Link to={Routes.Home}>Continue Shopping</Link>
                    </div>
                ) : (
                    filteredCartData?.map((product, index) => (
                        
                        <CheckoutCartProduct
                            key={index}
                            product={product}
                        />
                    ))
                )}
        </div>
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (cartLength === 0) {
                navigate(Routes.Home);
            }
        }, 100);
    
        // Cleanup function to clear the timeout when the component unmounts or when `cartLength` changes
        return () => clearTimeout(timeoutId);
    }, [cartLength]);
    const handleCodData = async () => 
    {
        try {

            // Product details
            // Create order in DB
            const orderFormData = new URLSearchParams();
            orderFormData.append('transaction_id', 0);
            orderFormData.append('total_amount', total.totalPrice);
            orderFormData.append('discount_amt', disAmount);
            orderFormData.append('shipping_amt', newShippingCharges);
            orderFormData.append('tax_amt', 0);
            orderFormData.append('paid_amount', newGrandTotal);
            orderFormData.append('payment_type', 2);
            orderFormData.append('currency', 'INR');
            orderFormData.append('coupon_id', couponId);
            orderFormData.append('address_id', id);
            orderFormData.append('reward_point', rewardSlice>0?rewardSlice*STRINGS.REWARD_CAL:0);
            orderFormData.append('reward_amt',rewardSlice);
            orderFormData.append('buy_now',JSON.stringify(newProducts));
            orderFormData.append('first_order_discount_id',firstOrderCouponDetail?.data?.id ? firstOrderCouponDetail?.data?.id : "");
            orderFormData.append('first_order_discount_amt',firstOrderCouponDiscount?firstOrderCouponDiscount:'');

            const dbResponse = await createOrder(orderFormData, user.token);
            // console.log('dbResponsecreateOrder', dbResponse);

            if (dbResponse.status === "1") {
                const buyIds = buy?.map((item) => item.id);
                // console.log('buyIdsbuyIdsbuyIdsbuyIds',buyIds);
                if(buyIds.length>0){
                    // dispatch(clearBuy());
                    // localStorage.removeItem('buy');
                }else{
                    try {
                        const deleteOperations = filteredCartData.map(async (product) => {
                            const formData = new URLSearchParams();
                            formData.append('id', product.id);
                            const responseDel = await deleteCart(formData);
                            //   deleteCart
                            const deleteCartStatus = responseDel.data.status;
                            if (deleteCartStatus === "1") {
                                return product.id; // Return the ID of the successfully deleted product
                            }
                        });
                        const deletedProductIds = await Promise.all(deleteOperations);
                        // Update local storage and state after all delete operations are completed
                        const updatedCart = cart.filter((item) => !deletedProductIds.includes(item.id));
                        if (updatedCart.length > 0) {
                            localStorage.setItem('cart', JSON.stringify(updatedCart));
                            dispatch(addToCart(updatedCart));
                            const couponnull = {};
                            dispatch(applyCoupon(couponnull));
                            localStorage.setItem('coupon', JSON.stringify(couponnull));
                            //   addToCart
                        } else {
                            localStorage.removeItem("cart");
                            dispatch(clearCart());
                            const couponnull = {};
                            dispatch(applyCoupon(couponnull));
                            localStorage.setItem('coupon', JSON.stringify(couponnull));
                            //   clearCart
                        }
                    } catch (error) {
                        console.error("Deleting Error:", error);
                    }

                }
                navigate(Routes.ThankuCheck);
               
            } else {
                toast.error(STRINGS.PLEASE_TRY_AGAIN_LATER);
            }
        } catch (error) {
            console.error('Error handling COD data:', error);
            toast.error(STRINGS.PLEASE_TRY_AGAIN_LATER);
        }
    };



    const [activeTab, setActiveTab] = useState('cashDelivery');
    const renderTabContent = () => {
        switch (activeTab) {
            case 'debitCrd':
                return <CreditForm />;
            case 'upiOnline':
                return <UpiForm />;
            case 'netBanking':
                return <NetBanking />;
            case 'cashDelivery':
                return <CashDelivery handleCodData={handleCodData} />;
            case 'payPal':
                return <PayPalForm from={from} CalculateTotals={CalculateTotals} couponId={couponId} />;
            default:
                return null;
        }
    };

    const onChange = (key) => {
        // console.log(key);
    };

    const genExtra = () => (
        <div className='extra_title'>
            {total.totalCount} Items
        </div>
    );
    const deliverExtra = () => (
        <div className='extra_title_sec'>
            {name}
        </div>
    );

    const items = [
        {
            key: '1',
            label: 'Bag',
            children: <div>{text_bag}</div>,
            extra: genExtra(),
        },
        {
            key: '2',
            label: 'Deliver to',
            children: <div>{textDeliver}</div>,
            extra: deliverExtra(),
        },
        {
            key: '3',
            label: 'Total Price',
            children: <div className='prize_detail'>{<PriceDetails />}</div>,
            // extra:  <div className='extra_title_sec'>₹{totalAmount.totalPrice}</div>,
        },
    ];

    return (
        <>
            <div className='payment_main'>
                <div className='payment_steper'>
                    <Steps
                        current={1}
                        progressDot={customDot}
                        items={[
                            {
                                title: 'Shipping',
                            },
                            {
                                title: 'Payment',
                            },
                            {
                                title: 'Confirmation',
                            },
                        ]}
                    />
                </div>
                <div className='payment_heading'>
                    {/* <span>Choose Payment Method</span>
                    <p>Choose the payment method you prefer</p> */}
                </div>
                <div className='payment_card_itemInfo'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <div className='payment_mode_card'>
                                <div className='account_masterTabs'>
                                    <Tabs tabPosition="left"
                                        activeKey={activeTab}
                                        onChange={setActiveTab}>
                                        {/* <items tab={
                                            <div className='tab_flex_img'>
                                                <img src={DebitCrd} alt="#" />
                                                <div className='credit_debit_para'>
                                                    <span>Credit/Debit Card</span>
                                                    <p>Visa, Mastercard, Rupay & More</p>
                                                </div>
                                            </div>
                                        } key="debitCrd" />
                                        <items tab={
                                            <div className='tab_flex_img'>
                                                <img src={UpiImg} alt="#" />
                                                <div className='credit_debit_para'>
                                                    <span>UPI</span>
                                                    <p>Google Pay, Phone Pe, Paytm & more</p>
                                                </div>
                                            </div>
                                        } key="upiOnline" />
                                        <items tab={
                                            <div className='tab_flex_img'>
                                                <img src={netBanking} alt="#" />
                                                <div className='credit_debit_para'>
                                                    <span>NetBanking</span>
                                                    <p>Pay through your favourite bank</p>
                                                </div>
                                            </div>
                                        } key="netBanking" /> */}

                                        <items tab={
                                            <div className='tab_flex_img'>
                                                <img src={cashDeliver} alt="#" />
                                                <div className='credit_debit_para'>
                                                    <span>Cash on Delivery</span>
                                                    <p>Pay at the time of delivery</p>
                                                </div>
                                            </div>
                                        } key="cashDelivery" />

                                        <items tab={
                                            <div className='tab_flex_img'>
                                                <img src={payPal} alt="#" width={25} />
                                                <div className='credit_debit_para'>
                                                    <span>Pay CC Avenue</span>
                                                    <p>Pay through your CC Avenue Account</p>
                                                </div>
                                            </div>
                                        } key="payPal" />
                                    </Tabs>
                                </div>
                                <div className="paymentContent_wrapper">{renderTabContent()}</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8}>
                            <div className='payment_items_accordian'>
                                <Collapse
                                    onChange={onChange}
                                    items={items}
                                />
                            </div>
                            <div className='paymnet_bag_info'>
                                <span>You are saving ₹{savingAmnt.toFixed(2)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='payment_footer'>
                {/* <Link to={Routes.Terms} state={{ from: "#returnCancel" }}><span>Refund policy  </span></Link>
                <div className='profile_dashed'>|</div> */}
                <Link to={Routes.Privacy}><span>Privacy policy </span></Link>
                <div className='profile_dashed'>|</div>
                <Link to={Routes.TermCondition}><span>Terms & Conditions</span></Link>
            </div>
        </>
    )
}

export default Payments