// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const getProductRelated = async (data) => {
  try {
    const response = await api.post('/get-related-product', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};


// Fetch Atttribute Types List
export const getAttributeTypeList = async (slug,data) => {
  try {
    const response = await api.get('get-attribute-type-list');
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const getPrimaryVarientAttribute = async (data) => {
  try {
    const response = await api.post('/get-primary-variant-attribute', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getVarientWiseAttribute = async (data) => {
  try {
    const response = await api.post('/get-variant-wise-attribute', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const notifyProduct =async (data,token)=>{
  try{
    const response = await api.post('add-product-notify', data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const bestSelletProductWise =async (data)=>{
  try{
    const response = await api.post('get-best-sell-product-wise', data, {
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const addOnProduct =async (data)=>{
  try{
    const response = await api.post('get-related-product', data, {
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

