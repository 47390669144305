import { createSlice } from '@reduxjs/toolkit';

// Define the initial state with only the description field
const initialState = {
  description: '',
};

const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    addToSeo: (state, action) => {
      state.description = action.payload;
    },
  },
});

export const { addToSeo } = seoSlice.actions;

export default seoSlice.reducer;
