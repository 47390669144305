import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getAllBlog } from '../../services/blog'
import { Row, Col, Dropdown, Space, Typography, Pagination } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import blogimg1 from "../../assets/images/blogs_category/blogsimg1.png";
import blogimg2 from "../../assets/images/blogs_category/blogsimg2.png";
import blogimg3 from "../../assets/images/blogs_category/blogsimg3.png";
import blogimg4 from "../../assets/images/blogs_category/blogsimg4.png";
import blogimg5 from "../../assets/images/blogs_category/blogsimg5.png";
import blogimg6 from "../../assets/images/blogs_category/blogsimg6.png";
import blogimg7 from "../../assets/images/blogs_category/blogsimg7.png";
import blogimg8 from "../../assets/images/blogs_category/blogsimg8.png";
import blogimg9 from "../../assets/images/blogs_category/blogsimg9.png";
import fliterImg from '../../assets/images/blogs_category/Layer_1.png';
import Routes from '../../Routes/Routes';

const items = [
    {
        key: '1',
        label: 'Item 1',
    },
    {
        key: '2',
        label: 'Item 2',
    },
    {
        key: '3',
        label: 'Item 3',
    },
];

function BlogsCategory() {
    const hasMounted = useRef(false);
    const [blogs, setBlog] = useState(null);
    const fetchData = async () => {
        try {
            const blogResponse = await getAllBlog();
            const blogData = blogResponse;
            if (blogResponse.status === '1') {
                setBlog(blogData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };
    useEffect(() => {
        if (hasMounted.current) return;
        fetchData();
        hasMounted.current = true;
    }, []);

    const showBlogs = () => {

        return blogs?.data?.map((item, index) => {
            return <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <div className='img_detail_one'>
                    <Link to={`${Routes.BlogDetails}/${item.slug}`}>
                        <img src={`${blogs.path + item.image}`} alt="not find" />
                        <h5>{item.title}</h5>
                    </Link>
                    {/* <p dangerouslySetInnerHTML={{ __html: item.description }} /> */}
                </div>
            </Col>
        })
    }

    return (
        <>
            <div className='sec_blogcategory_one_heading'>
                <h1>The Kairaus Journal</h1>
            </div>

            <div className='blog_img_details_main'>
                {/* <div className='blog_dropdown'>
                    <Dropdown
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: ['3'],
                        }}
                    >
                        <Typography.Link>
                            <Space>
                                <img src={fliterImg} />
                                Filter
                                <DownOutlined />
                            </Space>
                        </Typography.Link>
                    </Dropdown>
                </div> */}
                <Row gutter={[16, 48]}>
                    {showBlogs()}
                </Row>
                <div className='blog_pagination'>
                    <Pagination defaultCurrent={1} total={10} />
                </div>
            </div>
        </>
    )
}

export default BlogsCategory