import React from 'react';

function Exchanges() {

    return (
        <>
            <div className='shipping_container'>
                <h1>Return & Exchange Policy</h1>

                <div className='shipping_content'>
                    <p>Shipping Charges</p>
                    <span>There will be a 200 Indian Rupee shipping fee for orders totaling less than 10,000. However, shipping will be given at no additional cost to you if the total value of your order reaches 10,000 Indian Rupees. Also, on the basis of your provided pincode the shipping charges may vary.</span>

                    <p>Shipping policy</p>
                    <span>The package will be attempted to be delivered three times by our logistics partners before being returned to us. Kindly include the shipping address in its whole, accurately mentioning the zip code and a mobile number. This will guarantee an improved and quicker delivery.</span>

                    <p>Shipping location</p>
                    <span>We distribute our goods all over India. Delivery schedules are contingent upon the recipient's location, distance, and logistical partners. We disclose how many days it will take to deliver. Any delivery delays caused by the courier service are not our responsibility. Additionally, we In order to ensure that the products reach our customers as fast as possible, we also provide the best possible support.</span>

                </div>
            </div>
        </>
    )
}

export default Exchanges