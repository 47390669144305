import { createSlice } from '@reduxjs/toolkit';

const storedCoupon = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('shippingFees')) || null : null;
// console.log('storedcoupon', storedCoupon);

const shippingSlice = createSlice({
  name: 'shippingFees',
  initialState: storedCoupon,  // You can define an initial state if needed
  reducers: {
    applyShipping: (state, action) => action.payload,
  },
//   clearShipping: (state, action) => null,
});

export const { applyShipping } = shippingSlice.actions;

export default shippingSlice.reducer;
