import React from 'react';
import ErrorImg from '../../assets/images/error-404.png';
import { useNavigate } from 'react-router-dom';
import NewArrival from '../../components/home/NewArrival';

function Error() {
    const navigate = useNavigate()
    const goBackHandler = () => {
        navigate("/") // Go back one step in history // Navigate back to the previous page using window.history
    };

    return (
        <>
            <div className='error_pageCont'>
                <div className='erorr_positon'>
                    <h1>"It appears we've hit a snag. Rest assured, we're addressing it and will be back on track soon!"</h1>
                    <img src={ErrorImg} alt='' />
                    <p>Error</p>
                </div>
                <button onClick={goBackHandler}>Continue Shopping</button>
            </div>
            <NewArrival />
        </>
    )
}

export default Error;
