import React, { useCallback, useEffect, useState } from 'react';
import confirmImg from '../../assets/images/1st.png';
import confirmImg1 from '../../assets/images/3rd.png';
import { TruckOutlined, HeartOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Checkbox, Input, Form } from 'antd';
import CalculateTotals from '../../components/checkout/priceCalculateTotals';
import { useSelector } from 'react-redux';
import { getCustomerSupport } from '../../services/assurance';
import { updateCartStatus } from '../../services/cart/addCart';
import { Link, useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import { toast } from 'react-toastify';
import GstInfo from '../../components/GstInfo';
import ProductPrice from '../../components/ProductPrice';


function Confirmation() {
    const { total = { totalComparePrice: 0, totalCount: 0, totalPrice: 0 }, disAmount = 0, grandTotal = 0, newGrandTotal = 0, shippingCharge = 0, newShippingCharges = 0, bagDiscount, rewardAmnt, firstOrderCouponDiscount = 0, filteredCartData } = CalculateTotals();
    const from = JSON.parse(sessionStorage.getItem('selectedAddress'));
    const { id = '', address = '', gst_number = '', land_mark = '', mobile_number = '', city = {}, zip_code = '', name = '', state = {}, country = {}, billing_name = '', billing_phone = '', billing_address = '', billing_city = {}, billing_country = {}, billing_state = {}, billing_pincode = '', is_billing_address = '' } = from;
    // console.log(from, "from")
    const city_name = city?.city_name || '';
    const state_name = state?.state_name || '';
    const country_name = country?.country_name || '';
    const billingCity = billing_city?.city_name || '';
    const billingCountry = billing_country?.country_name || ''
    const billingState = billing_state?.state_name || ''

    let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
    firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);

    const cart = useSelector((state) => state.cart);
    const buy = useSelector(state => state.buy);
    const buyData = buy ? buy : [];
    const localCartIds = JSON.parse(localStorage.getItem('localCartIds'));
    const user = useSelector((state) => state.user);
    const [showBillingForm, setShowBillingForm] = useState(false);
    const navigate = useNavigate();

    const comp = useSelector(state => state.shippingFees);
    const [supportData, setSupportData] = useState({})
    const getLocalData = localStorage.getItem("bufferdays")

    const handleSupport = async () => {
        try {
            let result = await getCustomerSupport();
            setSupportData(result?.data);
        }
        catch (e) {
            console.log('error', e);
        }
    }

    useEffect(() => {
        handleSupport()
    }, [])

    const estimatedDays = parseInt(getLocalData) + supportData?.product_buffer_days
    const Dayjs = require('dayjs');
    const dateFormat = 'MMM DD, YYYY';
    const originalDate = Dayjs(comp, dateFormat);
    const newDate = originalDate.add(estimatedDays, 'day');
    const newDateString = newDate.format(dateFormat);

    const handleAddCart = useCallback(async () => {
        try {
            const newUpdateCart = buyData?.length > 0 ? buyData : cart
            const products = newUpdateCart.map(product => ({
                product_id: product.id,
                status: localCartIds?.length > 0 ? (localCartIds?.includes(product.id) ? 1 : 0) : 1,
            }));
            const responseAddCartData = await updateCartStatus(products, user.token)
            if (responseAddCartData.status === '1') {
                navigate(Routes.Payment);
            } else {
                toast.error('Please try again');
            }
        } catch (error) {
            console.error('API Request Error:', error);
        }
    }, [cart, localCartIds, user.token, navigate]);

    const [showGSTForm, setShowGSTForm] = useState(false);

    const handleCheckboxChange = (e) => {
        setShowGSTForm(e.target.checked);
    };
    const excRate = useSelector(state => state.excrate);
    const isINR = excRate?.currencyCode === "INR";

    return (
        <div className='confirmation_section'>
            <h1>Confirmation for Your Product detail</h1>
            <div className='confirmation_orders'>
                <h3>Your Products</h3>
                <div className='confirmation_product'>
                    {filteredCartData?.map(item => (
                        <div className='confirmation_img'>
                            <Link to={`${Routes?.ProductDetail}/${item.product_slug}`} state={{ProductId:item?.id}}>
                            <img src={item?.image} alt="#" />
                            </Link>
                            <div className='confirm_produtDetail'>
                                <div className='confirm_wishlist'>
                                <Link to={`${Routes?.ProductDetail}/${item.product_slug}`} state={{ProductId:item?.id}}>
                                    <p>{item?.product_name}</p>
                                    </Link>
                                    {/* <div className='wishlist_icon'>
                                     <Tooltip title="Wishlist">
                                         <HeartOutlined />
                                     </Tooltip>
                                     <Tooltip title="Delete Item">
                                         <CloseOutlined />
                                     </Tooltip>
                                 </div> */}
                                </div>
                                {/* <div className='confirm_chips'>
                                 <p>Set of 2</p>
                                 <p>Blue</p>
                             </div> */}
                                <div className='confirm_price'>
                                    <h5 className='pinCode_delivery'><TruckOutlined />Estimated delivery: {newDateString !== "Invalid Date" ? newDateString : comp}</h5>
                                    <div className='confirmPriceTag'>
                                        <h6>({item?.count}*{isINR?item?.price:item.usd_price * (excRate?.rate || 1)})</h6>
                                        <ProductPrice product={item}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <Row gutter={12}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className='confirmation_address'>
                            <h3>Your Address</h3>
                            <div className='shipping_address'>
                                <h4>Shipping Address</h4>
                                <div className='shipping_deatils'>
                                    <span>{name}</span>
                                    <span>+91-{mobile_number}</span>
                                    <span>{address}</span>
                                    {land_mark !== "" && land_mark !== "undefined" &&
                                        <span>{land_mark}</span>}
                                    <span>{city_name}, {state_name}, {country_name}</span>
                                    <span>{zip_code}</span>
                                </div>
                            </div>
                            {is_billing_address == '1' &&
                                <div className='shipping_address'>
                                    <h4>Billing Address</h4>
                                    <div className='shipping_deatils'>
                                        <span>{billing_name}</span>
                                        <span>+91-{billing_phone}</span>
                                        <span>{billing_address}</span>
                                        {/* <span>Near AU Bank, Malviya nagar </span> */}
                                        <span>{billingCity}, {billingState}, {billingCountry}</span>
                                        <span>{billing_pincode}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className='confirmation_billing'>
                            <h3>Your Bill</h3>
                            <div className='billing_details'>
                                <div className='bid_mrp'>
                                    <p>Big MRP {`(${total.totalCount} items)`} </p>
                                    <span>₹{total.totalComparePrice.toFixed(2)}</span>
                                </div>
                                <div className='bid_mrp'>
                                    <p>Kairaus Savings </p>
                                    <span>{bagDiscount ? `-₹${bagDiscount.toFixed(2)}` : '0'}</span>
                                </div>
                                {disAmount > 0 &&
                                    <div className='bid_mrp'>
                                        <p>Coupons Discount </p>
                                        <span>-₹{disAmount.toFixed(2)}</span>
                                    </div>}
                                {
                                    rewardAmnt > 0 && (
                                        <div className='bid_mrp'>
                                            <p>Reward Points Discount</p>
                                            <span>-₹{rewardAmnt}</span>
                                        </div>
                                    )

                                }
                                {firstOrderCouponDetail?.order_count == 0 &&
                                    <div className='bid_mrp'>
                                        <p >First Order Discount ({firstOrderCouponDetail?.data?.type_val}{firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type}) </p>
                                        <span >-₹{firstOrderCouponDiscount.toFixed(2)}</span>
                                    </div>
                                }
                                {
                                    newShippingCharges > 0 ? <div className='bid_mrp'>
                                        <p>Shipping Charges</p>
                                        <span>+ ₹{newShippingCharges?.toFixed(2)}</span>
                                    </div> :
                                        <div className='bid_mrp'>
                                            <p>Shipping Charges</p>
                                            <span>Free</span>
                                        </div>
                                }
                                <div className='bid_total'>
                                    <p>Total </p>
                                    <span>₹{newGrandTotal.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='confirmation_billing'>
                            <div className='payment_crd'>
                                <h3>Mode of payment</h3>
                                <span>Online</span>
                            </div>
                        </div> */}

                        {/* GST Detail */}
                        <GstInfo showGSTForm={showGSTForm} setShowGSTForm={setShowGSTForm}/>

                        {/* <div className='gst_cartCont'>
                            <Checkbox onChange={handleCheckboxChange}>Do you want to give your GST information?</Checkbox>
                            {showGSTForm && (
                                <div className='gst_form'>
                                    <Col span={24}>
                                        <Form.Item
                                            name="gstnumber"
                                            label="GST Number"
                                            rules={[
                                                { required: true, message: 'Please enter your GST Number' },
                                                {
                                                    pattern: /^[0-9A-Za-z]{15}$/,
                                                    message: 'Please enter a valid 15-digit GST number',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter GST Number" maxLength={15} type='text' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="companyname"
                                            label="Company Name"
                                            rules={[
                                                { required: true, message: 'Please enter your Company name' },
                                                { type: 'string', message: 'Please enter a valid company name' },
                                            ]}
                                        >
                                            <Input placeholder="Enter your Company name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="gstaddress"
                                            label="Address"
                                            rules={[{ required: true, message: 'Please enter your Address' }]}
                                        >
                                            <Input placeholder="Enter Address" />
                                        </Form.Item>
                                    </Col>
                                </div>
                            )}
                        </div> */}
                    </Col>
                </Row>
            </div>
            <div className={showGSTForm ? 'disablepayBtn':'confirm_pay'}>
                <button disabled={showGSTForm} onClick={() => handleAddCart()}>Pay Now</button>
            </div>

        </div>
    )
}

export default Confirmation