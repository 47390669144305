// components/ContactUs.js
import React from 'react';
import { submitContactForm } from '../../services/contactUs';
import { toast } from 'react-toastify';
import { STRINGS } from '../../constants/Constant';

const ContactUs = ({ input, button }) => {
  const [email, setEmail] = React.useState('');
  const [emailErrorShown, setEmailErrorShown] = React.useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setEmailErrorShown(false)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      if (!emailErrorShown) {
        toast.error('Please enter a valid email.');
        setEmailErrorShown(true);
      }
      return;
    }
    try {
      const formData = new URLSearchParams();
      formData.append('email', email);
      const response = await submitContactForm(formData);
      // console.log(response.status, 'response.data.statusresponse.data.status');
      if (response.status === '1') {
        toast.success(STRINGS.SUBSCRIBE_US);
        setEmail('');
      } else {
        toast.error('Something Went Wrong');
      }
    } catch (error) {
      toast.error('Failed to update record. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {React.cloneElement(input, { value: email, onChange: handleInputChange })}
      {React.cloneElement(button)}
    </form>
  );
};

export default ContactUs;
