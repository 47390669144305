import React, { useEffect, useState } from 'react'
import { Input, Form, Row, Col, Select, Button, Modal, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../services/auth/authApi';
import { toast } from 'react-toastify';
import Routes from '../../Routes/Routes';
import { addCart } from '../../services/cart/addCart';
import { clearUser } from '../../reducers/userSlice';
import { clearCart } from '../../reducers/cartSlice';


function ChangePassword({ setUserName }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const user = useSelector(state => state.user);

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            const newPassword = getFieldValue('new_password');
            if (!newPassword || value === newPassword) {
                return Promise.resolve();
            }
            return Promise.reject('Passwords do not match');
        },
    });

    const handleSubmit = async (values) => {
        try {
            const formData = new URLSearchParams();
            formData.append('old_password', values?.old_password);
            formData.append('new_password', values?.new_password);
            formData.append('confirm_password', values?.confirm_password);

            const response = await authApi.changePassword(formData, user.token);
            // console.log('response====39==>>', response);
            if (response?.status == "1") {
                toast.success(response?.message)
                // window.location.reload();

                // Add cart data to DB
                if (cart.length > 0) {
                    const products = cart?.map(product => ({
                        product_id: product.id,
                        product_variant_id: product.p_variant_id,
                        quantity: product.count,
                        amount: product.price,
                    }));
                    await addCart(products, user.token);
                    // console.log('responseAddCartData=at=logout=165==>>>',responseAddCartData);
                }

                localStorage.removeItem("user");
                localStorage.removeItem("cart");
                dispatch(clearUser());
                dispatch(clearCart());
                localStorage.setItem("logoutEvent", Date.now().toString());
                navigate(Routes.SignIn);
            }
            else if (response.status === undefined) {
                // console.error(response,"response")

                // toast.error('old p not m')
                if (response?.response?.data?.status === '0') {
                    toast.error("New password must not same as old password")
                }
            }

        } catch (error) {

            console.error('Error fetching address data:', error);
        }
    }

    return (
        <>
            <div className='profile_form'>
                <h1>Change Password</h1>
                <div className='profile_tabs_form'>
                    <Form name="control-hooks" layout="vertical" autoComplete="off" onFinish={handleSubmit}>
                        <Row gutter={18}>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item name="old_password" rules={[{ required: true, message: "Old password is required" }]} hasFeedback>
                                    <Input.Password className='input_signin' placeholder="Old Password" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item name="new_password" rules={[{ required: true, message: "New password is required" }, {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+=])[A-Za-z\d!@#$%^&*()-+=]{8,16}$/,
                                    message: 'Password must be 8-16 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
                                },
                                ]}
                                    hasFeedback>
                                    <Input.Password className='input_signin' placeholder="New Password" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item name="confirm_password" dependencies={['new_password']}
                                    rules={[{ required: true, message: "Confirm password is required" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('new_password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The confirm password that you entered do not match with new password!'));
                                        },
                                    }),
                                    ]}
                                    hasFeedback>
                                    <Input.Password className='input_signin' placeholder="Confirm Password" />
                                </Form.Item>
                            </Col>
                            <div className='disablepayBtn'>
                                <button>Save Details</button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default ChangePassword