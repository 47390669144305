import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import { getBestSellerList, getFinestProductList, getNewArrivalsList, getSavingProductList } from "../../services/home";
import dImg from '../../assets/images/d.png';
import img1 from '../../assets/images/drinkwarecup.png';
import img2 from '../../assets/images/drinkwarecup1.png';
import img3 from '../../assets/images/drinkwarecup2.png'
import img4 from '../../assets/images/drinkwarecup3.png';
import hoverImage from '../../assets/images/dining01.png';
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";

import { useSelector } from "react-redux";

import AddToCart from "../../function/AddToCart";
import LoaderLottie from '../../assets/images/loader.json'
import Lottie from 'react-lottie';
import ProductPrice from "../ProductPrice";


// Extract arrow component
const SliderArrow = ({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
);

const productItems = [
  {
    id: 1,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img2
  },
  {
    id: 2,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img3
  },
  {
    id: 3,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img4
  },
  {
    id: 4,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img1
  },
  {
    id: 5,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img3
  },

]

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Extract loading spinner component
const LoadingSpinner = () => <Lottie options={defaultOptions}
  height={150}
  width={200}
/>;



const OfferItem = () => {

  const user = useSelector((state) => state.user);
  const [sellerList, setSellerList] = useState(null);

  const userId = user?.id ? user.id : 0;

  const hasMounted = useRef(false);


  const settings = {
    dots: false,
    infinite: sellerList?.data?.length>4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // defines screen size below which the settings will change
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [imgPath, setimgPath] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('type', '1');
      const response = await getSavingProductList(formData, user?.token);
      // console.log('getNewArrivalsList',response);
      setSellerList(response);
      setimgPath(response.path);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  //   const handleAddToCart = (p_id) => {
  //     alert('in handleAddToCart newArrival');
  //     let productDetail = arrivalList?.data?.find(item => item.id === p_id);
  //     let productCount = 1
  //     const { id, product_name, price,product_slug,is_wishlist} = productDetail;
  //     const image = `${imgPath}${productDetail?.productimages[0]?.file_name}`;
  //     const newItem = { id, product_name,product_slug, price,is_wishlist, image, count: productCount, p_variant_id: 0};
  //     const existingItemIndex = cart.findIndex(item => item.product_name === newItem.product_name);

  //     if (existingItemIndex === -1) {
  //         if (Object.isExtensible(cart)) {
  //             cart.push(newItem);
  //         } else {
  //             cart = [...cart, newItem];
  //         }
  //     } else {
  //         const updatedCart = [...cart];
  //         updatedCart[existingItemIndex] = {
  //             ...updatedCart[existingItemIndex],
  //             count: updatedCart[existingItemIndex].count + productCount,
  //         };
  //         cart = updatedCart;
  //     }
  //     // Update localStorage and dispatch to Redux
  //     localStorage.setItem('cart', JSON.stringify(cart));
  //     dispatch(addToCart(cart));
  //     toast.success(STRINGS.ADDED_TO_CART_SUCCESSFULLY);
  //     // setOpenDrawer(true);
  // };

  useEffect(() => {
    if (!hasMounted.current) {
      fetchData();
      hasMounted.current = true;
    }
  }, []);

  const renderArrows = () => (
    <div className="slider-arrow">
      <SliderArrow direction="prev" onClick={() => sliderRef.current?.slickPrev()} />
      <SliderArrow direction="next" onClick={() => sliderRef.current?.slickNext()} />
    </div>
  );

  return (
    sellerList?.data?.length>0&&
    <section className='best_seller_slider'>
      <div className="container-fluid">
        <div className='row'>
          <h3 className='store_headings'>Kairaus’s Savings Bonanza</h3>
          {loading ? (
           <div style={{display:'flex',justifyContent:'center'}}> <LoadingSpinner /> </div>
          ) : (
            <div className='dinner_slider'>
              <Slider {...settings} ref={sliderRef}>
                {sellerList?.data.slice(0,9)?.map((sellerItem) => (

                  <div className='dinner_sliderImg' key={sellerItem?.id}>
                    {sellerItem?.productimages && sellerItem?.productimages.length > 0 ? (
                      <div className="imageContainer">
                        <Link to={`/product/${sellerItem?.product_slug}`} state={{ProductId:sellerItem?.id}} key={sellerItem?.id}>
                          <img src={`${sellerList.path}${sellerItem?.productimages[0]?.file_name}`} alt={sellerItem?.product_name} />
                          <img src={`${sellerList.path}${sellerItem?.productimages[1]?.file_name}`} className='hoverImage' alt="" />
                        </Link>

                        {sellerItem?.stock_quantity <= 0 ?
                        <div className="sold_off_chips">
                          <p>Sold Out</p>
                        </div>:
                        sellerItem?.discount !== 0 &&
                        <div className="off_chips">
                         <p>{sellerItem?.discount}% off</p>
                        </div>}

                        <Wishlist is_wishlist={sellerItem?.is_wishlist} pId={sellerItem?.id} path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} mode='HeartOutlined' />

                        <AddToCart productList={sellerItem} p_id={sellerItem?.id} imgPath={imgPath} />

                      </div>
                    ) : (
                      <img src={dImg} alt="Placeholder" />
                    )}

                    <div className='dinnerSlider_details'>
                      <Link to={`/product/${sellerItem?.product_slug}`} state={{ProductId:sellerItem?.id}} key={sellerItem?.id}> <p>{sellerItem?.product_name} </p></Link>
                      <div className='dinnerSliderSpan'>
                        {/* <span>₹{sellerItem?.price}</span>
                        {sellerItem?.price !== parseFloat(sellerItem?.compare_price) && sellerItem?.compare_price > 0 &&
                            <p>₹{sellerItem?.compare_price}</p>
                        } */}
                        <ProductPrice product={sellerItem}/>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <button className="common_btns" onClick={() => navigate(Routes.BigSaving)}>View More</button>
            </div>
          )}
          {renderArrows()}
        </div>
      </div>
    </section>
  );
};

export default OfferItem;
