import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../reducers/cartSlice';
import "../../assets/css/style/checkout.scss"
import { useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import { Checkbox, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { deleteCart, updateCartCount } from '../../services/cart/addCart';
import { toast } from 'react-toastify';
import { STRINGS } from '../../constants/Constant';
import { applyCoupon } from '../../reducers/couponSlice';
import { HeartOutlined } from '@ant-design/icons';
import { addWishlist } from '../../services/wishlist/addWishlist';
import { addToFavId } from '../../reducers/favIdSlice';
import ProductPrice from '../ProductPrice';

const DrawerCartProduct = ({ product, onClose, handleCardClick, checked, onChange, setSelectAllChecked }) => {
  // console.log('productproductproductproduct', product);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Move the logic here
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const cartIds = useSelector((state) => state.cartId);
  const { token } = useSelector(state => state.user);
  const excRate = useSelector(state => state.excrate);
  // const [isChecked, setIsChecked] = useState(checked);
  const favId = useSelector((state) => state.favId) || [];
  const newUpdatedPrice = product?.newPrice ? product?.newPrice : excRate?.currencyCode === "INR" ? product?.price : product?.usd_price * excRate?.rate;


  const updateCart = (updatedCart) => {
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    // Assuming you have a dispatch function available
    dispatch(addToCart(updatedCart));
    if (updatedCart.length === 0 && updatedCart[0]?.id === undefined) {
      // Assuming applyCoupon dispatches an action that sets the coupon state
      dispatch(applyCoupon({}));
      localStorage.setItem('coupon', JSON.stringify({}));
    }
  };
  const handleIncrement = async (id) => {
    const updatedCart = await Promise.all(cart.map(async (item) => {
      if (item.id === id) {
        if (item.stock_quantity <= item.count) {
          toast.error('Oops, the count has exceeded the available stock', { toastId: "1" });
          return item;
        }
        try {
          const formData = new URLSearchParams();
          formData.append('product_id', id);
          formData.append('quantity', item.count + 1);
          await updateCartCount(formData, user && user.token);
        } catch (error) {
          console.error("Cart Updating error :", error);
        }
        return { ...item, count: item.count + 1 };
      } else {
        return item;
      }
    }));
    // console.log('handleIncrement',id);
    updateCart(updatedCart);
  };

  const handleDecrement = async (id) => {
    const updatedCart = await Promise.all(cart.map(async (item) => {
      if (item.id === id && item.count > 1) {
        try {
          const formData = new URLSearchParams();
          formData.append('product_id', id);
          formData.append('quantity', item.count - 1); // Decrease the quantity by 1
          await updateCartCount(formData, user && user.token);
        } catch (error) {
          console.error("Cart Updating error :", error);
        }
        return { ...item, count: item.count - 1 };
      } else {
        return item;
      }
    }));
    updateCart(updatedCart);
  };


  const handleWishlist = async (product_name, p_id) => {
    const existingItemIndex = favId.findIndex(item => item === p_id);
    const updatedCart = cart.filter(item => item.product_name !== product_name);

    if (existingItemIndex !== -1) {
      // If pId exists in favId, remove it from cart and call deleteItemFromCart if token exists
      const confirmed = window.confirm("This item is already added to your favorites. Are you sure you want to remove it from the cart?");
      if (confirmed) {
        updateCart(updatedCart);
        if (token) {
          deleteItemFromCart(p_id);
        } else {
          toast.success("Already added in favourite");
        }
      }
    } else {
      // If pId doesn't exist in favId, add it to wishlist
      const formData = new URLSearchParams();
      formData.append('product_id', p_id);
      await addWishlist(formData, user?.token);

      const updatedFavId = [...favId, p_id]; // Add p_id to favId
      updateCart(updatedCart);

      if (token) {
        const formData = new URLSearchParams();
        formData.append('id', p_id)
        let deletedResult = await deleteCart(formData);

        if (deletedResult?.data?.status === '1') {
          toast.success('Added in favourite');
        }
      } else {
        toast.success("Already added in favourite");
      }

      dispatch(addToFavId(updatedFavId)); // Dispatch action to update favId state in Redux

      localStorage.setItem('favId', JSON.stringify(updatedFavId)); // Update local storage
    }
  };


  const handleRemove = (product_name, p_id) => {
    const updatedCart = cart?.filter((item) => item.product_name !== product_name);
    const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];
    const updatedProductCouponId = productCouponId?.filter((item) => item.productId !== p_id);
    localStorage.setItem('productCoupon', JSON.stringify(updatedProductCouponId));

    updateCart(updatedCart);
    if (token) {
      deleteItemFromCart(p_id);
    }
    else {
      toast.success(STRINGS.REMOVED_SUCCESSFULLY);
    }
  };

  // For deleting product from the cart & from DB.
  const deleteItemFromCart = async (productId) => {
    const formData = new URLSearchParams();
    formData.append('id', productId)
    let deletedResult = await deleteCart(formData);

    if (deletedResult?.data?.status === '1') {
      toast.success(STRINGS.REMOVED_SUCCESSFULLY);
    }
    else {
      toast.error(STRINGS.NOT_REMOVED_SUCCESSFULLY);
    }
  }

  const handleMoveToDetail = (ProductData) => {
    navigate(`${Routes?.ProductDetail}/${ProductData?.product_slug}`, { state: { ProductId: ProductData?.id } });
    onClose();
  }
  const [isChecked, setIsChecked] = useState(null);
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  useEffect(() => {
    if (cartIds.length === cart.length) {
      setIsChecked(true);
    }
  }, [])
  return (
    <div className={`checkout_bag ${isChecked ? 'checked_card' : ''}`}>
      <div className='cart-products'>
        <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} onClick={() => { handleCardClick(product.id) }} />
        <div className='left_cart' onClick={() => { handleMoveToDetail(product) }}>
          {product.image ? (
            <img src={product.image} alt={product.product_name} />
          ) : (
            'Image Not Found'
          )}
        </div>
        <div className='right_cart'>
          <div className='price_delete'>
            <h2 onClick={() => { handleMoveToDetail(product) }}>{product.product_name}</h2>
            <div className='crossed_heartIcon'>
              <Tooltip title="Move To Favourite" placement="top">
                <HeartOutlined onClick={(e) => { handleWishlist(product.product_name, product.id) }} />
              </Tooltip>
              <Tooltip title="Delete Item" placement="bottom">
                <CloseOutlined onClick={(e) => { handleRemove(product.product_name, product.id) }} alt={product.product_name} />
              </Tooltip>
            </div>
          </div>
          {/* <span>Set of 1</span> */}
          <div className='add_product_price'>
            <div className="button-box">
              <button className='btn-incriment'>
                <span onClick={() => handleDecrement(product.id)}><FaMinus /></span>
                {product.count}
                <span onClick={() => handleIncrement(product.id)}><FaPlus /></span>
              </button>
            </div>


            <div className='cartProduct_price'>
              <h6>({`${product.count} * ${newUpdatedPrice}`})</h6>
              <ProductPrice product={product}/>
            </div>
            {/* <img src={DeleteIcn} onClick={() => handleRemove(product.product_name)} alt={product.product_name} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerCartProduct;
