import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getNewArrivalsList } from '../../services/home';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Typography, Collapse, Checkbox, Radio, Slider } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RightOutlined, HeartOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import '../../assets/css/style/product.scss'
import { productApi } from '../../services/product/productApi';
import { useDispatch, useSelector } from 'react-redux';
import Wishlist from '../../function/Wishlist';
import Routes from '../../Routes/Routes';
import { addToCart } from '../../reducers/cartSlice';
import { toast } from 'react-toastify';
import { categoryApi } from '../../services/category/categoryApi';
import { LiaGreaterThanSolid } from "react-icons/lia";
import { getColor } from '../../services/filter/color';
import DinninhImg from '../../assets/images/soulsip.png';
import hoverImage from '../../assets/images/2nd.png';
import { getFilterProductList } from '../../services/filter/categories';
import { STRINGS } from '../../constants/Constant';
import { Carousel } from 'react-bootstrap';
import { getAllProductSearch } from '../../services/cart/search/autoSearch';
import NoDataimg from '../../assets/images/noData.png'
import AddToCart from '../../function/AddToCart';
import CommFilter from '../../components/CommFilter';


// const { Link: AntdLink } = Typography;
const marks = {
    0: '0',
    5000: {
        style: {
            color: '#000',
        },
        label: <strong>5000</strong>,
    },
};
const AllProduct = () => {
    const [loading, setLoading] = useState(true);
    const [productList, setProductList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [checkedIds, setCheckedIds] = useState([]);
    const [activeSpan, setActiveSpan] = useState(null);
    const [imgPath, setimgPath] = useState(null);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [selectedColor, setSelectedColor] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 0]);
    const [category, setCategory] = useState(null);
    const [categoryImgPath, setCategoryImgPath] = useState(null);
    const [sliderPath, setSliderPath] = useState(null);
    const [price, setPrice] = useState(null);
    const userId = user?.id ? user.id : 0;
    // console.log('userId===>>',userId);
    let cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const location = useLocation()
    let searchValue;
    if (location.state) {
        searchValue = location.state.searchValue;
    }

    console.log(searchValue,'searchValue')

    const fetchProducts = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('keyword', searchValue);
            formData.append('user_id', userId);
            const response = await getAllProductSearch(formData);
            // console.log('searchValue response',response);
            console.log(response, "response")
            if (response.status === '1') {
                setProductList(response);
                setLoading(false);
                // console.log('response.product_path', response.path)
                setimgPath(response?.product_path);
                setPrice(response.max_price);
                localStorage.setItem("maxPrice", response?.max_price);
            }
            else {
                setProductList([])
                setLoading(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const removeFocus = () => {
            document.activeElement.blur();
        };
        removeFocus();

        return () => {
            removeFocus();
        };
    }, [searchValue]);

    useEffect(() => {
        fetchProducts();
    }, [searchValue]);

    const [gridLayout, setGridLayout] = useState('lg'); // Default layout is 'lg' (4 columns)

    const handleGridLayoutChange = (layout) => {
        setGridLayout(layout);
    };

    const renderGridIcons = () => (
        <div className="grid-icons">
            <div className="grid-icon" onClick={() => handleGridLayoutChange('sm')}>
                {/* First SVG (2 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 24H43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('md')}>
                {/* Second SVG (3 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 18H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M5 30H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M17 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M30 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('lg')}>
                {/* Third SVG (4 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="40" height="40" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M24 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M34 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M4 14H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 34H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 24H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );

    const getColSpan = () => {
        switch (gridLayout) {
            case 'sm':
                return { xs: 12, sm: 12 };
            case 'md':
                return { xs: 12, sm: 12, md: 8 };
            case 'lg':
                return { xs: 12, sm: 12, md: 8, lg: 6 };
            default:
                return { xs: 12, sm: 12, md: 8, lg: 6 };
        }
    };

    return (
        <>
            <div className='prodcut_arrivalNew'>
                <div className="drinkware_top_img_sec">
                    <Carousel>
                        <Carousel.Item>
                            <img src={DinninhImg} alt=''/>
                            <Carousel.Caption>
                                <h2>All Products</h2>
                                <p>Explore a Tapestry of Styles at Kairaus: Unveiling Collections that Define Your Space</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className='path_drinkware'>
                    Home <LiaGreaterThanSolid /> <p>{searchValue}</p>
                </div>
                <div className='drinkware_acc_carousel_section'>
                    <CommFilter setProductList={setProductList} searchValue={searchValue} setimgPath={setimgPath} fetchProducts={fetchProducts} setCategoryImgPath={setCategoryImgPath} setSliderPath={setSliderPath} setCategory={setCategory} price={price} />
                    <div className='right-image-glry'>
                        <div className='dinner_slider'>
                            {renderGridIcons()}
                            <Row gutter={[32, 32]}>
                                {
                                    productList?.data?.length != 0 ?
                                        productList?.data?.map(product => (
                                            <Col {...getColSpan()} key={product?.id}>
                                                <div className='dinner_sliderImg'>
                                                    <div className='dinnerCategory_cont'>
                                                        <Link to={`/product/${product?.product_slug}`} key={product?.id}>
                                                            <div className='imageContainer '>
                                                                <img src={`${imgPath}${product?.productimages[0]?.file_name}`} />
                                                                <img src={!product?.productimages[1] ? hoverImage : `${imgPath}${product?.productimages[0]?.file_name}`} className='hoverImage' />
                                                            </div>
                                                        </Link>
                                                        {product?.stock_quantity <= 0 ?
                                                            <div className="sold_off_chips">
                                                                <p>Sold Out</p>
                                                            </div>
                                                            :
                                                            product?.discount !== 0 &&
                                                            <div className="off_chips">
                                                                <p>{product?.discount}% off</p>
                                                            </div>}
                                                        <Wishlist is_wishlist={product?.is_wishlist} pId={product?.id} path={`${Routes.ProductDetail}/${product?.product_slug}`} mode='HeartOutlined' />
                                                        {/* <div className='addCart_Btn'>
                                                            <button onClick={() => handleAddToCart(id)}>Move to Cart</button>
                                                        </div> */}
                                                        <AddToCart productList={product} imgPath={imgPath} />
                                                    </div>
                                                    <Link to={`/product/${product?.product_slug}`} key={product?.id}>
                                                        <div className='dinnerSlider_details'>
                                                            <p>{product?.product_name} </p>
                                                            <div className='dinnerSliderSpan'>
                                                                <span>₹{product?.price}</span>
                                                                {product.price !== parseFloat(product.compare_price) && product.compare_price > 0 &&
                                                                    <p>₹{product.compare_price}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </Col>
                                        ))
                                        : <div className='noDataCont'>
                                            <img src={NoDataimg} alt='' />
                                            <h5>No Data Found</h5>
                                        </div>
                                }
                            </Row>

                            {/* </Slider> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllProduct;