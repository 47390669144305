// rootReducer.js
import { combineReducers } from 'redux';
import cartReducer from './cartSlice';
import cartIdReducer from './cartIdSlice';
import userReducer from './userSlice';
import couponReducer from './couponSlice';
import searchSlice from './searchSlice';
import rewardSlice from './rewardSlice';
import filterSlice from './filterSlice';
import shippingSlice from './shippingSlice';
import buySlice from './buySlice';
import favIdSlice from './favIdSlice';
import compareSlice from './compareSlice';
import slugSlice from './slugSlice';
import excRateSlice from './excRateSlice';
import eventkeySlice from './eventkeySlice';
import seoSlice from './seoSlice';
import categorySlice from './categorySlice';


const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  buy: buySlice,
  coupon: couponReducer,
  search:searchSlice,
  cartId: cartIdReducer,
  favId: favIdSlice,
  reward:rewardSlice,
  chips:filterSlice,
  shippingFees:shippingSlice,
  comp:compareSlice,
  slug:slugSlice,
  excrate:excRateSlice,
  evetkey:eventkeySlice,
  seo: seoSlice,
  cat:categorySlice
});

export default rootReducer;
// seo: seoReducer, // Add SEO reducer