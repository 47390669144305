import React, { memo } from 'react';
import { useSelector } from 'react-redux';

const ProductPrice = ({ product }) => {
    const excRate = useSelector(state => state.excrate);
    const { price = 0, compare_price = 0, usd_price = 0, usd_compare_price = 0,newPrice=0 } = product || {};
    const updatePrice = newPrice ? newPrice : price;
    const isINR = excRate?.currencyCode === "INR";
    const showComparePrice = compare_price > 0 && parseFloat(price) !== parseFloat(compare_price);
    const convertedPrice = usd_price * (excRate?.rate || 1);
    const convertedComparePrice = usd_compare_price * (excRate?.rate || 1);
    const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || '$';

    return (
        <>
            <span>
                {isINR ? `₹${updatePrice}` : `${currencySymbol} ${convertedPrice.toFixed(2)}`}
            </span>
            {showComparePrice && (
                <p>
                    {isINR ? `₹${compare_price}` : usd_compare_price > 0 && `${currencySymbol} ${convertedComparePrice.toFixed(2)}`}
                </p>
            )}
        </>
    );
}

export default memo(ProductPrice);

