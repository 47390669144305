// AddressDetails.js
import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { serviceability } from '../../services/ship/courierServiceability';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { applyShipping } from '../../reducers/shippingSlice';

const AddressDetails = ({ showDrawer, userAddress, setSelectedAddressId, setDeliveryError, selectedAddressId, setShipping }) => {
    const [checked, setChecked] = useState()
    const dispatch = useDispatch()
    let cart = useSelector((state) => state.cart);
    let buy = useSelector((state) => state.buy);
    // Calculate total weight
    // const totalWeight = cart.reduce((accumulator, currentItem) => {
    //     // Check if currentItem has a 'weight' property
    //     if (currentItem.hasOwnProperty('weight')) {
    //         // Add the weight to the accumulator
    //         return accumulator + currentItem.weight;
    //     } else {
    //         // If 'weight' property doesn't exist, add 0 to accumulator
    //         return accumulator + 0;
    //     }
    // }, 0);



    const handleIsDefault = async (commonId) => {
        if (commonId) {
            const formData = new URLSearchParams();
            formData.append('delivery_postcode', commonId?.zip_code);
            formData.append('pickup_postcode', '302014');
            formData.append('weight', '1');
            formData.append('cod', '1');
            try {
                // const response = await serviceability(orpickup_postcode, delivery_postcode, weight, cod);
                const response = await serviceability(formData);
                const availableCourierCompanies = response?.data?.available_courier_companies || [];
                // setShipping(availableCourierCompanies)
                // localStorage.setItem("shippingFees",availableCourierCompanies[0]?.freight_charge || null)
                dispatch(applyShipping(availableCourierCompanies[0]?.etd || null))
                setDeliveryError(false)


                if (!availableCourierCompanies.length) {
                    toast.error("No delivery found with this pincode", { toastId: '1' });
                    setDeliveryError(true)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // toast.error("An error occurred while checking the pincode");
            }
        }
        //     else{
        //         toast.error("Please Enter Pincode");

        // }
    }

    useEffect(() => {
        if (userAddress?.length > 0) {
            const defaultAddress = userAddress?.find(address => address.is_default === 1);
            if (defaultAddress) {
                setSelectedAddressId(defaultAddress);
                setChecked(defaultAddress.id);
            }
            if (cart?.length > 0 || buy?.length > 0) {
                handleIsDefault(defaultAddress)
            }
        }
    }, [userAddress]);

    const handleRadioChange = async (addressId) => {
        // console.log('addressIdaddressId', addressId);
        setChecked(addressId?.id)
        setSelectedAddressId(addressId);
        handleIsDefault(addressId)
    };

    return (
        <>
            {/* {userAddress && userAddress.map((a, i) => (
                a.address&& 
                <div className={`checkout_address_cont ${a.is_default === 1 ? 'default_Addaddress' : ''}`} key={i}>
                    <div>
                        <div className='checkout_name'>
                        <div>
                            <Radio
                                checked={a.id === checked}
                                onChange={() => handleRadioChange(a)} />
                            <span>{a?.name}</span>
                        </div>
                        {a?.is_default === 1 && <div className='default_btn'>Default</div>}
                    </div>
                    <div>
                        <div className='shippingAddr'>
                            <h4>Shipping Address</h4>
                        </div>
                        <p>{a?.address}<br />
                            {a?.city?.city_name}, {a?.state?.state_name}, {a?.country?.country_name}, {a?.land_mark !== "undefined" && a?.land_mark !== "" && a?.land_mark !==null && `${a?.land_mark}`}-{a?.zip_code}</p>
                    </div>
                    {a?.is_billing_address ===1&&
                    <div>
                        <div className='shippingAddr'>
                            <h4>Billing Address</h4>
                        </div>
                        <p>{a?.billing_name}<br />
                            +91-{a?.billing_phone}<br />
                            {a?.billing_address}<br />
                            {a?.billing_city?.city_name}, {a?.billing_state?.state_name}, {a?.billing_country?.country_name}-{a?.billing_pincode}</p>

                            </div>}
                    </div>
                    <div className='checkout_editBtn'>
                        <button className='delivery_btn' onClick={() => showDrawer(true, a.id)}>Edit Address <ArrowRightOutlined /></button>
                    </div>
                </div>
                    
            ))} */}
            {userAddress && userAddress.map((address, index) => (

                <div className={`checkout_address_cont ${address.is_default === 1 ? 'default_Addaddress' : ''}`} key={index}>
                    <div className='checkout_name'>
                        <div>
                            <Radio
                                checked={address.id === checked}
                                onChange={() => handleRadioChange(address)} />
                            <span>{address.name}</span>
                        </div>
                        {address.is_default === 1 && <div className='default_btn'>Default</div>}
                    </div>
                    <div className='checkout_space_btn'>
                        <div>
                            <div>
                                <div className='shippingAddr'>
                                    <h4>Shipping Address</h4>
                                </div>
                                <p>
                                    {address.address}, {address.land_mark !== "undefined" && address.land_mark !== "" && `${address.land_mark}`}<br />
                                    {address.city?.city_name}, {address.state?.state_name}, {address.country?.country_name}-{address.zip_code}
                                </p>
                            </div>
                            {address.is_billing_address === 1 && (
                                <div>
                                    <div className='shippingAddr'>
                                        <h4>Billing Address</h4>
                                    </div>
                                    <p>
                                        {address.billing_name}<br />
                                        +91-{address.billing_phone}<br />
                                        {address.billing_address}<br />
                                        {address.billing_city?.city_name}, {address.billing_state?.state_name}, {address.billing_country?.country_name}-{address.billing_pincode}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='checkout_editBtn'>
                            <button className='delivery_btn' onClick={() => showDrawer(true, address.id)}>Edit Address <ArrowRightOutlined /></button>
                        </div>
                    </div>
                </div>

            ))}

            {/* <button className='delivery_btn' onClick={() => handleAddCart(selectedAddressId)}>Delivery Here <ArrowRightOutlined /></button> */}
        </>
    );
};

export default AddressDetails;
