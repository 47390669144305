import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getStateList } from '../../services/user/getStateList';
import { getCityList } from '../../services/user/getCitiyList';
import { getCountryList } from '../../services/user/getCountryList';
import { Radio, Checkbox } from 'antd';
const { TextArea } = Input;

const AddressForm = ({ open, onSubmit, editMode, existingData, form, onClose, setAddressTypeValue, addressTypeValue, setDefaultValue, defaultValue, isSaving, setShowBillingForm, showBillingForm }) => {

  const user = useSelector((state) => state.user);
  let { token } = user;

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState(null);
  const [country_id, setCountryId] = useState(null);

  const [billingStates, setBillingStates] = useState([]);
  const [billingCities, setBillingCities] = useState([]);
  const [billingCounties, setBillingCounties] = useState([]);
  const [billingCountry_id, setBillingCountry_id] = useState(null);

  const [showGSTForm, setShowGSTForm] = useState(false);

  const userName = useRef();
  // const radioRef = useRef();

  useEffect(() => {
    const focusInput = () => {
      // Using setTimeout to ensure focusing happens after rendering
      setTimeout(() => {
        if (userName.current) {
          userName.current.focus();
        }
      }, 0);
    };
    focusInput();
  }, [open]);

  const fetchCountry = async () => {
    try {
      const response = await getCountryList();
      if (response?.status == '1') {
        setCountries(response?.data)
        setBillingCounties(response?.data);
      } else {
        setCountries([])
        setBillingCounties([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onChange = (e) => {
    // console.log('radio checked', e.target.value);
    setAddressTypeValue(e?.target?.value);
  };

  const onDefaultAddress = (e) => {
    // alert(e.target.checked);
    setDefaultValue(e.target.checked);
  };

  const reset = () => {
    setAddressTypeValue('');
    setDefaultValue(false)
    onClose();
    form.resetFields();
  }


  const fetchStates = useCallback(async (country) => {
    const formData = new URLSearchParams();
    formData.append('country_id', country);
    try {
      const responseData = await getStateList(formData, token);

      if (responseData.status === '1') {
        const stateData = responseData.data;
        return stateData;
      }
    } catch (error) {
      console.error('API Request Error:', error);
      // message.error('Login failed. Please check your credentials and try again.');
    }
    return [];
  }, [token]);

  const fetchCities = useCallback(async (state) => {
    const formData = new URLSearchParams();
    formData.append('state_id', state);
    try {
      const responseData = await getCityList(formData, token);


      if (responseData.status === '1') {
        const cityData = responseData.data;
        return cityData;
      }
    } catch (error) {
      console.error('API Request Error:', error);
      // message.error('Login failed. Please check your credentials and try again.');
    }
    return [];
  }, [token]);

  useEffect(() => {
    const selectedCountry = form?.getFieldValue('country');
    const selectedState = form?.getFieldValue('state');

    if (selectedCountry) {
      fetchStates(selectedCountry)
        .then((states) => setStates(states))
        .catch((error) => console.error('Error fetching states:', error));
    }

    if (selectedState) {
      fetchCities(selectedState)
        .then((cities) => setCities(cities))
        .catch((error) => console.error('Error fetching cities:', error));
    } else {
      setCities([]);
    }
  }, [form, fetchStates, fetchCities]);


  //// billing countries code

  const fetchBillingStates = useCallback(async (country) => {
    const formData = new URLSearchParams();
    formData.append('country_id', country);
    try {
      const responseData = await getStateList(formData, token);

      if (responseData.status === '1') {
        const stateData = responseData.data;
        return stateData;
      }
    } catch (error) {
      console.error('API Request Error:', error);
      // message.error('Login failed. Please check your credentials and try again.');
    }
    return [];
  }, [token]);

  const fetchBillingCities = useCallback(async (state) => {
    const formData = new URLSearchParams();
    formData.append('state_id', state);
    try {
      const responseData = await getCityList(formData, token);


      if (responseData.status === '1') {
        const cityData = responseData.data;
        return cityData;
      }
    } catch (error) {
      console.error('API Request Error:', error);
      // message.error('Login failed. Please check your credentials and try again.');
    }
    return [];
  }, [token]);

  useEffect(() => {
    const selectedBCountry = form?.getFieldValue('billing_country_id');
    const selectedBState = form?.getFieldValue('billing_state_id');

    if (selectedBCountry) {
      fetchBillingStates(selectedBCountry)
        .then((states) => setBillingStates(states))
        .catch((error) => console.error('Error fetching states:', error));
    }

    if (selectedBState) {
      fetchBillingCities(selectedBState)
        .then((cities) => setBillingCities(cities))
        .catch((error) => console.error('Error fetching cities:', error));
    } else {
      setBillingCities([]);
    }
  }, [form, fetchBillingStates, fetchBillingCities]);

  const handleStateChange = (value) => {
    form?.setFieldsValue({ city: undefined });
    fetchCities(value)
      .then((cities) => setCities(cities))
      .catch((error) => console.error('Error fetching cities:', error));
  };

  const handleBillingStateChange = (value) => {
    form?.setFieldsValue({ billing_city_id: undefined });
    fetchBillingCities(value)
      .then((cities) => setBillingCities(cities))
      .catch((error) => console.error('Error fetching cities:', error));
  };

  // Edit mode
  useEffect(() => {
    const defaultValues = {
      country: '',
      state: '',
      city: '',
      pincode: '',
      address: '',
      name: '',
      phone: '',
      land_mark: '',
      gstNumber: '',
      isGst: 0,
      gstCompanyName: '',
      gstAddress: '',
      is_shipping_address: 0,
      is_billing_address: 0,
      billing_name: '',
      billing_phone: '',
      billing_country_id: '',
      billing_state_id: '',
      billing_city_id: '',
      billing_pincode: '',
      billing_address: '',
    };

    if (editMode && existingData) {

      // console.log('existingData:', existingData);

      const { country, state, city, zip_code, address, name, mobile_number, land_mark, is_gst, gst_number, company_name, company_address, is_shipping_address, is_billing_address, billing_name, billing_country_id, billing_state_id, billing_city_id, billing_country, billing_state, billing_city, billing_address, is_default, billing_pincode, billing_phone } = existingData;

      const newNmae = name?.replace(/\s+/g, ' ').trim();

      is_billing_address == '1' ? setShowBillingForm(true) : setShowBillingForm(false);
      // is_gst == '1' ? setShowGSTForm(true) : setShowGSTForm(false)

      form?.setFieldsValue({
        country: country?.id,
        pincode: zip_code,
        address,
        name: newNmae,
        land_mark: land_mark !== "undefined" && land_mark !== 'null' && land_mark !== null ? land_mark : "",
        phone: mobile_number,
        isGst: is_gst,
        gstNumber: gst_number?.toUpperCase(),
        gstCompanyName: company_name != null ? company_name : '',
        gstAddress: company_address,
        is_shipping_address: is_shipping_address,
        is_billing_address: is_billing_address,
        billing_name: billing_name,
        billing_country_id: billing_country_id == '0' ? null : billing_country_id,
        billing_state_id: billing_state_id == '0' ? null : billing_state_id,
        billing_city_id: billing_city_id == '0' ? null : billing_city_id,
        billing_address: billing_address,
        billing_pincode: billing_pincode,
        billing_phone: billing_phone,
        // The following lines set the state and city based on the API response
        state: state?.id,
        city: city?.id,
      });
      // console.log('existingDataexistingDataexistingData',existingData?.title);
      setAddressTypeValue(existingData?.title)
      setDefaultValue(existingData?.is_default)

      if (is_gst == '1') {
        setShowGSTForm(true);
        form.setFieldsValue({ isGst: true });
      } else {
        setShowGSTForm(false);
        form.setFieldsValue({ isGst: false });
      }

      // Fetch states and set them in the form
      fetchStates(country?.id)
        .then((states) => {
          setStates(states);

          // Fetch cities based on the selected state
          return fetchCities(state.id);
        })
        .then((cities) => setCities(cities))
        .catch((error) => console.error('Error fetching states/cities:', error));

      fetchBillingStates(billing_country_id)
        .then((states) => {
          setBillingStates(states);

          // Fetch cities based on the selected state
          return fetchBillingCities(billing_state_id);
        })
        .then((cities) => setBillingCities(cities))
        .catch((error) => console.error('Error fetching states/cities:', error));


    } else {
      form?.setFieldsValue(defaultValues);
      setAddressTypeValue('')
      setDefaultValue(false)
    }
  }, [editMode, existingData, form, fetchStates, fetchCities, fetchBillingStates, fetchBillingCities]);

  const countryChange = async (value) => {
    setCountryId(value);
    form?.setFieldsValue({ state: undefined, city: undefined });
    const stateList = await fetchStates(value);
    setStates(stateList);
  }

  // billing country
  const billingCountryChange = async (value) => {
    setBillingCountry_id(value);
    form?.setFieldsValue({ billing_state_id: undefined, billing_city_id: undefined });
    const stateList = await fetchBillingStates(value);
    setBillingStates(stateList);
  }

  useEffect(() => {
    fetchCountry();
  }, [])

  const handleKeyPress = (e) => {
    const key = e.key;
    if (key >= "0" && key <= "9" || !(key.match(/[a-zA-Z\s]/))) {
      e.preventDefault();
    }
  };

  // Validation function for Mobile & Pincode
  const handleValidationForMobileAndPincode = (e) => {
    const key = e.key;
    const input = e.target.value;
    if (key === '0' && (input === '' || e.target.selectionStart === 0)) {
      e.preventDefault();
    }
    if (!(key >= '0' && key <= '9') && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      // If the pressed key is not a number (0-9), the backspace key, or the tab key
      e.preventDefault(); // Prevent typing
    }
  };


  const handleCheckboxChange = (e) => {
    setShowGSTForm(e.target.checked);
  };

  // const [showBillingForm, setShowBillingForm] = useState(false);

  const handleBillingChange = (e) => {
    setShowBillingForm(e.target.checked);
  };

  const handleRadioChange = e => {
    const value = e.target.value;
    form.setFieldsValue({
      is_shipping_address: value === 'same_ship_add',
      is_billing_address: value === 'differ_bill_add',
    });
    setShowBillingForm(value === 'differ_bill_add');
  };


  return (
    <Form form={form} onFinish={onSubmit} autoComplete="off">
      <div className='model_form'>
        <Row gutter={18}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={<>Name</>}
              rules={[
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
            >
              <Input placeholder="Enter Name" onKeyDown={handleKeyPress} onChange={(e) => {
                const trimmedValue = e.target.value.trimStart();
                form?.setFieldsValue({ name: trimmedValue });
              }} ref={userName} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phone"
              label={<>Phone</>}
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone',
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'Please enter a valid 10-digit phone number',
                },
              ]}
            >
              <Input placeholder="Enter Phone" maxLength={10} type='text' onKeyDown={handleValidationForMobileAndPincode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label={<>Country</>}
              rules={[
                {
                  required: true,
                  message: 'Please select your country',
                },
              ]}
            >
              <Select
                showSearch
                // name="country_id"
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={(e) => {
                  countryChange(e);
                  // stateChange(null)

                }}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={country_id || undefined}
              >
                {countries &&
                  countries?.sort((a, b) => a.country_name.localeCompare(b.country_name))?.map((country, index) => (
                    <Select.Option key={index} value={country.id}>
                      {country.country_name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              label={<>State</>}
              rules={[
                {
                  required: true,
                  message: 'Please select your state',
                },
              ]}
            >
              <Select placeholder="Select State"
                showSearch
                onChange={handleStateChange}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {states?.sort((a, b) => a.state_name.localeCompare(b.state_name))?.map((state) => (
                  <Select.Option key={state.id} value={state.id}>
                    {state.state_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={<>City</>}
              className='counttyDropdown'
              rules={[
                {
                  required: true,
                  message: 'Please select your city',
                },
              ]}
            >
              <Select placeholder="Select City" showSearch filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
                {cities?.sort((a, b) => a.city_name.localeCompare(b.city_name))?.map((city) => (
                  <Select.Option key={city.id} value={city.id}>
                    {city.city_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="pincode"
              label={<>Pincode</>}
              className='counttyDropdown'
              rules={[
                {
                  required: true,
                  message: 'Please enter your pincode',
                },
                {
                  pattern: /^[0-9]{6}$/,
                  message: 'Please enter a valid 6-digit pincode',
                },
              ]}
            >
              <Input placeholder="Enter Pincode" maxLength={6} onKeyDown={handleValidationForMobileAndPincode} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="address"
          label={<>Address</>}
          rules={[
            {
              required: true,
              message: 'Please enter your full address',
            },
          ]}
        >
          <TextArea placeholder="Enter Road/Area/Colony" rows={6} onKeyDown={() => handleKeyPress} onChange={(e) => {
            const trimmedValue = e.target.value.trimStart();
            form?.setFieldsValue({ address: trimmedValue });

          }} />
        </Form.Item>
        <Form.Item
          name="land_mark"
          label={<>Landmark</>}
        >
          <Input placeholder="Landmark" onKeyDown={() => handleKeyPress} onChange={(e) => {
            const trimmedValue = e.target.value.trimStart();
            form?.setFieldsValue({ land_mark: trimmedValue });

          }} />
        </Form.Item>
      </div>
      <div className='model_formSecAddress'>
        <Row>
          <Col span={24}>
            <Form.Item
              name="title"
              label={(
                <>
                  <p>Type of Address</p> <span className='requires_star'>*</span>
                </>
              )}
            // rules={[
            //   {
            //     required: !addressTypeValue,
            //     message: 'Please select the type of address!',
            //   },
            // ]}
            >
              <div className='radiobutton_type'>
                <Radio.Group onChange={(e) => onChange(e)} value={addressTypeValue}>
                  <Radio value='Home'>Home</Radio>
                  <Radio value='Office'>Office</Radio>
                  <Radio value='Other'>Other</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='is_default'
              valuePropName="checked"
            >
              <div className="checkbox_sigup">
                <Checkbox checked={defaultValue} onChange={onDefaultAddress} >
                  Set as default Shipping Address
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </div>

      {/* Billing Address */}
      <div className='billing_addresses'>
        <h5>Billing address</h5>
        <div className='billing_mainCont'>
          <Radio.Group onChange={handleRadioChange} value={showBillingForm ? 'differ_bill_add' : 'same_ship_add'} >
            <div className='shippingAddress'>
              <Form.Item name='is_shipping_address' valuePropName='checked'>
                <Radio value='same_ship_add' >Same as shipping address</Radio>
              </Form.Item>
            </div>
            <div className='billingAddress'>
              <Form.Item name='is_billing_address' valuePropName="checked" >
                <Radio value='differ_bill_add'>Use a different billing address</Radio>
              </Form.Item>
            </div>
          </Radio.Group>

          {showBillingForm && (
            <div className='gst_form'>
              {/* <Form> */}
              <Row gutter={18}>
                <Col span={24}>
                  <Form.Item
                    name="billing_name"
                    label={<>Name</>}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Name" onKeyDown={handleKeyPress} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ billing_name: trimmedValue });
                    }} ref={userName} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="billing_phone"
                    label={<>Phone</>}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your phone',
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: 'Please enter a valid 10-digit phone number',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Phone" maxLength={10} type='text' onKeyDown={handleValidationForMobileAndPincode} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="billing_country_id"
                    label={<>Country</>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your country',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      name="billing_country_id"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(e) => {
                        billingCountryChange(e);
                        // stateChange(null)

                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={billingCountry_id || undefined}
                    >
                      {billingCounties &&
                        billingCounties?.sort((a, b) => a.country_name.localeCompare(b.country_name))?.map((country, index) => (
                          <Select.Option key={index} value={country.id}>
                            {country.country_name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="billing_state_id"
                    label={<>State</>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your state',
                      },
                    ]}
                  >
                    <Select placeholder="Select State"
                      showSearch
                      onChange={handleBillingStateChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {billingStates?.sort((a, b) => a.state_name.localeCompare(b.state_name))?.map((state) => (
                        <Select.Option key={state.id} value={state.id}>
                          {state.state_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="billing_city_id"
                    label={<>City</>}
                    className='counttyDropdown'
                    rules={[
                      {
                        required: true,
                        message: 'Please select your city',
                      },
                    ]}
                  >
                    <Select placeholder="Select City" showSearch filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                      {billingCities?.sort((a, b) => a.city_name.localeCompare(b.city_name))?.map((city) => (
                        <Select.Option key={city.id} value={city.id}>
                          {city.city_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="billing_pincode"
                    label={<>Pincode</>}
                    className='counttyDropdown'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your pincode',
                      },
                      {
                        pattern: /^[0-9]{6}$/,
                        message: 'Please enter a valid 6-digit pincode',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Pincode" maxLength={6} onKeyDown={handleValidationForMobileAndPincode} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="billing_address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your address',
                      },

                    ]}
                  >
                    <Input placeholder="Enter Address" />
                  </Form.Item>
                </Col>
              </Row>
              {/* </Form> */}
            </div>
          )}
        </div>
      </div>
      <div className='save_address_button'>
        <button className='cancelButton' type='button' onClick={reset}>Cancel</button>
        <button className='savedAddressButton' type='submit' disabled={isSaving}>{editMode ? "Update Address" : "Save Address"}</button>
      </div>
    </Form>
  );
};

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddressForm;
