// services/coupon/Coupon.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);

  if (error.response && error.response.data && error.response.data.message) {
    throw new Error(error.response.data.message);
  } else {
    throw new Error('An error occurred. Please try again later.');
  }
};

export const getCouponList = async (token) => {
    // console.log('coupon token :',token);
  try {
    const response = await api.get('/get-coupon',{
      headers: {
        Authorization: token,
      },
    });
    if (response?.data?.message === "Invalid token") {
      throw new Error("Invalid token");
    }
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getProductCoupon = async (data) => {
  try {
    const response = await api.post('/get-product-coupon', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Coupon for First order
export const getFirstOrderCoupon = async (data) => {
try {
  const response = await api.post('/get-first-order-coupon',data,{
    
  });

  return response.data;
} catch (error) {
  handleApiError(error);
}
};