import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStaticPage } from '../../services/staticPage';


function Shipping() {
  const [shppingData, setShippingData] = useState(null);
  const hasMounted = useRef(false);

  const fetchData = async () => {
    try {
      const response = await getStaticPage('return-and-shipping');
      // console.log('responseresponse', response);
      if (response.status === '1') {
        setShippingData(response.data);
      }

      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hasMounted.current) return;
    fetchData();
    hasMounted.current = true;
  }, []);

  return (
    <div className='shipping_container'>
      <h1>{shppingData?.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: shppingData && shppingData?.description }} className='shipping_content'></div>
    </div>
  )
}

export default Shipping