import React, { useEffect, useState } from 'react';
import { TruckOutlined } from '@ant-design/icons';
import "../../assets/css/style/checkout.scss"
import { getCustomerSupport } from '../../services/assurance';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import ProductPrice from '../ProductPrice';


const CheckoutCartProduct = ({ product }) => {
  const comp = useSelector(state => state.shippingFees);
  const [supportData, setSupportData] = useState({});
  const getLocalData = localStorage.getItem("bufferdays");
  const excRate = useSelector(state => state.excrate);

  const handleSupport = async () => {
    try {
      let result = await getCustomerSupport();
      setSupportData(result?.data);
    }
    catch (e) {
      console.error('error', e);
    }
  }

  useEffect(() => {
    handleSupport()
  }, [])

  const estimatedDays = parseInt(getLocalData) + supportData?.product_buffer_days

  const Dayjs = require('dayjs');

  const dateFormat = 'MMM DD, YYYY';

  const originalDate = Dayjs(comp, dateFormat);

  const newDate = originalDate.add(estimatedDays, 'day');

  const newDateString = newDate.format(dateFormat);

  return (
    <div className={`checkout_bag`}>
      <div className='cart-products'>
        <div className='left_cart'>
          {product.image ? (
            <Link to={`${Routes?.ProductDetail}/${product.product_slug}`} state={{ ProductId: product?.id }}>
              <img src={product.image} alt={product.product_name} />
            </Link>

          ) : (
            'Image Not Found'
          )}
        </div>
        <div className='right_cart'>
          <div className='price_delete align-items-start'>
            <Link to={`${Routes?.ProductDetail}/${product.product_slug}`} state={{ ProductId: product?.id }}>
              <h2>{product.product_name}</h2>
            </Link>
            {/* <div className='crossed_heartIcon'>
              <Tooltip title="Delete Item">
                <CloseOutlined onClick={(e) => { handleRemove(product.product_name, product.id) }} alt={product.product_name} />
              </Tooltip>
            </div> */}
            <div className='cartProduct_price'>
              <ProductPrice product={product}/>
            </div>

          </div>
          <span>QTY : {product.count}</span>
          <div className='add_product_price'>

            {/* <div className="button-box">
              <button className='btn-incriment'>
                <span onClick={() => handleDecrement(product.product_name)}><FaMinus /></span>
                {product.count}
                <span onClick={() => handleIncrement(product.product_name)}><FaPlus /></span>
              </button>
            </div> */}
            {/* <div className='cartProduct_price'>
              <p>₹{(product.price * (product.count ?? 0)).toFixed(2)}</p>
              {product.compare_price > 0 && (
                <span>₹{(product.compare_price * (product.count ?? 0)).toFixed(2)}</span>
              )}
            </div> */}
          </div>
          <h5 className='pinCode_delivery'><TruckOutlined />Estimated delivery: {newDateString !== "Invalid Date" ? newDateString : comp}</h5>
        </div>

      </div>
    </div>
  );
};

export default CheckoutCartProduct;
