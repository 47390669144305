import { createSlice } from '@reduxjs/toolkit';

const storedBuy = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('buy')) || []: [];

const buySlice = createSlice({
  name: 'buy',
  initialState: storedBuy,
  reducers: {
    addToBuy: (state, action) => {
      return action.payload; // This replaces the entire state with the new payload
    },
    clearBuy: () => {
        // Clear the state by returning an empty object
        return [];
      },
  },
});

export const { addToBuy,clearBuy} = buySlice.actions;
export default buySlice.reducer;
