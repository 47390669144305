import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
};
const api = axios.create(apiConfig);

// Create a separate function for handling API errors
const handleApiError = (error) => {
  console.error('API Error:', error);
  throw error;
};

// Home Slider List
export const getHomeSliderList = async () => {
  try {
    const response = await api.get('/home-slider-list');
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    return handleApiError(error);
  }
};
//Home New Arrivals List
export const getNewArrivalsList = async (data) =>{
  try{
    const response = await api.post('/new-arrivals',data);
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}
export const getCategoriesList = async () =>{
  try{
    const response = await api.get('/shop-by-category');
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`)
    }


  }catch(error){
    return handleApiError(error);
  }
}
// getProductDetails
export const getProductDetails = async (slug,data) => {
  // try {
    const response = await api.post(`/get-product-detail/${slug}`,data);
    if (response.status === 200) {
          return response.data;
        } else {
          return response;
        }
    // return response.data;

    // console.log('responseresponseresponse',response);
  //   if (response.status === 200) {
  //     return response.data;
  //   } else {
  //     throw new Error(`API Error: ${response.statusText}`);
  //   }
  // } catch (error) {
  //   return handleApiError(error);
  // }
};
// getProductList
export const getProductList = async (slug ,data)=>{
  try {
    const response = await api.post(`/get-category-detail/${slug}`, data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

// Get the banner images on the Home page. 
export const getHomeSectionList = async (data)=>{
  try {
    const response = await api.post('/home-section-list', data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getGiftList = async () =>{
  try{
    const response = await api.get('/get-home-gift-visits');
    if(response.status === 200){
      // console.log('111111',response.data);
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`)
    }


  }catch(error){
    return handleApiError(error);
  }
}

export const getHaulisList = async () =>{
  try{
    const response = await api.get('/get-home-grid-visits');
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`)
    }


  }catch(error){
    return handleApiError(error);
  }
}

export const checkPincode = async (data) => {
  try {
    const response = await api.post('/check-pincode',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const getGiftPriceList = async (slug ,data)=>{
  try {
    const response = await api.post(`get-gift-product/${slug}`, data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}
export const getGiftCategory= async ()=>{
  try {
    const response = await api.get('get-gift-category');
      return response.data;
  }catch(error){
    return handleApiError(error);
  }
}

export const getBestSellerList = async (data) =>{
  try{
    const response = await api.post('/get-best-sell-product',data);
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}
export const getColorProductList = async (data) =>{
  try{
    const response = await api.post('/get-product-color-wise-product',data);
      return response.data;
  }catch(error){
    return handleApiError(error);
  }
}

export const getBestSellerAllList = async (data) =>{
  try{
    const response = await api.post('/get-all-best-sell-product',data);
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getHomeColorList = async () =>{
  try{
    const response = await api.get('/get-fourite-color');
    if(response.status === 200){
      return response.data;
    }else{
      throw new Error(`API Error: ${response.statusText}`)
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getProductByColorList = async (id ,data)=>{
  try {
    const response = await api.post(`/get-color-wise-product/${id}`, data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getFinestProductList = async (data)=>{
  try {
    const response = await api.post("get-finest-product", data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getHomeFinestProductList = async (data)=>{
  try {
    const response = await api.post("finest-product", data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}

export const getSavingProductList = async (data, token) => {
  try {
      const response = await api.post('get-discounted-products', data, {
          headers: {
            Authorization: token,
          },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(`API Error: ${response.statusText}`);
        }
  } catch (error) {
    return handleApiError(error);
  }
};