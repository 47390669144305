// src/utils/cartUtils.js

import { useSelector } from "react-redux";

const CalculateTotals = () => {
  const cartIds = useSelector((state) => state.cartId);
  const cart = useSelector((state) => state.cart);
  const couponSlice = useSelector((state) => state.coupon);
  const rewardSlice = useSelector((state) => state.reward);
  const excRate = useSelector(state => state.excrate);
  const filteredCartData = cart.filter((product) => cartIds.includes(product.id));
  // const localcoupon=JSON.parse(localStorage.getItem('coupon'));
  // console.log('filteredCartData',localcoupon);
  // console.log('couponSlice',couponSlice);
  const cartLength = filteredCartData.length;  // console.log('filteredCartData',filteredCartData);

  let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
  firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);

  const total = filteredCartData.reduce(
    (accumulator, { compare_price = 0,usd_price=0,usd_compare_price=0, price = 0, count = 0, newPrice=0 }) => {
      const effectivePrice = newPrice || price;
      accumulator.totalComparePrice += excRate?.currencyCode === "INR" ?(compare_price > 0 ? compare_price : effectivePrice) * count:(usd_compare_price > 0 ? usd_compare_price : usd_price) * count;
      accumulator.totalPrice += excRate?.currencyCode === "INR" ?effectivePrice * count:usd_price * count;
      accumulator.totalCount += count;

  return accumulator;
},
{ totalPrice: 0, totalComparePrice: 0, totalCount: 0 }
  );

  const bagDiscount = total.totalComparePrice - total.totalPrice;

  let totalPrice = 0;
  let firstOrderCouponDiscount=0;

if (firstOrderCouponDetail?.order_count === 0) { // Case of first order
     firstOrderCouponDiscount = total.totalPrice * (firstOrderCouponDetail?.data?.type_val / 100);
    //  console.log('firstOrderCouponDiscount',firstOrderCouponDiscount);
    totalPrice = total.totalPrice - firstOrderCouponDiscount;
    // console.log('totalPricetotalPrice',totalPrice);
    // console.log('firstOrderCouponDiscount==50=>>', grandTotal);
} else {
    totalPrice = total.totalPrice;
}

  let disAmount = 0;
  if (couponSlice) {
    if (couponSlice.coupon_type === 'percent') {
      const amountOfPercent = couponSlice.type_val / 100;
      disAmount = totalPrice * amountOfPercent;
    } else if (couponSlice.coupon_type === 'normal') {
      disAmount = couponSlice.type_val;
    }
  }
  const rewardAmnt = total.totalPrice > 0 ? rewardSlice || 0 : null;
  let grandTotal = totalPrice - disAmount - (rewardAmnt > 0 ? rewardAmnt : 0);


  // if (firstOrderCouponDetail?.order_count == 0) { // Case of first order
  //   if (grandTotal > 0) {
  //     firstOrderCouponDiscount = grandTotal * (firstOrderCouponDetail?.data?.type_val / 100);
  //     grandTotal =  grandTotal - firstOrderCouponDiscount;
  //     // console.log('firstOrderCouponDiscount==50=>>', grandTotal);
  //   }
  //   else {
  //     return grandTotal;
  //   }
  // }
  // else {
  //    grandTotal = grandTotal;
  // }
  // let firstOrderCouponDiscount=0;
  // if (firstOrderCouponDetail?.order_count === 0) { // Case of first order
  //   if (grandTotal > 0) {
  //     firstOrderCouponDiscount = grandTotal * (firstOrderCouponDetail?.data?.type_val / 100);      
  //     grandTotal =  grandTotal - firstOrderCouponDiscount;
  //     // console.log('firstOrderCouponDiscount==50=>>', grandTotal);
  //   }
  //   else {
  //     return grandTotal;
  //   }
  // }
  // else {
  //    grandTotal = grandTotal;
  // }
  // console.log('firstOrderCouponDiscount',firstOrderCouponDiscount);

  // const savingAmnt = bagDiscount + disAmount + (rewardAmnt > 0 ? rewardAmnt : 0)
  let savingAmnt = bagDiscount + disAmount + (rewardAmnt > 0 ? rewardAmnt : 0)
  savingAmnt += firstOrderCouponDiscount > 0 ? firstOrderCouponDiscount : 0;

  return {
    total,
    disAmount,
    grandTotal,
    cartLength,
    filteredCartData,
    bagDiscount,
    rewardAmnt,
    savingAmnt,
    firstOrderCouponDiscount
  };
};

export default CalculateTotals;