import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getSeoMeta } from './services/seo/seo';
import SchemaMarkup from './components/SchemaMarkup';
import { getAllCategory } from './services/category/categoryApi';
import { useDispatch } from 'react-redux';
import { addToSeo } from './reducers/seoSlice';
import { addToCategory } from './reducers/categorySlice';

const getBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

const MyComponent = () => {
  const BASE_URL = getBaseUrl();
  // console.log('BASE_URLBASE_URLBASE_URL',BASE_URL);
  const location = useLocation();
  // const canonicalUrl = `https://kairaus.com${location.pathname}`;
  const canonicalUrl = `${BASE_URL}${location.pathname}`;
  const [seoData, setSeoData] = useState({
    title: 'Kairaus',
    description: '',
  });
  const dispatch = useDispatch();

  // const handleSeoUpdate = () => {
  //   dispatch(addToSeo({ description: seoData.footer_content }));
  // };
  const slug = window.location.href.split('/');
  const lastSegment = slug[slug.length - 1] || 'home';
  const productType = slug[slug.length - 2];
  useEffect(() => {
    const fetchData = async () => {
      const res_category = await getAllCategory();
      if(res_category.status==="1"){
        dispatch(addToCategory(res_category?.data));
      }
      const slugData = res_category?.data?.map(item => item.slug).filter(Boolean); // Extracting slugs
      const validSegments = new Set(slugData);
      const pageType = validSegments.has(lastSegment) ? 'category' : lastSegment === 'home' ? 'home' :productType==='product'?'product': 'menu';
      try {
        const formData = new URLSearchParams();
        formData.append('page_type', pageType);
        formData.append('slug', lastSegment);
        const response = await getSeoMeta(formData);
       if(response.status === '0'){
        dispatch(addToSeo(""));
       }
        if (response.status === '1') {
          setSeoData({
            title: response.data?.title,
            description: response.data?.meta_description,
          });
          dispatch(addToSeo(response.data?.footer_content));
          
        }
        // Do something with the response, if needed
      } catch (error) {
        console.error('Error fetching seo data:', error);
      }
    };

    fetchData();
  }, [lastSegment]); // Dependency array is empty, meaning this effect runs only once on mount

  return (
    <>
      <Helmet>
        <meta name="twitter:title" content={seoData.title}/>
        <meta name="twitter:description" content={seoData.description}/>
        <meta property="og:title" content={seoData.title}/>
        <meta property="og:description" content={seoData.description}/>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <link rel="canonical" href={canonicalUrl} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-HPKYDJRGM2"></script>
        <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-HPKYDJRGM2');
        `}
        </script>
      </Helmet>

      {/* Integrate SchemaMarkup component */}
      <SchemaMarkup
        type="ItemList"
        data={{
          itemListElement: [
            { '@type': 'ListItem', position: 1, name: 'Dining', url: 'https://kairaus.com/dining' },
            { '@type': 'ListItem', position: 2, name: 'Home Decor', url: 'https://kairaus.com/home-decor' },
            { '@type': 'ListItem', position: 3, name: 'Home Furnishing', url: 'https://kairaus.com/home-furnishing' },
            { '@type': 'ListItem', position: 4, name: 'Lifestyle', url: 'https://kairaus.com/lifestyle' },
            { '@type': 'ListItem', position: 5, name: 'Stationery', url: 'https://kairaus.com/stationery' },
            { '@type': 'ListItem', position: 6, name: 'About Us', url: 'https://kairaus.com/about-us' },
            // Add other items here
          ],
        }}
      />
      <SchemaMarkup
        type="Organization"
        data={{
          name: 'Kairaus',
          url: 'https://kairaus.com/',
          logo: '',
          sameAs: [
            'https://www.facebook.com/profile.php?id=61556630913372&is_tour_completed=true',
            'https://twitter.com/kairaus',
            'https://www.instagram.com/kairaus/',
            'https://www.youtube.com/@kairaus',
            'https://www.linkedin.com/in/kairaus/',
            'https://in.pinterest.com/kairaus/',
          ],
        }}
      />
      <SchemaMarkup
        type="WebSite"
        data={{
          name: 'Kairaus',
          url: 'https://kairaus.com/',
          potentialAction: {
            '@type': 'SearchAction',
            target: 'https://kairaus.com/{search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        }}
      />
    </>
  );
};

export default MyComponent;
