import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStaticPage } from '../../services/staticPage';

function TermCondition() {
  const [termsData, setTermsData] = useState(null);
  const hasMounted = useRef(false);
  const fetchData = async () => {
    try {
      const response = await getStaticPage('terms-&-conditions');
      // console.log('responseresponse', response);
      const privacyData = response.data;
      if (response.status === '1') {
        setTermsData(privacyData);
      }

      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hasMounted.current) return;
    fetchData();
    hasMounted.current = true;
  }, []);

  return (
    <div className='shipping_container'>
      <h1>Terms & Conditions of Use</h1>
      <div dangerouslySetInnerHTML={{ __html: termsData && termsData.description }} className='shipping_content'></div>
    </div>
  )
}

export default TermCondition