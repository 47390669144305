import { createSlice } from '@reduxjs/toolkit';

let storeInstance; // store instance

export const initializeCartSlice = (store) => {
  storeInstance = store; // store instance is set during initialization
};

const storedCart = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('cart')) || [] : [];

const cartSlice = createSlice({
  name: 'cart',
  initialState: storedCart,
  reducers: {
    addToCart: (state, action) => {
      // return [...state, ...action.payload] // This appends the new item to the existing state
      return action.payload; // This replaces the entire state with the new payload
    },
    // Other cases for cart actions

    // Example selector to get total count
    clearCart: (state, action) => {
      return [];
    },
    updateCartItem: (state, action) => {
      const { productId, newPrice, discountApply} = action.payload;
      const updatedCart = state.map(item => {
        if (item.id === productId) {
          return { ...item, newPrice: newPrice, discountApply:discountApply };
        }
        return item;
      });
      return updatedCart;
    },
  },
});

export const { addToCart, clearCart, updateCartItem } = cartSlice.actions;

// Add an event listener to detect changes in local storage
window.addEventListener('storage', (event) => {
  if (event.key === 'logoutEvent') {
    // Dispatch clearCart action when handling 'logoutEvent'
    storeInstance.dispatch(clearCart());
  }
});

export default cartSlice.reducer;
