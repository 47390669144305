// excreteSlice.js
import { createSlice } from '@reduxjs/toolkit';

const excRateSlice = createSlice({
  name: 'excrate',
  initialState: {},
  reducers: {
    addToExcRate: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToExcRate } = excRateSlice.actions;
export default excRateSlice.reducer;
