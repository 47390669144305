// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Input, Row, Col, Steps, Drawer, Divider, Select, Table, Dropdown, Modal, Button } from "antd";
import SearchImg from "../../assets/images/searchIMg.png";
import OutBox from "../../assets/images/OrderBox.png";
import invoiceImg from "../../assets/images/document-text.png";
import OrderTrack from "../../assets/images/truck-tick.png";
import VisaImg from "../../assets/images/visa.png";
import infoCircle from "../../assets/images/info-circle.png";
import arrowImg from "../../assets/images/arrow-up.png";
import truckTime from "../../assets/images/trucktime.png";
import boxImg from "../../assets/images/box-remove.png";
import Routes from '../../Routes/Routes';
import dayjs from 'dayjs';
import { authApi } from "../../services/auth/authApi";
import { trackingOrder } from "../../services/orders/order";
import { useLocation, useNavigate, Link } from "react-router-dom";
import NoDataimg from '../../assets/images/noData.png'
import { MoreOutlined } from '@ant-design/icons';
import tableIMg from '../../assets/images/1st.png'
import trashLottie from '../../assets/images/trash-lottie.json'
import Lottie from 'react-lottie';
import trackImg from '../../assets/images/placeholder.png';
import cancelImg from '../../assets/images/close.png'
import buyImg from '../../assets/images/update-arrows.png'
import { useSelector } from "react-redux";
import { cancelOrder } from "../../services/orders/orderDetail";
import { toast } from "react-toastify";

const data = [
    {
        key: '1',
        sno: "1",
        prodName: 'Ceramic Platters',
        empID: "EMP0059",
        img: tableIMg,
        quatityOrder: "Set of 2",
        date: "06 July 2023",
        status: "Dispatched Item",
    },
    {
        key: '2',
        sno: "2",
        prodName: 'Ceramic Platters',
        empID: "EMP0059",
        img: tableIMg,
        quatityOrder: "Set of 4",
        date: "06 July 2023",
        status: "Out for delivery",
    },
    {
        key: '3',
        sno: "3",
        prodName: 'Handmade Ceramic Stag Blue Multipurpose Use',
        empID: "EMP0059",
        img: tableIMg,
        quatityOrder: "Set of 6",
        date: "06 July 2023",
        status: "Yet to Shipped",
    },
];



function OrderPage() {
    const [order, setOrder] = useState([]);
    const [open, setOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null)
    const [selectedSorting, setSelectedSorting] = useState();
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addressData, setAddressData] = useState()
    // console.log(addressData,"vvvvv")
    const [cancelData, setCancelData] = useState()

    const showModal = (newData) => {
        setCancelData(newData?.status)
        const updateData = order?.find(val => val.order_item.find(newItem => newItem?.id === newData?.id))
        if (updateData?.payment_status == "2") {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
        }
    };
    const handleOk = async (cancelData) => {
        try {
            const formData = new URLSearchParams();
            formData.append('id', cancelData?.id);
            formData.append('status', '5');
            formData.append('cancel_reason', 'Some issue');
            const response = await cancelOrder(formData, userDetail?.token)
            // console.log(response, "responseresponse")
            if (response?.status == '1') {
                fetchOrderDetail()
                setIsModalOpen(false);
                toast.success("Order item has been cancelled successfully")
            } else {
                console.error('PDF not found');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [singleOrderData, setSingleOrderData] = useState();

    // const orderId = 69;
    const companyId = 2228557;
    const showDrawer = async (data) => {
        const updateData = order?.find(val => val.order_item.find(newItem => newItem?.id === data?.id))
        setAddressData(updateData);
        setSingleOrderData(data)
        // try {
        //     const response = await trackingOrder(data?.id, companyId)
        //     // console.log('response', response);
        // } catch (error) {
        //     console.error(error);
        // }
        if (updateData?.payment_status == "2" || data?.status?.status == '5') {
            setIsModalOpenTrack(false);
        } else {
            setIsModalOpenTrack(true);
        }
    };
    const onClose = () => {
        setOpen(false);
    };


    const user = localStorage.getItem('user');
    const userDetail = JSON.parse(user);

    const [current, setCurrent] = useState(0);
    const onChange = (value) => {
        // console.log("onChange:", value);
        setCurrent(value);
    };
    const stepDescriptions = [
        "Wed, 1 lth Jan",
        "Wed, 1 lth Jan",
        "Wed, 1 lth Jan",
        "Expected by, Mon 16th",
    ];

    const handleSelectChange = (value) => {
        setSelectedSorting(value)
        // console.log('setSelectedSorting',e.target.value);
    }
    const [orderId, setOrderId] = useState()
    const fetchOrderDetail = async () => {
        let result = await authApi.getOrderDetail(userDetail?.token);
        // console.log(result,"result")
        if (result.status === '1') {
            setOrder(result?.data)
            setOrderId(result?.ORDERID)
        }
    }

    useEffect(() => {
        fetchOrderDetail()
    }, [])

    const Invoice = async (newId) => {
        // console.log(newId, 'NotifiedNotified')
        try {
            const formData = new URLSearchParams();
            formData.append('order_id', newId?.id);
            const response = await authApi.downloadInvoice(formData, userDetail?.token)
            if (response?.data) {
                const pdfLink = response.data;
                documentBlobReq('invoice', pdfLink)()
            } else {
                console.error('PDF not found');
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleSendData = (item, e) => {
        e.preventDefault()
        setDrawerData(item)

    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const start = () => {
        setLoading(true);
        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const filteredData = data.filter(item => {
        return Object.values(item).some(value =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        );
    });

    const [isModalOpenTrack, setIsModalOpenTrack] = useState(false);
    // const showModalTrack = () => {
    //     setIsModalOpenTrack(true);
    // };
    const handleOkTrack = () => {
        setIsModalOpenTrack(false);
    };
    const handleCanceltrack = () => {
        setIsModalOpenTrack(false);
    };

    const [statusChange, setStatusChange] = useState('open');
    const [statusOpen, setStatusOpen] = useState(true);

    const onChangeStatus = (e) => {
        if (e === "open") {
            setStatusChange("open")
        } else if (e === "close") {
            setStatusChange("close")
        }
    };

    // const items = [
    //     // {
    //     //     key: '1',
    //     //     label: (<div className="actionBtn" onClick={() => showDrawer(drawerData)}><img src={trackImg} className="trackImg" />Track Item</div>),
    //     // },
    //     // {
    //     //     key: '2',
    //     //     label: (<div className="actionBtn" onClick={() => navigate('/editEmployee')}>Return Item</div>),
    //     // },
    //     // {
    //     //     key: '3',
    //     //     label: (<div className="actionBtn" onClick={() => navigate('/editEmployee')}><img src={buyImg} className="buyImg" /> Buy again</div>),
    //     // },
    //     // {
    //     //     key: '4',
    //     //     label: (<div className="actionBtn deleteBtn" onClick={showModal}><img src={cancelImg} />Cancel Item</div>),
    //     // },
    // ];

    const columns = [
        {
            srNo: 1,
            title: (
                <div>
                    S.No.
                </div>
            ),
            dataIndex: 'sno',
            sorter: (a, b) => a.sno - b.sno,
        },
        {
            srNo: 2,
            title: (
                <div>
                    My Orders
                </div>
            ),
            className: "tableOrder_col",
            dataIndex: 'prodName',
            sorter: (a, b) => a.prodName - b.prodName,
        },
        {
            srNo: 3,
            title: (
                <div>
                    Quantity
                </div>
            ),
            dataIndex: 'quatityOrder',
            sorter: (a, b) => a.quatityOrder - b.quatityOrder,
        },
        // {
        //     srNo: 4,
        //     title: (
        //         <div>
        //             Order Date
        //         </div>
        //     ),
        //     dataIndex: 'date',
        //     sorter: (a, b) => a.date - b.date,
        // },
        {
            srNo: 5,
            title: (
                <div>
                    Status
                </div>
            ),
            dataIndex: 'status',
            render: (item, orderDetail) => {
                let statusMessage;
                switch (orderDetail?.status.status) {
                    case 0:
                        statusMessage = "Order Confirmed";
                        break;
                    case 1:
                        statusMessage = "Preparing";
                        break;
                    case 2:
                        statusMessage = "In Transit";
                        break;
                    case 3:
                        statusMessage = "Out for Delivery";
                        break;
                    case 4:
                        statusMessage = "Delivered";
                        break;
                    case 5:
                        statusMessage = "Order Cancelled";
                        break;
                    case 6:
                        statusMessage = "Rejected";
                }
                if (orderDetail?.item?.payment_status == "2") {
                    statusMessage = "order Failed";
                }
                return (
                    <div className={orderDetail?.item?.payment_status === 2 ? "orderSuccess" : 'statusTag'}>
                        {statusMessage}
                    </div>
                );
            },
            sorter: (a, b) => a.status - b.status,
        },
        {
            srNo: 6,
            title: "Action",
            dataIndex: '',
            render: (item) => {
                return (
                    <>
                        {/* {item?.status?.payment_status == "2" ? 
                    <div className="dropdown-fail">
                    <MoreOutlined />
                    </div>
                     :
                     <Dropdown
                        menu={{ items }}
                        placement="bottom"
                        arrow
                            >
                            <a onMouseOver={(e) => handleSendData(item, e)}>
                                <MoreOutlined />
                            </a>
                            onClick={() => showDrawer(item)}
                            onClick={showModalTrack}
                     </Dropdown>
                     } */}
                        <div className="action_buuton">
                            <button onClick={() => showDrawer(item)}><svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C24 44 39 32 39 19C39 10.7157 32.2843 4 24 4C15.7157 4 9 10.7157 9 19C9 32 24 44 24 44Z" fill="none" stroke="#333" strokeWidth="3" strokeLinejoin="round" /><path d="M24 25C27.3137 25 30 22.3137 30 19C30 15.6863 27.3137 13 24 13C20.6863 13 18 15.6863 18 19C18 22.3137 20.6863 25 24 25Z" fill="none" stroke="#333" strokeWidth="3" strokeLinejoin="round" /></svg>Track Order</button>
                            {item?.status?.status < 1 &&
                                <button onClick={() => showModal(item)}><svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 10V44H39V10H9Z" fill="none" stroke="#333" strokeWidth="3" strokeLinejoin="round" /><path d="M20 20V33" stroke="#333" strokeWidth="3" strokeLinecapp="round" strokeLinejoin="round" /><path d="M28 20V33" stroke="#333" strokeWidth="3" strokeLinecapp="round" strokeLinejoin="round" /><path d="M4 10H44" stroke="#333" strokeWidth="3" strokeLinecapp="round" strokeLinejoin="round" /><path d="M16 10L19.289 4H28.7771L32 10H16Z" fill="none" stroke="#333" strokeWidth="3" strokeLinejoin="round" /></svg>Cancel Order</button>}
                            <button><svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 8L6 14L12.9998 21" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /><path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>Return Product</button>
                            <button><svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 8L6 14L12.9998 21" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /><path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>Returned</button>
                        </div>
                    </>
                )
            }
        }
    ];

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: trashLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const hasSelected = selectedRowKeys.length > 0;

    const priceGrandTotal = order.map(order => {
        const totalPrice = order.order_item.reduce((total, item) => total + parseFloat(item.products.compare_price * item.quantity), 0);
        return { id: order.id, totalPrice };
    });

    const totalComparePrice = priceGrandTotal.find(val => val.id === addressData?.id)
    // console.log(totalComparePrice, "totalComparePrice")

    const documentBlobReq = async (doc_name, apiUrl) => {
        const fullUrl = window.location.href;
        const urlObject = new URL(fullUrl);
        const protocol = urlObject.protocol;
        const hostname = urlObject.host;
        const domain = `${protocol}//${hostname}`;
        const proxyUrl = `${domain}/proxy?url=${encodeURIComponent(apiUrl)}`;
        const response = await fetch(proxyUrl)
        const blobData = await response?.blob()
        const fileURL = window.URL.createObjectURL(blobData);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = doc_name;
        alink.click();
    }

    return (
        <>
            <div className="OrderReturn_pages">
                <div className="order_container">
                    <h1>My Orders</h1>
                    {/* <div className="button_fiterldrop">
                        <div className="drinkware_sort">
                            <span> Filter by order type:</span>
                            <Select
                                style={{
                                    width: 'auto',
                                    minWidth: 150,
                                }}
                                onChange={handleSelectChange}
                                // value={selectedSorting}
                                placeholder="Select"
                                options={[
                                    {
                                        value: 'all order',
                                        label: 'All Order',
                                    },
                                    {
                                        value: 'Not yet shipped',
                                        label: 'Not yet shipped',
                                    },
                                    {
                                        value: 'In Transit',
                                        label: 'In Transit',
                                    },
                                    {
                                        value: 'Cancelled',
                                        label: 'Cancelled',
                                    }]}>
                            </Select>
                        </div>
                        <div className="drinkware_sort">
                            <span> Filter by order date:</span>
                            <Select
                                style={{
                                    width: 'auto',
                                    minWidth: 150,
                                }}
                                onChange={handleSelectChange}
                                // value={selectedSorting}
                                placeholder="Select"
                                options={[
                                    {
                                        value: 'last30days',
                                        label: 'Last 30 days',
                                    },
                                    {
                                        value: 'last3months',
                                        label: 'Last 3 months',
                                    },
                                    {
                                        value: 'last6month',
                                        label: 'Last 6 months',
                                    },
                                    {
                                        value: 'last1year',
                                        label: 'Last 1 Year',
                                    }]}>
                            </Select>
                        </div>
                    </div> */}
                </div>
                <div className="order_multiCrds">
                    {order?.length > 0 ? (
                        order?.map((item) => (
                            item?.order_item?.length > 0 &&
                            <div className="orderReturn_Card">
                                <div className="outDelivery">
                                    <div className="trackbtn_withHed">
                                        <p>
                                            <img src={OutBox} alt="" />
                                        </p>
                                        <h4>{dayjs(item?.created_at).format("DD MMM, YYYY")}</h4>
                                    </div>
                                    {item?.payment_status !== 2 &&
                                        <div className="cancelProduct_btn">
                                            <button onClick={() => Invoice(item)}>Invoice</button>
                                        </div>}
                                </div>
                                <div className="order_tables">
                                    <Table
                                        bordered
                                        columns={columns}
                                        dataSource={item.order_item.map((orderDetail, index) => ({
                                            ...orderDetail,
                                            sno: index + 1,
                                            prodName: (
                                                <div className="employeeItem">
                                                    <Link to={`${Routes.ProductDetail}/${orderDetail?.products?.product_slug}`} state={{ ProductOd: orderDetail?.products?.id }}>
                                                        <img src={`https://api.growthgrids.com/storepedia/uploads/products/${orderDetail?.products?.productimage?.file_name}`} alt="Product" />
                                                        <div className="det">
                                                            <h4>{orderDetail?.products?.product_name}</h4>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ),
                                            quatityOrder: orderDetail?.quantity,
                                            date: dayjs(item?.created_at).format("DD MMM, YYYY"),
                                            status: orderDetail, item
                                        }))}
                                        pagination={{
                                            pageSize: pageSize,
                                        }}
                                        scroll={{ x: 'max-content' }}
                                    />
                                </div>
                            </div>
                        )))
                        :
                        (<div className='noDataCont'>
                            <img src={NoDataimg} alt='' />
                            <h5>No Order Found</h5>
                        </div>)
                    }
                </div>

                <Modal title="Track your order" open={isModalOpenTrack} onOk={handleOkTrack} onCancel={handleCanceltrack} footer={<button onClick={handleCanceltrack}>Go Back</button>} className="orderDetails_drawer" width={850}>
                    {/* <div className="drawer_heading">
                        <h2>Order ID: {addressData?.order_id !== null ? addressData?.order_id : 3354654654526}</h2>
                        <p>Order date: <span>{dayjs(addressData?.created_at).format("DD MMM, YYYY")}</span></p>
                    </div> */}

                    <div className="steperOrder">
                        <h2>Order ID: {`${singleOrderData?.id}-${orderId}-${addressData?.id}`}</h2>
                        <Steps
                            current={singleOrderData?.status?.status}
                            onChange={onChange}
                            items={[
                                {
                                    title: "Order Confirmed",
                                    description: `${dayjs(addressData?.created_at).format("DD MMM, YYYY")}`,
                                    status: singleOrderData?.status?.status >= 0 ? "finish" : "process"
                                },
                                {
                                    title: "In Transit",
                                    status: singleOrderData?.status?.status >= 2 ? "finish" : "process"
                                },
                                {
                                    title: "Out For Delivery",
                                    status: singleOrderData?.status?.status >= 3 ? "finish" : "process",
                                },
                                {
                                    title: "Delivered ",
                                    status: singleOrderData?.status?.status >= 4 ? "finish" : "process",
                                },
                            ]}
                        />
                        {/* <p>Your Order have been delivery</p>
                         <span>DHL Smartmail (tracking number : 9632255887412589963)</span> */}
                    </div>
                    <Divider />
                    <div className="drawer_items_details">
                        <h4>Your Order</h4>
                        {/* {addressData?.order_item?.map(newItem => ( */}
                        <div className="outBox_details">
                            <div className="orderDetails">
                                <Link to={`${Routes.ProductDetail}/${singleOrderData?.status?.products?.product_slug}`} state={{ ProductId: singleOrderData?.status?.products?.id }}>
                                    <div>
                                        <img src={`https://api.growthgrids.com/storepedia/uploads/products/${singleOrderData?.status?.products?.productimage?.file_name}`} alt="" />
                                    </div>
                                    <div className="details_para">
                                        <span>{singleOrderData?.status?.products?.product_name}</span>
                                        {/* <p>AWB NO : 85214789SJHSDYYSG785</p> */}
                                    </div>
                                </Link>
                            </div>
                            <div className="tracKBtn">
                                <p>₹ {singleOrderData?.status?.price * singleOrderData?.status?.quantity}</p>
                                <span>Qty: {singleOrderData?.status?.quantity}</span>
                            </div>
                        </div>
                        {/* ))} */}
                    </div>
                    <Divider />
                    <div className="drawer_paymentCont">
                        <Row gutter={32}>
                            <Col span={12}>
                                <div className="paymentAddress">
                                    <h2>Delivery at</h2>
                                    <span>
                                        {addressData?.name}
                                        <br />
                                        {addressData?.address}, {addressData?.land_mark},{addressData?.city}, {addressData?.state}, {addressData?.country} - {addressData?.zip_code}
                                        <br />
                                        +91-{addressData?.mobile_number}
                                    </span>
                                </div>
                            </Col>
                            {/* <Col span={12}>
                                <div className="orderSummery">
                                    <h2>Order Summary</h2>
                                    <div className="orderSummeryDetails">
                                        <h3>Bag Total</h3>
                                        <span>₹{totalComparePrice?.totalPrice}.00</span>
                                    </div>
                                    <div className="orderSummeryDetails">
                                        <h4>Shipping</h4>
                                        <p>{addressData?.shipping_amt !== "0.00" ? `+₹${addressData?.shipping_amt}` : "Free"}</p>
                                    </div>
                                    <div className="orderTotal">
                                        <h6>Total</h6>
                                        <span>₹{addressData?.paid_amount}</span>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                </Modal>

                <Modal
                    open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                    className='deletePopUp_model'
                    footer={
                        <div className='custom-modal-footer'>
                            {/* <Button key="cancel" onClick={handleCancel} className='cancel_Button'>
                                Cancel
                            </Button> */}
                            <Button key="delete" onClick={() => handleOk(cancelData)} className='deleteButton'>
                                Cancel Order
                            </Button>
                        </div>
                    }
                >
                    <div className='delete_popUpDesign'>
                        <p>Are you sure to cancel this order?</p>
                        <Lottie options={defaultOptions}
                            max-height={200}
                            width={250}
                        />

                    </div>
                </Modal>
            </div>
        </>
    );
}

export default OrderPage;