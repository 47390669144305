// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);

  if (error.response && error.response.data && error.response.data.message) {
    throw new Error(error.response.data.message);
  } else {
    throw new Error('An error occurred. Please try again later.');
  }
};

export const getAddressData = async (token) => {
  try {
    const response = await api.get('/user-address', {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const getGstInformation = async (token) => {
  try {
    const response = await api.get('/get-gst-information', {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
// get-gst-information