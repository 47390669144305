import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, useNavigate } from 'react-router-dom';
import { addContact } from '../../services/contactUs';
import { toast } from 'react-toastify';
import { Form, Input, Select, Button } from 'antd';
import { STRINGS } from '../../constants/Constant';

const { TextArea } = Input;

const ContactForm = () => {
    const [form] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState(null);
    const user = useSelector(state => state.user);
    const navigate = useNavigate();

    const onChange = (value) => setSelectedOption(value);

    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            
            if (!user?.id) {
                navigate(`${Routes.SignIn}`, { state: { from: "/contact-us" } });
            } else {
                const formData = new URLSearchParams();
                formData.append('query_id', values.query_id);
                formData.append('order_number', values.orderNumber);
                formData.append('email_id', values.email);

                const response = await addContact(formData, user.token);
                if (response?.status === "1") {
                    toast.success("Your query is submitted successfully! We will contact you soon");
                } else {
                    toast.error(response?.message);
                }
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            toast.error(error?.message);
        }
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div className='contact_detailsWrapper'>
            <h1>How can we assist you today?</h1>
            <h6>Have a question, concern, or feedback for us? We're here to help! Choose from the dropdown menu to get support tailored to your needs or log in to track your orders and access your account details</h6>
          
            <Form className='slect_help new-form' form={form} onFinish={onFinish}>
            <Form.Item
                    name="query_id"
                    label="Select Query"
                    className="selectedOption"
                    rules={[{ required: true, message: 'Please select your query!' }]}
                >
                <Select
                    placeholder="Please Select Your Query"
                    onChange={onChange}
                    filterOption={filterOption}
                    options={[
                        { value: 1, label: 'Track my order' },
                        { value: 2, label: 'Return or exchange query' },
                        { value: 3, label: 'Issue with received product' },
                        { value: 4, label: 'Payment and billing' },
                        { value: 5, label: 'Technical support' },
                        { value: 6, label: 'General query' },
                        { value: 7, label: 'Bulk Order query' },
                    ]}
                />
                </Form.Item>
               
                <Form.Item name="orderNumber" label="Order Number" className="selectedOption" rules={[{ required: true, message: 'Please enter your order number!'},{ pattern: /^\d{4}-[A-Z]{3}\d{3}-\d{3}$/, message: 'Please enter a valid order number!' }]}>
                    <Input placeholder="Enter Order Tracking Number" />
                </Form.Item>
                <Form.Item name="email" label="Email ID" className="selectedOption" rules={[{ required: true, message: 'Please enter your email ID!' }, { type: 'email', message: 'Please enter a valid email ID!' }]}>
                    <Input placeholder="Enter Email Id" />
                </Form.Item>
                <Form.Item name="inquiry" label="Your inquiry" className="selectedOption">
                    <TextArea placeholder="Write Your Inquiry" rows={6} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!user?.id}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
           
            {!user?.id && <div className='loginSpan'>Login to get help with your order ID or recent orders</div>}
        </div>
    );
};

export default ContactForm;
