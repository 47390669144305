import React from 'react'
import { Input, Form, Col, Row, Checkbox, Typography } from 'antd';
import visa from '../../assets/images/visa.png';
import mastercrd from '../../assets/images/mastercrd.png';
import onlineCrd from '../../assets/images/onliners.png';
import Rupay from '../../assets/images/rupay.png';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Link } = Typography;
function UpiForm() {
    return (
        <>
            <div className='upiForm_payment'>
                <h2>Credit/Debit Card</h2>
                <div className='we_accept_div'>
                    <span>We accept</span>
                    <div className='weAccept_img'>
                        <img src={visa} />
                        <img src={mastercrd} />
                        <img src={onlineCrd} />
                        <img src={Rupay} />
                    </div>
                </div>
                <div className='Upi_tab_form'>
                    <Form name="control-hooks" layout="vertical" autoComplete="off" >
                        <Row gutter={18}>
                            <Col span={24}>
                                <Form.Item name="CardNumber"
                                    rules={[{ required: true }]} >
                                    <Input className='input_signin' placeholder='Card Number' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item name="Expiry"
                                    rules={[{ required: true, }]}>
                                    <Input className='input_signin' placeholder='Expiry (MM/YY)' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item name="CVV"
                                    rules={[{ required: true, }]}>
                                    <Input className='input_signin' placeholder='CVV' />
                                    <InfoCircleOutlined />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='checkout_box_Upi'>
                            <Checkbox>Save this as per RBI Guidelines.<Link to=""> Know More</Link></Checkbox>
                        </div>
                        <div className='payBtn'>
                            <button>Pay 1000 </button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default UpiForm