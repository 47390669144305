import { createSlice } from '@reduxjs/toolkit';

const storedChip = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('chips')) || [] : [];

const filterSlice = createSlice({
  name: 'chips',
  initialState: storedChip,
  reducers: {
    addToChip: (state, action) => {
      return action.payload; // This replaces the entire state with the new payload
    },
  },
});

export const { addToChip } = filterSlice.actions;

export default filterSlice.reducer;
