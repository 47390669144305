import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../reducers/userSlice";
import { useNavigate } from "react-router-dom";
import Routes from "../../Routes/Routes";
import { getSingleOrderDetail } from "../../services/orders/orderDetail";
import { deleteCart } from "../../services/cart/addCart";
import { addToCart, clearCart } from "../../reducers/cartSlice";
import { Link } from "react-router-dom";
import { applyCoupon } from "../../reducers/couponSlice";
import { clearBuy } from "../../reducers/buySlice";

function Thankyou() {
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const buy = useSelector((state) => state.buy) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [newOrderId, setNewOrderId] = useState(0);
  const handleItemCLick = (from) => {
    if (from === "logout") {
      localStorage.removeItem("user");
      dispatch(clearUser());
      navigate(Routes.SignIn);
    } else {
      navigate(Routes.AccountProfile, { state: { from } });
    }
  };

  useEffect(() => {
    //  setNewOrderId(window.location.href.split('=')[1])

    // else{
    const fetchData = async () => {
      const formData = new URLSearchParams();
      formData.append("id", window.location.href.split("=")[1]);

      try {
        const response = await getSingleOrderDetail(formData, user.token);

        if (response.status === "1") {
          const { order_item, payment_status } = response.data || {};
          if (payment_status == "2") {
            navigate("/paymentFailed");
          } else {
            const buyIds = buy?.map((item) => item.id);
            if (buyIds.length > 0) {
              dispatch(clearBuy());
              localStorage.removeItem("buy");
            } else {
              const odrIds = order_item.map((id) => id.product_id);

              if (odrIds.length > 0) {
                const filteredCart = cart.filter((cartItem) =>
                  odrIds.includes(cartItem.id)
                );

                try {
                  const deleteOperations = filteredCart.map(async (product) => {
                    const formData = new URLSearchParams();
                    formData.append("id", product.id);

                    const responseDel = await deleteCart(formData);
                    const deleteCartStatus = responseDel.data.status;

                    if (deleteCartStatus === "1") {
                      return product.id; // Return the ID of the successfully deleted product
                    }
                  });

                  const deletedProductIds = await Promise.all(deleteOperations);

                  // Update local storage and state after all delete operations are completed
                  const updatedCart = cart.filter(
                    (item) => !deletedProductIds.includes(item.id)
                  );

                  if (updatedCart.length > 0) {
                    localStorage.setItem("cart", JSON.stringify(updatedCart));
                    dispatch(addToCart(updatedCart));
                    const couponnull = {};
                    dispatch(applyCoupon(couponnull));
                    localStorage.setItem("coupon", JSON.stringify(couponnull));
                  } else {
                    localStorage.removeItem("cart");
                    dispatch(clearCart());
                    const couponnull = {};
                    dispatch(applyCoupon(couponnull));
                    localStorage.setItem("coupon", JSON.stringify(couponnull));
                  }
                } catch (error) {
                  console.error("Deleting Error:", error);
                }
              }
            }
          }
        } else {
          const buyIds = buy?.map((item) => item.id);
          if (buyIds.length > 0) {
            dispatch(clearBuy());
            localStorage.removeItem("buy");
          }
          console.error("API returned an error:", response.error);
        }
      } catch (error) {
        console.error("Error fetching order detail:", error);
      }
    };
    fetchData();

    // }
  }, []);

  return (
    <>
      <div className="chcekout_thank_page">
        <h3>Order Confirmation</h3>
        <h1>Thank You!</h1>
        <p>
          For choosing Kairaus for your latest purchase! Your order has been
          successfully placed and is now in the queue for processing. We
          appreciate your trust in us to deliver quality products to your
          doorstep. Anticipate an upcoming email containing tracking details on{" "}
          <span>{user?.email}</span>, as we strive for prompt delivery to bring
          your new additions to your home swiftly. We appreciate your trust in
          us, and look forward to serving you again in the future. In the
          meantime, continue your shopping experience here.
        </p>
        <div className="trackOrder">
          <button onClick={() => handleItemCLick("orders")}>
            Track your Order
          </button>
          {/* <span>Read about Return Policy</span> */}
        </div>
      </div>
      <div className="payment_footer">
        {/* <Link to={Routes.Terms} state={{ from: "#returnCancel" }}><span>Refund policy  </span></Link>
                <div className='profile_dashed'>|</div> */}
        <Link to={Routes.Privacy}>
          <span>Privacy policy </span>
        </Link>
        <div className="profile_dashed">|</div>
        <Link to={Routes.TermCondition}>
          <span>Terms & Conditions</span>
        </Link>
      </div>
    </>
  );
}

export default Thankyou;
