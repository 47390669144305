import React, { useState, useEffect } from 'react';
import { HeartOutlined, CloseCircleOutlined, HeartFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addWishlist } from '../services/wishlist/addWishlist';
import Routes from '../Routes/Routes';
import { Tooltip } from 'antd';
import { STRINGS } from '../constants/Constant';
import { toast } from 'react-toastify';
import { addToFavId } from '../reducers/favIdSlice';
import { clearUser } from '../reducers/userSlice';

const Wishlist = ({ pId, path, mode, setFProducts, fProducts, is_wishlist }) => {
  // console.log(path)
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const favId = useSelector((state) => state.favId)||[];
  const [isFavorite, setIsFavorite] = useState(is_wishlist === 1);
  const [wishlistItems, setWishlistItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsFavorite(is_wishlist === 1);
  }, [is_wishlist]);

  const handleWishlistClick = async () => {
    if (user && user.id) {
      const existingItemIndex = favId.findIndex(item => item === pId);
      const updatedFavId = [...favId];
      
      // If pId exists in favId, remove it; otherwise, add it
      if (existingItemIndex !== -1) {
        updatedFavId.splice(existingItemIndex, 1);
      } else {
        updatedFavId.push(pId);
      }

      // Dispatch action to update favId state in Redux
      dispatch(addToFavId(updatedFavId));

      // Update local storage
      localStorage.setItem('favId', JSON.stringify(updatedFavId));
      try {
        const formData = new URLSearchParams();
        formData.append('product_id', pId);
        formData.append('user_id', user.id);

        const response = await addWishlist(formData, user.token);
        if (response.status === "1") {
          const toastMessage = response?.message;
          const existingToast = toast.isActive(pId);

          if (existingToast) {
            toast.update(pId, {
              render: toastMessage,
            });
          } else {
            toast.success(toastMessage, { toastId: pId });
          }

          setIsFavorite(!isFavorite);
          const updatedWishlistItems = isFavorite
            ? wishlistItems.filter(item => item !== pId)
            : [...wishlistItems, pId];
          setWishlistItems(updatedWishlistItems);

          if (fProducts && fProducts.length > 0) {
            let tempArr = fProducts.filter(item => item.product_id !== pId);
            setFProducts(tempArr);
          } else {
            console.error('fProducts is undefined or empty');
          }
        }
      } catch (error) {
        if (error.message === "Invalid token") {
          // Handle invalid token scenario
          localStorage.removeItem('user'); // Remove only the 'user' key from local storage
          dispatch(clearUser());
          navigate(Routes.SignIn, { state: { from: path } }); // Redirect to login page with state
        } else {
          console.error('Error adding to wishlist:', error);
          // Handle other errors gracefully
        }
      }
    } else {
      navigate(Routes.SignIn, { state: { from: path } });
    }
  };

  const iconMap = {
    CloseCircleOutlined: <CloseCircleOutlined onClick={handleWishlistClick} />,
    HeartOutlined: (
      <div className='wishlistIcoons' onClick={handleWishlistClick}>
        <Tooltip placement="topLeft" title={isFavorite ? STRINGS.REMOVE_FROM_WISHLIST : STRINGS.MOVE_TO_WISHLIST}>
          {isFavorite ? <HeartFilled /> : <HeartOutlined />}
        </Tooltip>
      </div>
    ),
  };

  return iconMap[mode] || null;
};

export default Wishlist;

