import React, { useState } from 'react';
import { Row, Col } from 'antd';
import silder1 from '../../assets/images/dining.png';
import silder2 from '../../assets/images/dining.png';
import silder3 from '../../assets/images/dining.png';
import silder4 from '../../assets/images/dining.png';
import VerticalSlider from './VerticalSlider';

const OurStory = () => {

    return (
        <div className='ourStoryContainer'>
            <div className='black_story'>
                <hr className='black_line' />
                <h1>
                    Our <br />
                    Story
                </h1>
                <hr className='black_line01' />
            </div>
            <div className='slider_animation mt-5'>
                <Row>
                    <Col span={12}>
                        <div className='slider_mission'>
                            <span>About</span>
                            <p>Creating world-class buildings that are attractive, beautiful, and durable.</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <VerticalSlider />
                    </Col>
                </Row>
            </div>
            <div className='kairaus_stroy'>
                <div className='slider_mission'>
                    <p>Kairaus Career Story</p>
                </div>
                <div className='slider_kairau'>
                    <Row>
                        <Col span={12}>
                            <p>Where does it come from?</p>
                            <h6>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</h6>
                        </Col>
                        <Col span={12}>
                            <img src={silder2} className='silder2' />
                            <img src={silder3} className='silder3' />
                            <img src={silder4} className='silder4' />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='story_mission'>
                <Row>
                    <Col span={12}>
                        <div className='stroy_image'>
                            <img src={silder1} alt="" />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='slider_mission'>
                            <span>Our Mission</span>
                            <p>We create a simple design that give a new look.Engage the expertise of our professionals to receive comprehensive tender submission and bidding support.</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='slider_login'>
                <Row>
                    <Col span={12}>
                        <div className='slider_mission'>
                            <p>Let's talk,<br />we response quickly</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='addres_slider'>
                            <div className='slider_contant'>
                                <span>Address</span>
                                <p>N 79-80 Adinath Nagar , JLN Marg Jaipur-302018, Raj , India</p>
                            </div>
                            <div className='slider_contant'>
                                <span>Phone Number</span>
                                <p>+91-8523698459</p>
                                <h4>contact@kairaus.com</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default OurStory;
