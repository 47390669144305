import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGstInformation } from '../../services/user/getUserAddress';
import NoDataimg from '../../assets/images/noData.png';

function GstInfo() {
    const { token } = useSelector((state) => state.user);
    const [gstList, setGstList] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGstInformation = async () => {
            try {
                const result = await getGstInformation(token);
                if (result.status === '1') {
                    setGstList(result.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        };
        fetchGstInformation();
    }, [token]);

    return (
        <div className='gst_infoContainer'>
            <div className='gst_headingBtn'>
                <h3>GST Information</h3>
                {/* <button>
                    <svg width='20' height='20' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M40 23V14L31 4H10C8.89543 4 8 4.89543 8 6V42C8 43.1046 8.89543 44 10 44H22'
                            stroke='#333'
                            strokeWidth='3'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M32 44L42 34L38 30L28 40V44H32Z'
                            fill='none'
                            stroke='#333'
                            strokeWidth='3'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M30 4V14H40'
                            stroke='#333'
                            strokeWidth='3'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>{' '}
                    Edit
                </button> */}
            </div>
            <div className='gst_addresssed'>
                {loading ? (
                    <div>Loading...</div>
                ) : gstList && gstList.length ? (
                    gstList.map((item) => (
                        <div className='gst_details' key={item.id}>
                            <div className='gst_number'>
                                <h3>GST Number </h3>
                                <span>{item.gst_number}</span>
                            </div>
                            <div className='gst_company'>
                                <h3>GST Company Name </h3>
                                <span>{item.company_name}</span>
                            </div>
                            <div className='gst_number'>
                                <h3>GST Address </h3>
                                <span> {item.company_address}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='noDataCont'>
                        <img src={NoDataimg} alt='' />
                        <h5>No Data Found</h5>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GstInfo;
