import axios from 'axios';
const apiConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
};
const api = axios.create(apiConfig);

const handleApiError = (error) => {
    console.errror('API Error:', error);
    throw error;
}

//  About Us API
export const getBlog = async () => {
    try {
        const response = await api.get('/get-blog');
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`API Error: ${response.statusText}`)
        }
    } catch (error) {
        return handleApiError(error);
    }
}
export const getAllBlog = async () => {
    try {
        const response = await api.get('/get-all-blog');
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`API Error: ${response.statusText}`)
        }
    } catch (error) {
        return handleApiError(error);
    }
}

export const getBlogDetails = async (slug)=>{
    try {
      const response = await api.post(`/get-blog-detail/${slug}`);
      if(response.status === 200){
        return response.data;
      }else{
        return 404;
      }
    }catch(error){
        return 404;
    }
}

