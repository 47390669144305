import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import dropSpinner from '../../assets/images/Drop_ring.gif'


// const LoadingSpinner = () => <img src={dropSpinner} style={{ width: '74px', height: '56px', borderRadius: '38px' }} />;


const CarouselCom = ({ sliderList, carouselSpinner }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="home_img_full">
      {/* {
        carouselSpinner ?
        (
          <div style={{display:'flex',justifyContent:'center'}}> <LoadingSpinner /> </div>
        )
         :
         ( */}
          <Carousel activeIndex={index} onSelect={handleSelect}>
          {sliderList && sliderList.data && sliderList.data.map((slide, idx) => (
          
            <Carousel.Item key={slide.id}>
                <Link to={slide.button_link || "/"}>
              <img src={`${sliderList.path}${slide.image}`} alt={`Slide ${slide.id} - ${slide.title} - ${slide.sub_title}`} />
              <Carousel.Caption>
                {/* {idx === 0 && <h1>{slide.title}</h1>} */}
                {/* <p>{slide.sub_title}</p> */}
                  {/* <button>Shop Now</button> */}
              </Carousel.Caption>
              </Link>
            </Carousel.Item>
           
          ))}
        </Carousel>
         {/* )
      } */}

    </section>
  );
};

export default CarouselCom;
