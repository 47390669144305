// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);

  if (error.response && error.response.data && error.response.data.message) {
    throw new Error(error.response.data.message);
  } else {
    throw new Error('An error occurred. Please try again later.');
  }
};

export const getWishList = async (token) => {
  try {
    const response = await api.get('/wishlist', {
      headers: {
        Authorization: token,
      },
    });
    if (response?.data?.message === "Invalid token") {
      throw new Error("Invalid token");
    }
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
