import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { RiseOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import deliveryAnimation from '../../assets/images/animationDelivery.json';

function CashDelivery({ handleCodData }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handlePlaceOrder = () => {
        handleCodData();
        handleOk();
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: deliveryAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className='cashDelivery_form'>
                <h2>Pay at your doorstep</h2>
                <div className='netBanking_warning'>
                    <RiseOutlined />
                    <span><b>Recent transactions</b> were made through cards. <br /></span>
                </div>
                <div className='payBtn'>
                    <td>
                        <button onClick={showModal}>Place Order</button>
                    </td>
                </div>
            </div>

            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                className='cashDelivery_model'
                footer={
                    <div className='custom-modal-footer'>
                        <Button key="cancel" onClick={handleCancel} className='cancel_Button'>
                            Cancel
                        </Button>
                        <Button key="proceed" onClick={handlePlaceOrder} className='deleteButton'>
                            Proceed
                        </Button>
                    </div>
                }>
                <div className='delete_popUpDesign'>
                    <Lottie options={defaultOptions}
                        max-height={100}
                        width={250}
                    />
                    <p>Order placed</p>
                    <small>Confirming cash-on-delivery ensures seamless processing of your order. Shall we proceed with this payment method?</small>
                </div>
            </Modal>

        </>
    )
}

export default CashDelivery