import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import { Input, Row, Col } from 'antd';
import search_icon from "../../assets/images/faqs/Frame 1.png";
import Accordion from 'react-bootstrap/Accordion';
import { getFaqCategory, getFaqList } from '../../services/faq';
import Routes from "../../Routes/Routes";
import faqImg from '../../assets/images/faqs/faqsheader.png';
import { Link } from 'react-router-dom';
import { getSearchContactUs } from '../../services/contactUs';
import { useSelector } from 'react-redux';

function Faqs() {

    const hasMounted = useRef(false);
    const [faq, setFaqData] = useState(null);
    const [faqList, setFaqListData] = useState(null);
    const user = useSelector(state => state.user);
    const fetchData = async () => {
        const formData = new URLSearchParams();   
              formData.append('type', '0');

        try {
            const faqResponse = await getFaqCategory(formData);
            const faqData = faqResponse;
            if (faqResponse.status === '1') {
                setFaqData(faqData);
            }
            const faqListResponse = await getFaqList(formData);
            const faqListData = faqListResponse;
            if (faqListResponse.status === '1') {
                setFaqListData(faqListData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };
    const [searchData, setSearchData] = useState()

    const search = (e)=>{
        setSearchData(e?.target?.value)
    }

    const handleSearch=async()=>{
        const formData = new URLSearchParams();   
        formData.append('search_for', '0');
        formData.append('search_data', searchData);
        try {
            const faqResponse = await getSearchContactUs(formData, user?.token);
            const faqData = faqResponse;
            if (faqResponse.status === '1') {
                setFaqListData(faqData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    useEffect(()=>{
    handleSearch()
    },[searchData])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (hasMounted.current) return;
        fetchData();
        hasMounted.current = true;
    }, []);

    return (
        <>
            <section className="section_one_faqs">
                <img src={faqImg} alt="#" />
                <div className="full_img_text">
                    <h1>Have Questions!</h1>
                </div>
            </section>
            <section className='section_two_faqs'>
                <Container fluid="md">
                    <Row>
                        <Col sm={7}>
                            <div className='faqs_right_side'>
                                <h4>How can we Help!</h4>
                                <ul>
                                {faq&&faq.data.map((item,index) => (
                                <li key={index}>
                                    <a rel="canonical" href={`#link${item.id}`} >{item.name} </a>
                                </li>
                                ))}
                                    
                                </ul>
                            </div>
                        </Col>
                        <Col sm={17}>
                            <div className='faqs_left_side'>
                                <h4>FAQ</h4>
                                <div className='searchbar_faqs'>
                                    <img src={search_icon} alt="" />
                                    <Input placeholder="Search FAQ" onChange={(e)=>search(e)} />
                                </div>
                                {faqList && faqList.data.map((list, index) => (
                                    <section className='faqs_accor_main_sec1' id={`link${list.id}`} key={index}>
                                        <h4>{list.name}</h4>
                                        <Accordion>
                                            {list.faq && list.faq.map((val, index) => (
                                                <Accordion.Item eventKey={val.id} key={index}>
                                                    <Accordion.Header>{val.question}?</Accordion.Header>
                                                    <Accordion.Body>
                                                        {val.answer}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </section>
                                ))}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <section className='faqs_footer_heading' id='link4'>
                <div className='faqs_footer_main content'>
                    <p className='faq_foot_p'>Need Help!</p>
                    <h3>Get in Touch</h3>
                    <div className='foot_faq_btn'>
                        <Link to={Routes.ContactUs}> <button>Contact Us!</button></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs