import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  catData: '',
  isLoading: true,
};

const categorySlice = createSlice({
  name: 'cat',
  initialState,
  reducers: {
    addToCategory: (state, action) => {
      state.catData = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { addToCategory, setLoading } = categorySlice.actions;

export default categorySlice.reducer;
