import React, { useEffect, useState } from 'react';
import { LiaGreaterThanSolid } from "react-icons/lia";
import compareImg from '../../assets/images/product1.png';
// import compareImg2 from '../../assets/images/product2.png';
// import compareImg3 from '../../assets/images/product3.png';
// import compareImg4 from '../../assets/images/product4.png';
import { Row, Col, Rate } from 'antd';
import { compareProduct } from '../../services/assurance';
import Wishlist from '../../function/Wishlist';
import AddToCart from '../../function/AddToCart.js';
import { addToCompare } from '../../reducers/compareSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Routes from '../../Routes/Routes.js';
import { Link, useNavigate } from 'react-router-dom';

const CompareDetail = () => {
    const comp = useSelector(state => state.comp);
    const [path, setPath] = useState(null);
    const [compareList, setCompareList] = useState(null);
    const [is_wishlistt, setIs_wishlist] = useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const categoryName = JSON.parse(localStorage?.getItem("compareData"))
    // console.log(categoryName,"categoryNamecategoryName")

    let compareData = JSON.parse(localStorage.getItem('compareData')) || {};
    const fetchCompareProduct = async (productIds) => {
        try {
            const formData = new URLSearchParams();
            formData.append('product_id', JSON.stringify(productIds));

            const response = await compareProduct(formData);
            // console.log('responseresponseresponseresponse',response);
            if (response.status === "1") {

                if (response.data.some(product => product.id === compareData.id)) {
                    // Find the index of the product that matches compareData.id
                    const matchedProductIndex = response.data.findIndex(product => product.id === compareData.id);
                    // Move the matched product to the first index
                    if (matchedProductIndex !== -1) {
                        const matchedProduct = response.data.splice(matchedProductIndex, 1)[0];
                        response.data.unshift(matchedProduct);
                    }
                }



                setIs_wishlist(response?.is_wishlist);
                setPath(response.path);
                setCompareList(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCloseIcon = (id) => {
        let updatedComp = comp.filter(item => item.id !== id);
        // console.log('updatedCompupdatedComp',updatedComp);
        if (compareData?.id !== id) {
            localStorage.setItem('comp', JSON.stringify(updatedComp));
            dispatch(addToCompare(updatedComp));
            fetchCompareProduct(updatedComp.map(item => item.id));
        }
    };

    useEffect(() => {
        const compareData = JSON.parse(localStorage.getItem('compareData')) || {};
        const productIds = [compareData?.id, ...comp.map(item => item.id)];
        fetchCompareProduct(productIds);
    }, [comp]);
    // const handleItem = (slug, from, route, dataSlug, menuSlug, categorySlug) => {
    //     navigate(`${Routes.ProductDetail}/${slug}`, { state: { dataSlug, route, from, menuSlug, categorySlug } });
    // }

    const colSpan = compareList ? 24 / compareList.length : 24;

    return (
        <div className='compare_container'>
            <div className="path_about_drinkware">
                <p>  <Link to="/">Home</Link>
                    <LiaGreaterThanSolid />
                    <Link to={`${Routes?.ProductCategory}/${categoryName?.catSlug}`} state={{ ProductId: categoryName?.id }}>
                        <h6>{categoryName?.category_name}</h6>
                    </Link>
                    {/* <span> Compare</span> */}
                </p>
            </div>
            <h1>Compare Product</h1>
            <div className='products_contant_cards'>
                <Row gutter={32}>
                    {compareList?.map((comp, index) => (
                        <Col key={index} span={colSpan}>
                            <div id="myHeader" className={`${isSticky ? "newProduct_compareCard" : "product_compareCard"} ${index === 0 ? "extraClass" : ""}`}>
                                <Link key={index} to={`${Routes.ProductDetail}/${comp.product_slug}`} state={{ ProductId: comp?.id }} className='anchor_image'>
                                    <img src={`${path}/${comp.productimages[0]?.file_name}`} alt={comp.product_name} onError={(e) => { e.target.onerror = null; e.target.src = compareImg }} />
                                </Link>
                                <Link key={index} to={`${Routes.ProductDetail}/${comp.product_slug}`} state={{ ProductId: comp?.id }} className='anchore_link'>
                                    <h6>{comp.product_name}</h6>
                                    <div className="price">
                                        <p>₹{comp.price}</p>
                                        <span>₹{comp.compare_price}</span>
                                        {comp?.discount > 0 && (
                                            <div className="off_chips_compare">
                                                <p>{comp.discount}% off</p>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                                {index !== 0 && (
                                    <div className='close_icon' onClick={() => handleCloseIcon(comp.id)}>
                                        <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8L40 40" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /><path d="M8 40L40 8" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </div>
                                )}
                                {/* <Wishlist mode='HeartOutlined' /> */}
                                <div className="wishlist">
                                    {
                                        is_wishlistt !== null &&
                                        <Wishlist
                                            is_wishlist={is_wishlistt}
                                            pId={comp?.id}
                                            path={`${Routes.ProductDetail}/${comp?.product_slug}`}
                                            mode='HeartOutlined'
                                        />
                                    }
                                </div>
                                <AddToCart productList={comp} imgPath={path} />
                                {/* <div className='mt-2'> <Rate allowHalf defaultValue={3.5} /> <span>(155)</span></div> */}
                            </div>
                        </Col>
                    ))}
                </Row>
                {
                    compareList?.length > 0 &&
                    <div className='product_keyDeatil'>
                        <h6>Key Details</h6>
                        <div className='details_keys'>
                            {compareList?.map((comp, index) => (
                                <ul>
                                    {comp?.producttypes?.type_name ? <li>{comp?.producttypes?.type_name}</li> : null}
                                    {comp?.productmaterials?.material_name ? <li>{comp?.productmaterials?.material_name}</li> : null}
                                    {comp.Feature ? <li>{comp.Feature}</li> : null}
                                    {comp.pack_contain ? <li>Set of {comp.pack_contain}</li> : null}
                                    {/* {comp.description ? <li><p dangerouslySetInnerHTML={{ __html: comp.description }}></p></li> : null} */}
                                    {/* {comp.additional_description && <li><p dangerouslySetInnerHTML={{ __html: comp.additional_description }}></p></li>} */}
                                </ul>

                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default CompareDetail