// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const updateUserAddress = async (data, token) => {
    try {
      const response = await api.put('/edit-user-address', data, {
        headers: {
            Authorization: token,
          },
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  };


  // export const deleteUserAddress = async (data, token) => {
  //   try {
  //     const response = await api.post('/delete-user-address', data, {
  //       headers: {
  //           Authorization: token,
  //         },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return handleApiError(error);
  //   }
  // };

 export const deleteUserAddress = async (data) => {
    const user = localStorage.getItem('user');
    const tokenLocal = JSON.parse(user);
    const newToken = tokenLocal.token;

    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": newToken
    }

    try {
        const response = await api.delete('/delete-user-address', { data: data, headers: headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}