// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

  export const viewProduct = async (token) => {
    // try {
      const response = await api.get('/get-user-product-view', {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return response;
      }
    // } catch (error) {
    //   if (error?.response?.data?.message === "Invalid token") {
    //     throw new Error(`API Error: ${error.response.statusText || error.message}`);
    //   }
    
    //   return handleApiError(error);
    // }
  };
