// services/filter/category.js
import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);
export const CategoryList = async (data) => {
  try {
    const response = await api.post('/category-list',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};
export const getFilterProductList = async (data) => {
  try {
    const response = await api.post('/get-filter-product-list',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};

export const MaterialList = async (data) => {
  try {
    const response = await api.post('/material-list',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};
export const TypeList = async (data) => {
  try {
    const response = await api.post('/product-type-list',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};

export const DiscountList = async (data) => {
  try {
    const response = await api.post('/product-discount-list',data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};