import React from 'react';
import { Drawer, Button, Collapse, Checkbox, Slider, Switch } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Accordion from 'react-bootstrap/Accordion';

const { Panel } = Collapse;

const FilterDrawer = ({
    closeDrawer,
    isDrawerVisible,
    items,
    toggleAccordion,
    isActiveIcon,
    colorList,
    onChanged,
    marks,
    handlePriceChange,
    priceRange,
    toggleAccordionSec,
    isActiveSec,
    materialList,
    itemsMaterial,
    checkedColors,
    colorPath,
    maxPrice,
    discountItem,
    discountList,
    stockToggle,
    removeOutOfStocks

}) => (
    <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        open={isDrawerVisible}
        width={380}
        height={100}
        destroyOnClose={true}
        maskClosable={true}
        drawerStyle={{ zIndex: 1000 }}
        className='filter_drawer_open'
        footer={
            <div className='filter_phonebtn'>
                <Button className='filter_phoneApply'>
                    Apply
                </Button>
                <Button>Cancel</Button>
            </div>
        }
    >
        <div className="drinkware_acc_carousel_section">
            <div className="accordian_section">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Categories :</Accordion.Header>
                        <Accordion.Body>
                            <div className="titles_acd">
                                <Collapse items={items}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} />
                            </div>
                        </Accordion.Body>
                        <Accordion.Body className='accoddian_titles'>
                            <div className="titles_acd">
                                <Collapse
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                                >
                                    <Panel {...itemsMaterial(materialList)} />
                                </Collapse>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={toggleAccordion}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            Colors :
                            {isActiveIcon ? <MinusOutlined /> : <PlusOutlined />}
                        </span> </Accordion.Header>
                        <Accordion.Body>
                            {colorList !== null ? (
                                <div className='color_imageCat'>
                                    <Checkbox.Group value={checkedColors} >
                                        {colorList?.map((color) => (
                                            <Checkbox checked={checkedColors?.includes(color.id)} onChange={() => onChanged(color)} key={color?.id} value={color?.id}> <img src={`${colorPath}${color?.image}`} alt={color?.color} /><p>{color?.color}</p></Checkbox>

                                        ))}
                                    </Checkbox.Group>
                                </div>
                            ) : (
                                <div>Loading...</div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={toggleAccordionSec}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            Price :
                            {isActiveSec ? <MinusOutlined /> : <PlusOutlined />}
                        </span> </Accordion.Header>
                        <Accordion.Body>
                            <div className='accordion_range'>
                                <Slider
                                    range
                                    marks={marks}
                                    max={maxPrice}
                                    onChange={handlePriceChange}
                                    value={priceRange}
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="titles_acd">
                    <Collapse
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    >
                        <Panel {...discountItem(discountList)} />
                    </Collapse>
                </div>
                <div className='out_stock_boxed'>
                    <span>Remove Out of Stock Items</span>
                    <Switch checked={stockToggle} onChange={removeOutOfStocks} />
                </div>
            </div>
        </div>
    </Drawer>
);

export default FilterDrawer;
