import { createSlice } from '@reduxjs/toolkit';

const CompDataToStore = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('comp')) || [] : [];

const compareSlice = createSlice({
  name: 'comp',
  initialState: CompDataToStore,
  reducers: {
    addToCompare: (state, action) => {
        return action.payload; // This replaces the entire state with the new payload
    },
    // Example selector to get total count
    clearCompare: (state, action) => {
      return [];
    },
  },
});

export const { addToCompare, clearCompare } = compareSlice.actions;


export default compareSlice.reducer;
