import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
};
const api = axios.create(apiConfig);

// Create a separate function for handling API errors
const handleApiError = (error) => {
  console.error('API Error:', error);
  throw error;
};

// Home Slider List
export const getRewardsPoints = async (token) => {
  try {
    const response = await api.get('/get-reward-point',{
        headers: {
          Authorization: token,
        },
      });
      if (response?.data?.message === "Invalid token") {
        throw new Error("Invalid token");
      }
      return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

