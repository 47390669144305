import { createSlice } from '@reduxjs/toolkit';

const storedFavId = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('favId')) || [] : [];

const favIdSlice = createSlice({
  name: 'favId',
  initialState: storedFavId,
  reducers: {
    addToFavId: (state, action) => {
      return action.payload; // This replaces the entire state with the new payload
    
    },
  },
});

export const { addToFavId} = favIdSlice.actions;

export default favIdSlice.reducer;
