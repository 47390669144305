import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
};
const api = axios.create(apiConfig);

// Create a separate function for handling API errors
const handleApiError = (error) => {
  console.error('API Error:', error);
  throw error;
};

// Home Slider List
export const getCustomerSupport= async ()=>{
    try {
      const response = await api.get('/customer-support');
        return response.data;
    }catch(error){
      return handleApiError(error);
    }
  }

  export const comboProduct = async (data) => {
    try {
      const response = await api.post('/get-combo-product',data);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`API Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching menu data:', error);
      throw error; // Re-throw the error to allow the calling code to handle it
    }
  };
  export const compareProduct = async (data) => {
    try {
      const response = await api.post('/get-compare-product',data);
      return response.data;
    } catch (error) {
      console.error('Error fetching menu data:', error);
      throw error; // Re-throw the error to allow the calling code to handle it
    }
  };