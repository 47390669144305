import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { STRINGS } from '../constants/Constant';
import { addToCart } from '../reducers/cartSlice';
import { addCart } from '../services/cart/addCart';
import { useNavigate } from 'react-router-dom';
import Routes from '../Routes/Routes';
import { clearUser } from '../reducers/userSlice';
import { notifyProduct } from '../services/product';


const AddToCart = ({ productList,productCount, imgPath, myClass, applied,appliedPrice, productCouponId,routeName,slug}) => {
    // console.log(productList,'productListproductList')
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const [updateNotify, setUpdateNotify] = useState(0)

    const calculateFilteredCartCount = () => {
        const filteredProduct = cart.find(product => product.id === productList?.id);
        const count = productCount !== undefined ? productCount-1 : 0;
        const totalQuantity = filteredProduct ? filteredProduct.count + count : 0;
        return Math.min(totalQuantity, productList?.stock_quantity ?? 0);
    };

    const isOutOfStock = productList?.stock_quantity === 0 || productList?.stock_quantity <= calculateFilteredCartCount();

    const addCartItemToDB = async (product) => {
            const response =await addCart(product, user.token);
            if (response === "Unauthorized") {
                localStorage.removeItem('user');
                dispatch(clearUser());
                navigate(Routes.SignIn);
            }
    };

    const handleAddToCartWhenNoString = () => {
        // console.log('productList',productList);
        productCount = productCount !== undefined && productCount !== null ? productCount : 1;
        // const productCount = 1;
        const { id, product_name, product_slug, is_wishlist, compare_price,usd_compare_price,usd_price, weight, unit, stock_quantity, shipping_amount_type, shipping_charge } = productList;
        const image = `${imgPath}${productList?.productimages?.[0]?.file_name_120_x_120}`;
        const price = applied ? appliedPrice : productList?.price;
        const newItem = { id, product_name, product_slug, is_wishlist, price,usd_price, image,shipping_amount_type,shipping_charge, count: productCount, p_variant_id: 0, compare_price,usd_compare_price, weight, unit, stock_quantity };

        const productForDB = {
            product_id: newItem.id,
            product_variant_id: newItem.p_variant_id,
            quantity: newItem.count,
            amount: newItem.price,
        };

        const existingItemIndex = cart.findIndex(item => item.id === newItem.id);

        let updatedCart;
        if (existingItemIndex === -1) {
            updatedCart = Object.isExtensible(cart) ? [...cart, newItem] : [...cart, newItem];
        } else {
            updatedCart = [...cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + productCount,
            };
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart));
        dispatch(addToCart(updatedCart));
        toast.success(STRINGS.ADDED_TO_CART_SUCCESSFULLY);

        if (user.token) {
            addCartItemToDB([productForDB]);
        }
    };

    const notifyMe = async(pId)=>{
        if(!user.id){
            slug=slug?`/${slug}`:'';
            const from = `${routeName}${slug}`; // Corrected the template literal syntax
            // alert(from);
            navigate(Routes?.SignIn, { state: { from } });
        }
        try {
            const formData = new URLSearchParams();
            formData.append('product_id', pId);
    
            const response = await notifyProduct(formData, user.token);
            if (response.status === "1") {
              toast.success(STRINGS.NOTIFY_ME,{toastId:productList?.id})
              setUpdateNotify(1)
            }
          } catch (error) {
            console.error('Error adding to wishlist:', error);
          }
       
    }

    return (
        <div className='addCart_Btn'>
            {isOutOfStock ? (
                <button onClick={()=>notifyMe(productList?.id)} disabled={updateNotify || productList?.is_notify == '1'}>{updateNotify || productList?.is_notify == '1' ? "Notified": "Notify Me"}</button>
            ) : (
                <button
                    className={myClass}
                    onClick={handleAddToCartWhenNoString}
                >
                    {STRINGS.ADD_TO_CART}
                </button>
            )}
        </div>
    );
};

export default AddToCart;
