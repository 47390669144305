import React, { useEffect, useState } from "react";
import { getProductCoupon } from "../services/coupon/Coupon";
import { updateCartItem } from "../reducers/cartSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { applyCoupon } from "../reducers/couponSlice";

const ProductCoupon = ({ productDetails, appliedPrice, setAppliedPrice, applied, setApplied }) => {
    // alert(productDetails?.id);
    const [productCouponList, setProductCouponList] = useState([]);
    const [error, setError] = useState(null);
    const [appliedCoupons, setAppliedCoupons] = useState({});
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const {id} = useSelector(state => state.user);
    //
    useEffect(() => {
        if(productDetails){
        const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];
        if(productCouponId[0]?.userId===id){
            if (Array.isArray(productCouponId) && productCouponId.length > 0) {
                setApplied(true);
                const newPrices = productCouponId
                    .filter(item => item.productId === productDetails?.id)
                    .map(item => item.newPrice);
                    setAppliedPrice(newPrices);
                    // console.log('newPricesnewPricesnewPrices',newPrices);
                    const appliedCouponsFromStorage = productCouponId.reduce((acc, item) => {
                        if (item.productId === productDetails?.id) {
                            acc[item.couponId] = true;
                        }
                        return acc;
                    }, {});
                    setAppliedCoupons(appliedCouponsFromStorage);
            } else {
                setApplied(false); // Assuming you also want to set 'applied' to false if there are no items
                setAppliedPrice([]); // Reset applied prices if there are no items
            }

        }else{
            localStorage.removeItem('productCoupon');
        }
        
    }
    }, [cart,productDetails]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formData = new URLSearchParams();
                formData.append('product_id', productDetails.id);
                const response = await getProductCoupon(formData);
                if (response.status === '1') {
                    setProductCouponList(response.data);
                    setError(null);
                }
            } catch (error) {
                setError('Error fetching data');
            }
        };

        fetchData();
    }, [productDetails]);
    const handleCopyClick = (couponData) => {
        if (productDetails?.price < couponData.min_amount) {
            toast.error(`Minimum order amount to apply this coupon is ₹${couponData.min_amount}`);
            return;
        }
        const cartCouponDetails = JSON.parse(localStorage.getItem('coupon')) || {};
        // console.log('cartCouponDetailscartCouponDetails',cartCouponDetails);
        const FirstOrderCouponDetails = JSON.parse(localStorage.getItem('FirstOrderCouponDetails')) || {};
        const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];
        // Increment productCouponCount if cartCouponDetails.id exists, else use the current productCouponId length
        const productCouponCount = cartCouponDetails.id ? productCouponId.length + 1 : productCouponId.length;
        console.log(productCouponCount,"productCouponCount")
        const isCouponApplied = productCouponId.some(item => item.couponId === couponData.id && item.productId === productDetails.id);
        const maxCouponsAllowed = 2;

        if (FirstOrderCouponDetails.status === "1") {
            if (isCouponApplied) {
                removeCoupon(couponData, productCouponId);
                return;
            }
            // applyCoupon(couponData, productCouponId, true);
            const newPrice = (productDetails.price * couponData.type_val) / 100;
            setAppliedPrice(productDetails.price - newPrice);

            const newIds = {
                couponId: couponData.id,
                productId: productDetails.id,
                newPrice: productDetails.price - newPrice,
                oldPrice: productDetails.price,
                userId:id,
            };
            const storeUpdatePrice = {
                productId: productDetails.id,
                newPrice: productDetails.price - newPrice,
                discountApply: true
            };

            setApplied(!isCouponApplied);
            localStorage.setItem('productCoupon', JSON.stringify([newIds]));
            dispatch(updateCartItem(storeUpdatePrice));
            if(cartCouponDetails?.id){
                const couponnull = {};
                dispatch(applyCoupon(couponnull));  
                localStorage.removeItem('coupon');
            }
        } else {
            if (productCouponCount < maxCouponsAllowed) {
                applyCouponLogic(couponData, productCouponId, !isCouponApplied);
            } else {
                // if (window.confirm("Only two coupons can be applied at a time. Are you sure you want to remove a coupon?")) {
                //     removeCoupon(couponData, productCouponId);
                // }
                if (isCouponApplied) {
                    removeCoupon(couponData, productCouponId);
                } else {
                    if (window.confirm("Only two coupons can be applied at a time. Are you sure you want to remove a coupon?")) {
                        removeCoupon(couponData, productCouponId);
                        applyCouponLogic(couponData, productCouponId, true); // Apply new coupon after removing
                    }
                }
            }
        }
    };
    
    const applyCouponLogic = (couponData, productCouponId, apply) => {
        const newPrice = apply ? (productDetails.price * couponData.type_val) / 100 : productDetails.price;
        const existingCouponIndex = productCouponId.findIndex(item => item.productId === productDetails.id);
        if (existingCouponIndex !== -1) {
            productCouponId.splice(existingCouponIndex, 1);
        }
        const updatedProductCouponId = apply ?
            [...productCouponId, { couponId: couponData.id, productId: productDetails.id, newPrice: productDetails.price - newPrice, oldPrice: productDetails.price,userId:id}] :
            productCouponId.filter(item => !(item.couponId === couponData.id && item.productId === productDetails.id));

        setAppliedPrice(productDetails.price - newPrice);
        // setApplied(apply); // Toggle applied state
        const updatedAppliedCoupons = {};
        if (apply) {
            updatedAppliedCoupons[couponData.id] = true;
        }
        setAppliedCoupons(updatedAppliedCoupons);

        localStorage.setItem('productCoupon', JSON.stringify(updatedProductCouponId));
        dispatch(applyCoupon(updatedProductCouponId));
        
        const storeUpdatePrice = {
            productId: productDetails.id,
            newPrice: productDetails.price - newPrice,
            discountApply: apply
        };
        
        dispatch(updateCartItem(storeUpdatePrice));
    };

    const removeCoupon = (couponData, productCouponId) => {
        const updatedProductCouponId = productCouponId?.filter(item => !(item.couponId === couponData.id && item.productId === productDetails.id));
        localStorage.setItem('productCoupon', JSON.stringify(updatedProductCouponId));
        setAppliedPrice(productDetails.price);
        // setApplied(false); // Set applied state to false
        const updatedAppliedCoupons = {};
        setAppliedCoupons(updatedAppliedCoupons);

        const storeUpdatePrice = {
            productId: productDetails.id,
            newPrice: productDetails.price,
            discountApply: false
        };

        dispatch(updateCartItem(storeUpdatePrice));
    };

    const renderCouponCode = (item) => {
        if (appliedCoupons[item.id]) {
            return <p>COUPON APPLIED!</p>;
        } else {
            return item.coupon_code;
        }
    };
    return (
        <>
            {productCouponList?.length > 0 && (
                <div className='coupons_containers'>
                    <h4>Best offers for you!</h4>
                    {error && <div>Error: {error}</div>}
                    <div className='product_best_offer_row'>
                        {productCouponList?.map(item => (
                            <div className='product_best_offer_item'>
                                <div className='product_best_offer_info'>
                                    <strong>{item?.coupon_desc}</strong>
                                </div>
                                <div className='product_best_offer_code'>
                                    <span className='code_info'>Use Code:</span>
                                    <div className={appliedCoupons[item.id] ? 'offer_code appliedOffer' : 'offer_code'}>
                                        <span className='copy_code' onClick={() => handleCopyClick(item)}>
                                            <div className='applied_span'>
                                                {renderCouponCode(item)}
                                            </div>
                                        </span>
                                    </div>
                                    {appliedCoupons[item.id]  &&
                                        <div className='applied_couponsSpan'>
                                            <h6><span>After applying the coupon, Your New Price :</span> ₹{appliedPrice}  </h6>
                                        </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
    export default ProductCoupon;
