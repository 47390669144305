import React from 'react';
import rewardsImg from '../../assets/images/blog image/blog_header_img1.png';


function Rewards() {
  return (
    <div className='rewards_background'>
      <div className='rewards_banner'>
        <img src={rewardsImg} />
        <div className='rewards_bannerSpan'>
          <h6>Say hello to...</h6>
          <h1>Rewards Zone</h1>
          <span>An irresistible loyalty program from Kairaus!</span>
          <h4>WIN REWARDS EVERY TIME YOU SHOP.</h4>
          <button>Join Kairaus</button>
        </div>
      </div>
      <div className='rewards_bannerPpoints'>
        <h2>How It Works?</h2>
        <span>Earn a flat 1,000 INR coupon for every 500 points you collect!</span>
        <div className='rewards_pointscards'>
          <div className='rewards_cards'>
            <h4>+40</h4>
            <span>When you join Kairaus's rewards zone</span>
          </div>
          <div className='rewards_cards'>
            <h4>+25</h4>
            <span>When you create your account</span>
          </div>
          <div className='rewards_cards'>
            <h4>+30</h4>
            <span>When you purchase for the first time</span>
          </div>
          <div className='rewards_cards'>
            <h4>+50</h4>
            <span>When you refer a friend</span>
          </div>
          <div className='rewards_cards'>
            <h4>+60</h4>
            <span>On your anniversary and birthday</span>
          </div>
        </div>
      </div>
      <div className='rewards_memeber'>
        <p>1 Rewards Point = ₹ 2/-</p>
        <h2>How to Become Our Rewards Zone Member?</h2>
        <span>Get into our Silver Zone with an annual spend of ₹4,000 to ₹50,000. Elevate to the Gold Zone with purchases between ₹50,001 and ₹100,000 and enjoy enhanced privileges. Surpass ₹100,001 in annual purchases to become a distinguished Platinum Zone Member and access our top-tier benefits.</span>
      </div>
      <div className='Benefit_tiers'>
        <h2>Membership Benefits</h2>
        <div className='benefit_table'>
          <table>
            <tr>
              <th>SILVER</th>
              <th>GOLD</th>
              <th>PLATINUM</th>
            </tr>
            <tr>
              <td>
                <div className='points_table'>
                  <p>Reward Points</p>
                  <span>Earn 2 reward points for every ₹100 spent</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Reward Points</p>
                  <span>Earn 4 reward points for every ₹100 spent</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Reward Points</p>
                  <span>Earn 6 reward points for every ₹100 spent</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='points_table'>
                  <p>Birthday Bonus</p>
                  <span>Celebrate your special day with us! Earn an additional 2 Reward Points for every purchase made on your birthday.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Birthday Bonus</p>
                  <span>Celebrate your special day with us! Earn an additional 30 Reward Points for every purchase made on your birthday</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Birthday Bonus</p>
                  <span>Celebrate your special day with us! Earn an additional 60 Reward Points for every purchase made on your birthday.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='points_table'>
                  <p>Exclusive Sale Sneak Peek</p>
                  <span>Get front-row access to Kairaus's much-anticipated Annual Sale. Be the first to grab the best deals!</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Exclusive Sale Sneak Peek</p>
                  <span>Get front-row access to Kairaus's much-anticipated Annual Sale. Be the first to grab the best deals!</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Exclusive Sale Sneak Peek</p>
                  <span>Get front-row access to Kairaus's much-anticipated Annual Sale. Be the first to grab the best deals!</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='points_table'>
                  <p>Premier Collection Access</p>
                  <span>Experience our newest collections before anyone else. Enjoy early access to our latest offerings.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Premier Collection Access</p>
                  <span>Experience our newest collections before anyone else. Enjoy early access to our latest offerings.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Premier Collection Access</p>
                  <span>Experience our newest collections before anyone else. Enjoy early access to our latest offerings.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className='points_table'>
                  <p>Boost Your Benefits</p>
                  <span>Step up to the Gold Zone! Enhance your membership and multiply your points to unlock luxurious rewards</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>VIP Customer Support</p>
                  <span>Shop with ease and enjoy personalized assistance from our dedicated Kairaus Support Team.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>VIP Customer Support</p>
                  <span>Shop with ease and enjoy personalized assistance from our dedicated Kairaus Support Team.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className='points_table'>
                  <p>Exclusive Events</p>
                  <span>Get nvitations to members-only events. Enjoy exclusive launches, VIP gatherings, and special workshops designed just for rewards zone members</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Exclusive Events</p>
                  <span>Get nvitations to members-only events. Enjoy exclusive launches, VIP gatherings, and special workshops designed just for rewards zone members.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className='points_table'>
                  <p>Free Expedited Shipping</p>
                  <span>Shop your favorites and enjoy the luxury of speed with free expedited shipping for all your purchases. Faster delivery, at no extra cost—exclusively for rewards zone members.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Free Next-Day Shipping</p>
                  <span>Shop your favorites and enjoy the luxury of speed with free next-day shipping for all your purchases. Faster delivery, at no extra cost—exclusively for rewards zone members.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className='points_table'>
                  <p>Boost Your Benefits</p>
                  <span>Step up to the Platinum Zone! Enhance your membership and multiply your points to unlock luxurious rewards.</span>
                </div>
              </td>
              <td>
                <div className='points_table'>
                  <p>Personal Gift Advisor</p>
                  <span>Receive expert assistance from our in-house specialists to select and personalize the ideal gift for your loved ones.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <div className='points_table'>
                  <p>Personal Shopping Assistant</p>
                  <span>Enjoy a tailored shopping experience with your own Personal Shopper, available at your convenience.</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <p>Please be aware that rewards and their associated benefits are subject to change. For the most current information, please contact us.</p>
    </div>
  )
}

export default Rewards