import React, { useState } from 'react'
import { Input, Form, Col, Row, Collapse, Checkbox, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import googlePay from '../../assets/images/googlepay.png';
import paytmPay from '../../assets/images/paytm.png';
import phonePay from '../../assets/images/phonepay.png';
import upiOnline from '../../assets/images/upiOnline.png';

function CreditForm() {

    const [checkUpi, setCheckupi] = useState(false)
    const [activeKey, setActiveKey] = useState('1');

    const text = (
        <div className='Upi_tab_form'>
            <Form name="control-hooks" layout="vertical" autoComplete="off" >
                <Row gutter={18}>
                    <Col xs={24} sm={24} md={17} lg={17}>
                        <Form.Item name="UpiId"
                            rules={[{ required: true, }]}>
                            <Input className='input_signin' placeholder='Enter UPI ID' />
                            <InfoCircleOutlined />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={7} lg={7}>
                        <Form.Item name="UpiName" >
                            <Select
                                className='input_signin'
                                showSearch
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: '1',
                                        label: 'Not Identified',
                                    },
                                    {
                                        value: '2',
                                        label: 'Closed',
                                    },
                                    {
                                        value: '3',
                                        label: 'Communicated',
                                    },
                                    {
                                        value: '4',
                                        label: 'Identified',
                                    },
                                    {
                                        value: '5',
                                        label: 'Resolved',
                                    },
                                    {
                                        value: '6',
                                        label: 'Cancelled',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='UpiBtn'>
                    <button>Pay 1000 </button>
                </div>
            </Form>
        </div>
    );

    const textSec = (
        <div className='Upi_tab_form'>
            <Form name="control-hooks" layout="vertical" autoComplete="off" >
                <Row gutter={18}>
                    <Col span={17}>
                        <Form.Item name="UpiId"
                            rules={[{ required: true, }]}>
                            <Input className='input_signin' placeholder='Enter UPI ID' />
                            <InfoCircleOutlined />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name="UpiName" >
                            <Select
                                className='input_signin'
                                showSearch
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: '1',
                                        label: 'Not Identified',
                                    },
                                    {
                                        value: '2',
                                        label: 'Closed',
                                    },
                                    {
                                        value: '3',
                                        label: 'Communicated',
                                    },
                                    {
                                        value: '4',
                                        label: 'Identified',
                                    },
                                    {
                                        value: '5',
                                        label: 'Resolved',
                                    },
                                    {
                                        value: '6',
                                        label: 'Cancelled',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='UpiBtn'>
                    <button>Pay ₹1000 </button>
                </div>
            </Form>
        </div>
    );

    const items = [
        {
            key: '1',
            label: <div className='collapse_Img'>
                <div className='span_collapse'>
                    <img src={googlePay} />
                    <span>Google Pay</span>
                </div>
                <Checkbox checked={checkUpi} />
            </div>,
            children: <p>{text}</p>,
        },
        {
            key: '2',
            label: <div className='collapse_Img'>
                <div className='span_collapse'>
                    <img src={phonePay} />
                    <span>Phone Pay</span>
                </div>
                <Checkbox />
            </div>,
            children: <p>{textSec}</p>,
        },
        {
            key: '3',
            label: <div className='collapse_Img'>
                <div className='span_collapse'>
                    <img src={paytmPay} />
                    <span>Paytm</span>
                </div>
                <Checkbox />
            </div>,
            children: <p>{text}</p>,
        },
        {
            key: '4',
            label: <div className='collapse_Img'>
                <div className='span_collapse'>
                    <img src={upiOnline} />
                    <span>Other UPI Id</span>
                </div>
                <Checkbox />
            </div>,
            children: <p>{text}</p>,
        },
    ];



    const handleClick = (e) => {
        setCheckupi(e)
    }

    return (
        <>
            <div className='upiForm_payment'>
                <h2>Pay With UPI</h2>
                <div className='Upi_tab_accodian'>
                    <Collapse onChange={(e) => handleClick(e)} accordion items={items} defaultActiveKey={['1']} />
                </div>
            </div>
        </>
    )
}

export default CreditForm