import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import { initializeUserSlice } from './reducers/userSlice';
import { initializeCartSlice } from './reducers/cartSlice'; 
import { setStoreInstance } from './storeInstance';


const store = configureStore({
  reducer: rootReducer,
});
// initializeUserSlice(store);
initializeUserSlice(store)
initializeCartSlice(store);
setStoreInstance(store);

export default store;
