import React from 'react'

import { Input, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { authApi } from '../../services/auth/authApi';
import { toast } from 'react-toastify';
import Routes from '../../Routes/Routes';

const ResetPassword = () => {
    // const  {token}  = useParams();
    const token = window?.location?.href?.split("?token=")?.[1];
    // console.log(token,"token")

    // const user = useSelector(state => state.user);
    const onFinish = async (values) => {
        const { password,confirmPassword } = values;

        try {
            const formData = new URLSearchParams();
            formData.append('token', token);
            formData.append('new_password', password);
            formData.append('confirm_password', confirmPassword);
            const response = await authApi.resetPassword(formData);
            // console.log(response, "response")
            if (response?.status == "1") {
                toast.success(response?.message)
                navigate(Routes.SignIn)
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            toast.error("New password must not be same as old password")
        }
    };
    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();

    const handleKeyPress = (e) => {
        if (e.key === " ") {
          e.preventDefault();
        }
      };

    return (

        <>

            <div className='BD_master_wrapper'>
                <div className='bd_model_left'>
                    <h1>Reset Password!</h1>

                    <Form name="control-hooks" layout="vertical" onFinish={onFinish} autoComplete="off" >
                
                        <Form.Item
                            name="password"
                            label="Create a Password"
                            rules={[{ required: true, message: "Password is required" }, {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+=])[A-Za-z\d!@#$%^&*()-+=]{8,20}$/,
                                message: 'Password must be 8-20 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
                            },
                            ]}
                            hasFeedback
                        >
                            <Input.Password className="input_signin"
                                onKeyDown={handleKeyPress} maxLength={16} />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            label="Confirm Password"
                            dependencies={['password']}
                            rules={[{ required: true, message: "Confirm Password is required" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                              }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password className="input_signin" />
                        </Form.Item>


                        <div className='bd_model_button'>
                            <button key="submit" className='BG_mainButton' >
                                Submit
                            </button>
                        </div>
                        {/* <div className='notice'>
                                <p>By continuing, you agree to Storepedia's Conditions of Use and Privacy Notice.</p>
                            </div> */}
                        <Link to={Routes.SignIn}>

                            {/* <div className='Storepedia' onClick={() => navigate("/signin")}> */}
                            <p>Go Back</p>
                            {/* </div> */}
                        </Link>
                        {/* <button key="submit" className='BG_mainButton2' >
                            Create an Account
                                </button> */}
                    </Form>
                </div>
            </div>
        </>


    )
}

export default ResetPassword