import React, { useState, useEffect } from 'react';
import { Link, useLocation} from 'react-router-dom';
import {getProductByColorList } from '../../services/home';
import { Row, Col} from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {} from '@ant-design/icons';
import '../../assets/css/style/product.scss'
import {useSelector } from 'react-redux';
import Wishlist from '../../function/Wishlist';
import Routes from '../../Routes/Routes';
import { LiaGreaterThanSolid } from "react-icons/lia";
import hoverImage from '../../assets/images/2nd.png';
import { Carousel } from 'react-bootstrap';
import NoData from '../../assets/images/noData.png'
import AddToCart from '../../function/AddToCart';
import CommFilter from '../../components/CommFilter';
import ProductPrice from '../../components/ProductPrice';

const ProductByColor = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [productList, setProductList] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryImgPath, setCategoryImgPath] = useState(null);
    const [sliderPath, setSliderPath] = useState(null);
    const [index, setIndex] = useState(0);
    const [imgPath, setimgPath] = useState(null);
    const user = useSelector((state) => state.user);
    const [price, setPrice] = useState(null);

    const userId = user?.id ? user.id : 0;
    const chips = useSelector(state => state.chips);
    const [chipsList, setChipsList] = useState(null);
    const [slugData, setSlugData] = useState()
    useEffect(() => {
        setChipsList(chips);
    }, [chips])

    let from;
    let colorById;
    if (location.state) {
        from = location.state.from;
        colorById = location.state.colorId;
    }
    const { pathname } = location;
    const parts = pathname.split("/");
    const desiredPart = parts[1];
    const fetchProducts = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('user_id', userId);
            const response = await getProductByColorList(from, formData);
            setProductList(response);
            setimgPath(response.product_path);
            setPrice(response.max_price)
            setCategory(response?.slider_details);
            setCategoryImgPath(response.category_path);
            setSliderPath(response.page_slider)
            setSlugData(response?.slug)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [gridLayout, setGridLayout] = useState('lg'); // Default layout is 'lg' (4 columns)

    const handleGridLayoutChange = (layout) => {
        setGridLayout(layout);
    };

    const renderGridIcons = () => (
        <div className="grid-icons">
            <div className="grid-icon" onClick={() => handleGridLayoutChange('sm')}>
                {/* First SVG (2 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 24H43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('md')}>
                {/* Second SVG (3 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 18H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M5 30H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M17 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M30 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('lg')}>
                {/* Third SVG (4 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="40" height="40" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M24 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M34 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M4 14H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 34H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 24H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );

    const getColSpan = () => {
        switch (gridLayout) {
            case 'sm':
                return { xs: 12, sm: 12 };
            case 'md':
                return { xs: 12, sm: 12, md: 8 };
            case 'lg':
                return { xs: 12, sm: 12, md: 8, lg: 6 };
            default:
                return { xs: 12, sm: 12, md: 8, lg: 6 };
        }
    };

    return (
        <>
            <div className='prodcut_arrivalNew'>
                <div className="drinkware_top_img_sec">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {category?.page_slider?.page_slider_images.map((slider, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img src={`${sliderPath}${slider.image}`} alt={slider.image_title} />
                                    <Carousel.Caption>
                                        <div className='giftly_heading_para'>
                                            <h1>{slider.image_title}</h1>
                                            <p>{slider.description}</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </div>
                <div className='path_drinkware'>
                    <Link to="/">Home</Link> <LiaGreaterThanSolid /> <p>{from}</p>
                </div>
                <div className='drinkware_acc_carousel_section'>
                    <CommFilter setProductList={setProductList} setimgPath={setimgPath} fetchProducts={fetchProducts} setCategoryImgPath={setCategoryImgPath} setSliderPath={setSliderPath} setCategory={setCategory} price={price} desiredPart={desiredPart} />
                    <div className='right-image-glry'>
                        <div className='dinner_slider'>
                            {renderGridIcons()}
                            <Row gutter={[32, 32]}>
                                {productList?.data?.length > 0 ?
                                    productList?.data.map((sellerItem) => (
                                        <Col {...getColSpan()} key={sellerItem?.id}>
                                            <div className='dinner_sliderImg'>
                                                <div className='dinnerCategory_cont'>
                                                    <Link to={`/product/${sellerItem?.product_slug}`} state={{ from, menuSlug: slugData, ProductId: sellerItem?.id }} key={sellerItem?.id}>
                                                        <div className='imageContainer '>
                                                            <img src={`${imgPath}${sellerItem?.productimages[0]?.file_name}`} />
                                                            <img src={!sellerItem?.productimages[1] ? hoverImage : `${imgPath}${sellerItem?.productimages[1]?.file_name}`} className='hoverImage' />
                                                        </div>
                                                    </Link>
                                                    {sellerItem?.stock_quantity <= 0 ?
                                                        <div className="sold_off_chips">
                                                            <p>Sold Out</p>
                                                        </div> :
                                                        sellerItem?.discount !== 0 &&
                                                        <div className="off_chips">
                                                            <p>{sellerItem?.discount}% off</p>
                                                        </div>}

                                                    <Wishlist is_wishlist={sellerItem?.is_wishlist} pId={sellerItem?.id} path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} mode='HeartOutlined' />

                                                    <AddToCart productList={sellerItem} p_id={sellerItem?.id} imgPath={imgPath} />

                                                </div>
                                                <Link to={`/product/${sellerItem?.product_slug}`} state={{ ProductId: sellerItem?.id }} key={sellerItem?.id}>
                                                    <div className='dinnerSlider_details'>
                                                        <p>{sellerItem?.product_name} </p>
                                                        <div className='dinnerSliderSpan'>
                                                            {/* <span>₹{sellerItem?.price}</span>
                                                            {sellerItem.price !== parseFloat(sellerItem.compare_price) && sellerItem.compare_price > 0 &&
                                                                <p>₹{sellerItem.compare_price}</p>
                                                            } */}
                                                            <ProductPrice product={sellerItem}/>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
                                    )) :
                                    <div className='noDataCont' style={{ marginTop: 60 }}>
                                        <img src={NoData} alt='' />
                                        <h5> No Data Found</h5>
                                    </div>
                                }
                            </Row>

                            {/* </Slider> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductByColor;