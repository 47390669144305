// services/orders/order.js
import axios from "axios";
const aConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const api = axios.create(aConfig);

const handleApiError = (error) => {
  console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const getSingleOrderDetail =async (order_id,token)=>{
  try{
    const response = await api.post('get-order-details', order_id, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

export const cancelOrder =async (data,token)=>{
  try{
    const response = await api.post('cancel-order-item', data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}
