// import { useEffect } from 'react';
// import CalculateTotals from '../../utils/cartUtils';

import { useSelector } from "react-redux";
import CalculateTotals from "../../components/checkout/priceCalculateTotals";

// import { useSelector } from 'react-redux';
const PriceDetails = ({ shipping }) => {
    const { total = { totalComparePrice: 0, totalCount: 0, totalPrice: 0 }, disAmount = 0, grandTotal = 0, newGrandTotal = 0, shippingCharge = 0,newShippingCharges=0, bagDiscount, rewardAmnt, firstOrderCouponDiscount = 0 } = CalculateTotals();
    // const Newtotal =  grandTotal + shipping[0]?.freight_charge;
    let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
    firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);
    const shippingCharges = useSelector((state) => state.shippingFees);
    const excRate = useSelector(state => state.excrate);
    const isINR = excRate?.currencyCode === "INR";
    const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || '$';
    return (
        <div className='prize_detail'>
            <div className='prize_dra'>
                <p>Bag MRP {`(${total.totalCount} items)`}</p>
                <p> {isINR ? "₹" : currencySymbol} {total.totalComparePrice.toFixed(2)}</p>
            </div>
            <div className='prize_dra'>
                <p>Bag Discount</p>
                <p>{bagDiscount ? `-${isINR ? "₹" : currencySymbol} ${bagDiscount.toFixed(2)}` : '0'}</p>
            </div>
            {
                disAmount > 0 && <div className='prize_dra'>
                    <p>Coupon Discount</p>
                    <span>-₹{disAmount.toFixed(2)}</span>
                </div>
            }
           {shippingCharges && (
                newShippingCharges > 0 ? 
                    <div className='prize_dra'>
                        <p>Shipping Charges</p>
                        <span>+ {isINR ? "₹" : currencySymbol} {newShippingCharges?.toFixed(2)}</span>
                    </div>
                : 
                    <div className='prize_dra'>
                        <p>Shipping Charges</p>
                        <span>Free</span>
                    </div>
            )}

            {/* <div className='prize_dra'>
                <p>Shipping</p>
                <p>Free</p>
            </div> */}
            {
                rewardAmnt > 0 && (
                    <div className='prize_dra'>
                        <p>Reward Points Discount</p>
                        <p>-{isINR ? "₹" : currencySymbol} {rewardAmnt}</p>
                    </div>
                )

            }
            {firstOrderCouponDetail?.order_count == 0 &&
                <div className='prize_dra'>
                    <p >First Order Discount ({firstOrderCouponDetail?.data?.type_val}{firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type}) </p>
                    <span >-{isINR ? "₹" : currencySymbol}{firstOrderCouponDiscount.toFixed(2)}</span>
                </div>
            }
            <div className='prize_dra'>
                <p className='p_bold'>Total</p>
                <p className='p_bold'>{isINR ? "₹" : currencySymbol}{newGrandTotal.toFixed(2)}</p>
            </div>
        </div>
    );
};
export default PriceDetails;


