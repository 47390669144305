import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { getWishList } from '../../services/wishlist/getWishList';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import Wishlist from '../../function/Wishlist';
import NoDataimg from '../../assets/images/noData.png'
import { addToCart } from '../../reducers/cartSlice';
import { toast } from 'react-toastify';
import ReactStars from "react-rating-stars-component";
import { STRINGS } from '../../constants/Constant';
import { addWishlist } from '../../services/wishlist/addWishlist';
import { addToFavId } from '../../reducers/favIdSlice';
import ProductPrice from '../../components/ProductPrice';
import { clearUser } from '../../reducers/userSlice';



function FavouritePage() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [fProducts, setFProducts] = useState([]);
    const [imgpath, setImgPath] = useState();
    const [isProcessing, setIsProcessing] = useState(false);
    const favId = useSelector((state) => state.favId)||[];

    const dispatch = useDispatch();
    let cart = useSelector((state) => state.cart);
    useEffect(() => {
        if (user && user.id) {
            const fetchData = async () => {

                try {
                    const response = await getWishList(user.token);
                    if (response.status === '1') {
                        setFProducts(response.data)
                        setImgPath(response.product_path)
                    }

                } catch (error) {
                    if (error.message === "Invalid token") {
                        // Handle invalid token scenario
                        localStorage.removeItem('user'); // Remove only the 'user' key from local storage
                        dispatch(clearUser());
                        navigate(Routes.SignIn, { state: { from: Routes.AccountProfile } }); // Redirect to login page with state
                      }
                }
            }
            fetchData();
        } else {
            navigate(Routes.SignIn, { state: { from: Routes.AccountProfile } });

        }


    }, [user, navigate, setFProducts, cart])

    const handleButton = cart.find(val=> {
        return fProducts.some(product => product.product.id === val.id);
    });
    const handleAddToCart = async (p_id, newPId) => {
        if (isProcessing) return; // Prevent multiple clicks while processing
        setIsProcessing(true);
        let productDetail = fProducts?.find(item => item.id === p_id);
        let productCount = 1
        const { product } = productDetail
        const { id, product_name, price, compare_price, product_slug,weight,unit,stock_quantity} = product;
        const image = `${imgpath}${product?.productimages[0]?.file_name}`;
        const newItem = { id, product_name, product_slug, is_wishlist: 0, price, compare_price, image, count: productCount, p_variant_id: 0,weight,unit,stock_quantity};

        const existingItemIndex = cart.findIndex(item => item.product_name === newItem.product_name);

        if (existingItemIndex === -1) {
            if (Object.isExtensible(cart)) {
                cart.push(newItem);
            } else {
                cart = [...cart, newItem];
            }
        } else {
            const updatedCart = [...cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + productCount,
            };
            cart = updatedCart;
        }
        // Update localStorage and dispatch to Redux
        localStorage.setItem('cart', JSON.stringify(cart));
        dispatch(addToCart(cart));

        //
        // Check if the newPId exists in the favId array
        const existingFavIndex = favId.findIndex(item => item === newPId);

        // If the newPId exists in favId, remove it
        if (existingFavIndex !== -1) {
        const updatedFavId = favId.filter(item => item !== newPId);
        // Dispatch action to update favId state in Redux
        dispatch(addToFavId(updatedFavId));
        // Update local storage
        localStorage.setItem('favId', JSON.stringify(updatedFavId));
        }
        //
        const formData = new URLSearchParams();
        formData.append('product_id', newPId);
        const response = await addWishlist(formData, user.token);
        if (response.status === '1') {
            let tempArr = fProducts.filter(item => item.product.id !== newPId)
            setFProducts(tempArr);

        }
        setTimeout(() => {
            toast.success(STRINGS.MOVED_TO_CART_SUCCESSFULLY);
            setIsProcessing(false);
        }, 200); // Delay of 1 second
    };
    return (
        <>
            <div className='favourite_pages'>
                <h1>My Favourites</h1>
                <div className='favourite_cards'>
                    <Row gutter={[18, 22]}>
                        {fProducts?.length > 0 ? (
                            fProducts.map((p, index) => (
                                <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
                                    <div className='multiFav_crd'>
                                        <Wishlist pId={p.product.id} path={`${Routes.ProductDetail}/${p.product.product_slug}`} mode='CloseCircleOutlined' setFProducts={setFProducts} fProducts={fProducts} />
                                        <div className='favourite_img'>
                                            <Link key={index} to={`${Routes.ProductDetail}/${p.product.product_slug}`}>
                                                <img src={`${imgpath}${p?.product?.productimages[0]?.file_name}`} alt={p.product.product_name} />
                                                <h3>{p.product.product_name}</h3>
                                            </Link>
                                            <div className='fav_price'>
                                                <div className="dinnerSliderSpan">
                                                    {/* <span>₹{p.product.price}</span>
                                                    <p>₹{p.product.compare_price}</p> */}
                                                    <ProductPrice product={p.product}/>
                                                </div>
                                                <div className='fav_ratingImg'>
                                                    <ReactStars
                                                        key={`react-stars-${index}-${p?.product?.average_rating}`}
                                                        count={5}
                                                        size={24}
                                                        isHalf={true}
                                                        activeColor="#ffd700"
                                                        color="#d1d1d1"
                                                        value={parseFloat(p?.product?.average_rating)}
                                                        edit={false}
                                                    />
                                                </div>
                                            </div>
                                            {p.product.stock_quantity === 0 ? (
                                                <p className='outStock'>Out of stock</p>
                                            ) : (
                                                handleButton?.id !== p.product.id &&
                                                <button onClick={() => handleAddToCart(p.id, p.product.id)} disabled={isProcessing}>
                                                    {STRINGS.MOVE_TO_CART}
                                                </button>
                                            )}

                                        </div>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <div className='noDataCont'>
                                <img src={NoDataimg} alt='' />
                                <h5>No Data Found</h5>
                            </div>
                        )}
                    </Row>
                </div>
            </div>
        </>
    )
}

export default FavouritePage