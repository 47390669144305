import React from 'react';
import Accordion from 'react-bootstrap/Accordion'; // Adjust the import based on your project structure

const ProductFAQ = ({ faq }) => {
    if (!faq?.length) return null;

    return (
        <div className="faqs_productDetails">
            <section className="faqs_accor_main_sec1">
                <h4>Frequently Asked Questions</h4>
                <Accordion defaultActiveKey="0">
                    {faq.map((faqItem, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faqItem?.question}</Accordion.Header>
                            <Accordion.Body>{faqItem?.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </section>
        </div>
    );
};

export default ProductFAQ;
