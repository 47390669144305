import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import Wishlist from '../../function/Wishlist';
import AddToCart from '../../function/AddToCart';

const TrendingProduct = React.memo(({ trendingProduct, relatedSettings, imgPath, handleRelated, Routes }) => {
    const navigate = useNavigate();

    const variantSlugNavigate = (slug) => {
        navigate(`${Routes.ProductDetail}/${slug}`);
    };

    const trendingProductList = useMemo(() => (
        trendingProduct?.length > 0 && !trendingProduct?.every(item => item.relatedproduct == null) && (
            <div className="product_all_main">
                <h2>Trending Products</h2>
                <div className="dinner_slider">
                    <Slider {...relatedSettings}>
                        {trendingProduct?.map((item, index) => (
                            item?.relatedproduct !== null && (
                                <div key={index} className="dinner_sliderImg">
                                    <div className="dinnerCategory_cont">
                                        <div
                                            className="imageContainer"
                                            onClick={() =>
                                                variantSlugNavigate(item?.relatedproduct?.product_slug)
                                            }
                                        >
                                            <img
                                                src={`${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}`}
                                                alt=""
                                            />
                                            <img
                                                src={
                                                    !item?.relatedproduct?.productimages[1]
                                                        ? `${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}`
                                                        : `${imgPath}${item?.relatedproduct?.productimages[1]?.file_name}`
                                                }
                                                className="hoverImage"
                                                alt=""
                                            />
                                        </div>
                                        {item?.relatedproduct?.stock_quantity < 1 ? (
                                            <div className="sold_off_chips">
                                                <p>Sold Out</p>
                                            </div>
                                        ) : (
                                            item?.relatedproduct?.discount > 0 && (
                                                <div className="off_chips">
                                                    <p>{item.relatedproduct.discount}% off</p>
                                                </div>
                                            )
                                        )}
                                        

                                        <Wishlist
                                            is_wishlist={item?.relatedproduct?.is_wishlist}
                                            pId={item?.related_product_id}
                                            path={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`}
                                            mode="HeartOutlined"
                                        />
                                        <AddToCart
                                            imgPath={imgPath}
                                            productList={item?.relatedproduct}
                                        />
                                    </div>
                                    <div
                                        className="dinnerSlider_details"
                                        onClick={() =>
                                            handleRelated(item?.relatedproduct?.product_slug)
                                        }
                                    >
                                        <p>{item?.relatedproduct?.product_name} </p>
                                        <div className="dinnerSliderSpan">
                                            <span>₹{item?.relatedproduct?.price}</span>
                                            {item?.relatedproduct?.price !==
                                                parseFloat(item?.relatedproduct?.compare_price) &&
                                                item?.relatedproduct?.compare_price > 0 && (
                                                    <p>₹{item?.relatedproduct?.compare_price}</p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </Slider>
                </div>
            </div>
        )
    ), [trendingProduct, relatedSettings, imgPath, handleRelated, Routes]);

    return trendingProductList;
});

export default TrendingProduct;
