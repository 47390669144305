import React from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';

function Thankyousign() {
    const navigate=useNavigate();
    const handleClick =()=>{
        navigate(Routes.Home)
    }
    return (
        <>
            <div className='sign_thank_page'>
                <h1>Thank You!</h1>
                <p>We want to express our heartfelt appreciation for your recent feedback on our website. Your thoughts and insights are invaluable to us as we strive to provide an exceptional shopping experience. Thank you for taking the time to share your thoughts. Your feedback is a guiding light for us, and we look forward to incorporating your suggestions to make your future experiences even better. Your support means the world to us.
                </p>
                <div className='trackOrder' onClick={handleClick}>
                    <button>Back to Home</button>
                </div>
            </div>
        </>
    )
}

export default Thankyousign