import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-bootstrap";
import ViewedImg from "../../assets/images/beautiful.png";
import ViewedImg1 from "../../assets/images/blog image/blog_img_2.png";
import { Link, useNavigate} from "react-router-dom";
import { LiaGreaterThanSolid } from "react-icons/lia";
import Routes from "../../Routes/Routes";
import { Row, Col} from "antd";
import Wishlist from "../../function/Wishlist";
import AddToCart from "../../function/AddToCart.js";
import { viewProduct } from "../../services/viewed.js";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../reducers/userSlice.js";
import ProductPrice from "../../components/ProductPrice.jsx";

function Viewed() {
  const [viewProducts, setViewProducts] = useState([]);
  const [imgPath, setimgPath] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const fetchViewedProducts = async () => {
    // const token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjM2LCJyb2xlX2lkIjoyLCJpYXQiOjE3MTUyNTYzNzksImV4cCI6MTcxNzg0ODM3OX0.DyA7s8O6ELDJ27ZyHu7RG0hVclCAPcHeXhTo81oVYCI"
    try {
      const response = await viewProduct(user?.token);
      // console.log(response.APIError);
      // console.log('responseresponseresponse',response);
      if (response.status === "1") {
        setViewProducts(response?.data);
        setimgPath(response?.product_path);
      }
    } catch (error) {
      if(error?.response?.data?.message==="Invalid token"){
        const from = `${Routes?.Viewed}`; // Corrected the template literal syntax
        dispatch(clearUser());
        navigate(Routes?.SignIn, { state: { from } });
    }
    }
  };

  useEffect(() => {
    fetchViewedProducts();
  }, []);


  return (
    <div className='viewed_pages'>
      <div className="drinkware_top_img_sec">
        <Carousel>
          <Carousel.Item>
            <img src={ViewedImg} alt='' />
            <Carousel.Caption>
              <div className='giftly_heading_para'>
                <h3>Viewed Products</h3>
                <p>Explore similar pieces you've been eyeing!</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="path_drinkware">
        <div className='path_linkedCont'>  <Link to={Routes?.Home}> Home</Link> <LiaGreaterThanSolid /> <p>Viewed Products</p></div>
      </div>
      <div className='viewwed_products'>
        <Row gutter={[32, 32]}>
          {viewProducts?.map(viewItem => (
            viewItem?.products &&
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <div className='view_productCrd'>
                <Link to={`${Routes?.ProductDetail}/${viewItem?.products?.product_slug}`} state={{ProductId:viewItem?.products?.id}}>
                  <img src={viewItem?.products !== null ? `${imgPath}${viewItem?.products?.productimages[0]?.file_name}` : ViewedImg1} alt=""/>
                  <div className='view_productSpn'>
                    <p>{viewItem?.products?.product_name}</p>
                    <div className='dinnerSliderSpan'>
                      {/* <span>₹{viewItem?.products?.price}</span>
                      {viewItem?.products?.price !== parseFloat(viewItem?.products?.compare_price) && viewItem?.products?.compare_price > 0 &&
                          <p>₹{viewItem?.products?.compare_price}</p>
                      } */}
                      <ProductPrice product={viewItem?.products}/>
                    </div>
                    <div className='view_star'>
                      <ReactStars
                        count={5}
                        key={`react-stars-${viewItem?.products?.average_rating}`}
                        value={parseFloat(viewItem?.products?.average_rating)}
                        size={20}
                        isHalf={true}
                        activeColor="#ffd700"
                        color="#d1d1d1"
                        edit={false}
                      />
                      {viewItem?.products?.average_rating > 0 &&
                        <span>({viewItem?.products?.average_rating?.toFixed(1)})</span>}</div>
                  </div>
                </Link>
                {viewItem?.products?.stock_quantity <= 0 ?
                  <div className="sold_off_chips">
                    <p>Sold Out</p>
                  </div>
                  :
                    viewItem?.products?.discount !== 0 &&
                  <div className="off_chips">
                      <p>{viewItem?.products?.discount}% off</p>
                  </div>}
                <Wishlist is_wishlist={viewItem?.is_wishlist} pId={viewItem?.products?.id} path={`${Routes.ProductDetail}/${viewItem?.products?.product_slug}`} mode='HeartOutlined' />
                <AddToCart productList={viewItem?.products} imgPath={imgPath} />
              </div>

            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Viewed;
