import React, { useEffect, useRef, useState } from 'react'
import { Input, Form, Button, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { authApi } from '../../services/auth/authApi';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Routes from '../../Routes/Routes';
import trashLottie from '../../assets/images/gmailVerify.json';
import Lottie from 'react-lottie'

const ForgetPass = () => {
    const emailName = useRef();
    useEffect(() => {
        emailName.current.focus();
    }, [])
    const user = useSelector(state => state.user);
    const onFinish = async (values) => {
        try {
            const formData = new URLSearchParams();
            formData.append('email', values?.email);
            const response = await authApi.forgotPassword(formData);
            // console.log(response, "response")
            if (response?.status == "1") {
                toast.success(response?.message)
                navigate(Routes.SignIn)
            } else {
                toast?.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            toast.error(error.message)
        }
    };
    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();
    const handleKeyPress = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: trashLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (

        <>

            <div className='BD_master_wrapper'>
                <div className='bd_model_forget'>
                    <h1>Forgot Password!</h1>
                    <span>No worries! Just enter your email, and we'll assist you.</span>
                    <Form name="control-hooks" layout="vertical" onFinish={onFinish} autoComplete="off" >
                        <Form.Item name="email" label="Email Address"
                            rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'Please enter a valid email' },]}>
                            <Input placeholder='Enter here...' className="input_signin" onKeyDown={handleKeyPress} ref={emailName} />
                        </Form.Item>

                        <div className='bd_model_button_forget'>
                            <Link to={Routes.SignIn}>
                                <span>Go Back</span>
                            </Link>
                            <button key="submit" className='BG_mainButton' onClick={showModal} >
                                Submit
                            </button>
                        </div>
                        <div className='Storepedia_forget'>
                            <Link to={Routes?.SignUp}>
                                New to Kairaus?
                            </Link>
                            <button key="submit" className='BG_mainButton2'>
                                Create an Account
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className='emailVerify_model'
                footer={
                    <div className='custom-modal-footer'>
                        <Button key="delete" onClick={handleOk} className='deleteButton'>
                            Got It!
                        </Button>
                    </div>
                }
            >
                <div className='delete_popUpDesign'>
                    <Lottie options={defaultOptions}
                        max-height={150}
                        width={150}
                    />
                    <p>Check Your Email!</p>
                    <span>We've you a link to reset your password!</span>
                </div>
            </Modal>
        </>
    )
}

export default ForgetPass