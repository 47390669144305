import React from 'react';
import { Carousel } from 'react-bootstrap';
import DinninhImg from '../../assets/images/soulsip.png';
import { LiaGreaterThanSolid } from "react-icons/lia";
import occasionImg1 from '../../assets/images/birthday.jpg';
import occasionImg2 from '../../assets/images/rakhi.jpg';
import occasionImg3 from '../../assets/images/teej.jpg';


const OccasionCards = () => {
    return (
        <div className="collection_div">
            <div className="drinkware_top_img_sec">
                <Carousel>
                    <Carousel.Item>
                        <img src={DinninhImg} alt='' />
                        <Carousel.Caption>
                            <div className='giftly_heading_para'>
                                <h1>Occasions and Festivals</h1>
                                <p>Explore similar pieces you've been eyeing!</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='path_drinkware'>
                Home <LiaGreaterThanSolid /> <p>Occasions Collection</p>
            </div>
            <div className='occasions_all'>
                <div className='occasions_crds'>
                    <div className='occasion_mainDiv'>
                        <img src={occasionImg1} />
                    </div>
                    <div className='occasion_mainDiv'>
                        <img src={occasionImg2} />
                    </div>
                    <div className='occasion_mainDiv'>
                        <img src={occasionImg3} />
                    </div>
                    <div className='occasion_mainDiv'>
                        <img src={occasionImg2} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OccasionCards
