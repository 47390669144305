import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import { getBlogDetails } from '../../services/blog'
import { useNavigate, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Routes from '../../Routes/Routes';

const Blog = () => {

    const [blogDetail, setBlogDetail] = useState([]);
    const { slug } = useParams();
    // console.log('slug==>>>',slug);
    const navigate=useNavigate();

    const fetchBlogDetail = async () => {
        try {
            const blogResponse = await getBlogDetails(slug);
            if(blogResponse===404){
                navigate(Routes.Error);
            }
            if (blogResponse.status === '1') {
                setBlogDetail(blogResponse);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    };

    useEffect(() => {
        fetchBlogDetail();
    }, [slug]);

    
    return (
        <>
            <div className='spedia_blog_main'>
                <div className='blog_content_right'>
                    <h1>{blogDetail?.data?.title}</h1>
                    {/* <h4>How to Choose the Perfect Dinnerware Set</h4> */}
                    <p>{blogDetail?.data?.created_at}</p>
                </div>
                <div className='blog_content_left'>
                    <img src={blogDetail?.path + blogDetail?.data?.image} alt="not found" />
                </div>
            </div>
            <section className='blog_sec_two'>
                <Container>
                    <p className='blog_sec_two_main' dangerouslySetInnerHTML={{ __html: blogDetail?.data?.description }}></p>
                </Container>
                <div className='faqs_productDetails'>
                    <section className='faqs_accor_main_sec1'>
                        <h4>Frequently Asked Questions</h4>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Are these tumblers heat-resistant?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, the tumblers are made from real Bengal terracotta, making them heat-resistant and suitable for serving hot beverages like tea or coffee.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header>Are the terracotta tumblers easy to hold and serve?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, the terracotta tumblers are designed for ease of handling and serving, with a textured surface and abstract engravings that provide superior tactility.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Can these tumblers be used for purposes other than serving tea or coffee?</Accordion.Header>
                                <Accordion.Body>
                                    While these terracotta tumblers are ideal for serving tea or coffee, they can also be used for other beverages, like fruit juice or lassi, or even as decorative items.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I customise these tumblers with my own designs?</Accordion.Header>
                                <Accordion.Body>
                                    While the terracotta tumblers come with engraved designs, you can explore customizing them further through methods like painting or adding decals. However, ensure to use food-safe materials if modifying them for beverage use.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </section>
                </div>
            </section>
        </>

    )
}

export default Blog