/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { Popover, Steps, Row, Col, Collapse, Drawer, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { addUserAddress } from '../../services/user/addUserAddress';
import { useSelector } from 'react-redux';
import AddressForm from '../../components/forms/AddressForm';
import { getAddressData } from '../../services/user/getUserAddress';
import PriceDetails from './PriceDetails';
import AddressDetails from './AddressDetails';
import { Link, useNavigate } from 'react-router-dom';
import { getSingleAddress } from '../../services/user/singleAddress';
import { updateUserAddress } from '../../services/user/updateUserAddress';
import NoCartimg from '../../assets/images/nocart.png'
import { STRINGS } from '../../constants/Constant';
import Routes from '../../Routes/Routes';
// import CalculateTotals from '../../utils/cartUtils';

import { useCallback } from 'react';
import {updateCartStatus } from '../../services/cart/addCart';
import CheckoutCartProduct from '../../components/drawer/CheckoutCartProduct';
import CalculateTotals from '../../components/checkout/priceCalculateTotals';

const CustomDrawer = ({ onClose, open, editMode, existingData,featchUserAddress, userAddress, setShowBillingForm, showBillingForm }) => {

    const user = useSelector((state) => state.user);
    const [form] = Form.useForm();
    const [addressTypeValue, setAddressTypeValue] = useState('');
    const [defaultValue, setDefaultValue] = useState(false);
    const { token } = user;
    const handleSubmit = async ({ country, state, city, pincode, address, name,gstNumber, phone, is_default, land_mark, isGst, gstCompanyName, gstAddress, is_shipping_address, is_billing_address, billing_name, billing_phone, billing_country_id, billing_state_id, billing_city_id, billing_pincode,  billing_address }) => {
        
        const newDefaultValue = is_default ? 1 : 0;
     
console.log('handleSubmithandleSubmithandleSubmit',addressTypeValue);
        let formData = new URLSearchParams();
        formData.append('title', addressTypeValue);
        formData.append('name', name);
        formData.append('country_id', country);
        formData.append('state_id', state);
        formData.append('city_id', city);
        formData.append('pincode', pincode);
        formData.append('address', address);
        formData.append('land_mark', land_mark);
        formData.append('mobile_number', phone);
        formData.append('is_default', userAddress.length === 0 ? 1 : newDefaultValue);
        formData.append('apartment', '');

        // GST
        formData.append('is_gst', isGst?'1' : '0');
        formData.append('gst_number',isGst? gstNumber : '')
        formData.append('company_name', isGst? gstCompanyName :'');
        formData.append('company_address',isGst? gstAddress : '');
        // // Billing_Address
        formData.append('is_shipping_address', is_shipping_address ? '1' : is_billing_address==false ? '1' : '0'  );
        formData.append('is_billing_address', is_billing_address ? '1' : '0');

        formData.append('billing_name',   billing_name);
        formData.append('billing_phone',  billing_phone);
        formData.append('billing_country_id',  billing_country_id);
        formData.append('billing_state_id',  billing_state_id);
        formData.append('billing_city_id',  billing_city_id);
        formData.append('billing_pincode',  billing_pincode);
        formData.append('billing_address',  billing_address);


        try {
            if (editMode && existingData) {
                // console.log('existingData&editMode',existingData.id);
                // If in edit mode, update the existing address
                formData.append('id', existingData.id);
                // formData.append('is_default', is_default ? 1 : 0);
                const updateResponse = await updateUserAddress(formData, token);
                if (updateResponse.status === '1') {
                    toast.success(STRINGS.ADDRESS_UPDATED_SUCCESSFULLY);
                    onClose(false)
                    featchUserAddress()
                    setAddressTypeValue('')
                } else {
                    toast.error(STRINGS.FAILED_TO_UPDATE_ADDRESS);
                }
            } else {
                // If not in edit mode, add a new address
                const addResponse = await addUserAddress(formData, token);
                if (addResponse.status === '1') {
                    toast.success(STRINGS.ADDRESS_ADDED_SUCCESSFULLY);
                    onClose(false)
                    setAddressTypeValue('')
                    featchUserAddress()
                    setDefaultValue(false)
                } else {
                    toast.error(STRINGS.FAILED_TO_ADD_ADDRESS);
                }
            }
        } catch (error) {
            console.error('API Request Error:', error);
            // message.error('Address submission failed.');
            if (addressTypeValue === "") {
                toast.error("Please select type of address",{toastId:""})
            } else {
                toast.error(error?.message);
            }
        }
    };
    return (
        <Drawer title="Add New Address" placement="right" onClose={onClose} open={open}
            width={550}>
            <div className="model_address_text">
                <h3>Address</h3>
                <AddressForm onSubmit={handleSubmit} form={form} onClose={onClose} editMode={editMode} existingData={existingData} setAddressTypeValue={setAddressTypeValue} addressTypeValue={addressTypeValue} setDefaultValue={setDefaultValue} defaultValue={defaultValue} open={open} setShowBillingForm={setShowBillingForm} showBillingForm={showBillingForm} />
            </div>
        </Drawer>
    );
};

const customDot = (dot, { status, index }) => (
    <Popover
        content={
            <span>
                step {index} status: {status}
            </span>
        }
    >
        {dot}
    </Popover>
);

const Category = () => {
    const [userAddress, setUserAddress] = useState([]);
    const { total = { totalCount: 0, totalPrice: 0 }, savingAmnt = 0,cartLength,filteredCartData} = CalculateTotals();
    const cart = useSelector((state) => state.cart);
    const buy = useSelector(state => state.buy);
    const buyData = buy ? buy : [];
    const cartCount = cart.reduce((total, item) => total + item.count, 0);
    const localCartIds = JSON.parse(localStorage.getItem('localCartIds')); 
    // console.log('filteredCartData',filteredCartData);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [existingData, setExistingData] = useState(null)
    const [deliveryError, setDeliveryError] = useState(false);
    const user = useSelector((state) => state.user);
    const [showBillingForm, setShowBillingForm] = useState(false);
    const navigate = useNavigate();
    // const buy = useSelector((state) => state.buy);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (cartLength === 0) {
                navigate(Routes.Home);
            }
        }, 100);
    
        // Cleanup function to clear the timeout when the component unmounts or when `cartLength` changes
        return () => clearTimeout(timeoutId);
    }, [cartLength]);

    const featchUserAddress = async () => {

        try {
            // Fetch data associated with the address using an API call
            const addressData = await getAddressData(user.token); // Replace with the actual function to fetch data
            // console.log('getAddressDatadasdaaddressData', addressData?.data);
            if (addressData.status === '1') {
                setUserAddress(addressData.data);
            }
        } catch (error) {
            console.error('Error fetching get user address data:', error);
            // Handle error, show message, etc.
        }
    }
    useEffect(() => {
        featchUserAddress();
    }, [user]);

    const text_bag = (
        <div className='drawer_cupns_space'>{
            cartLength=== 0 ? (
                <div className='noDataCont'>
                    <img src={NoCartimg} alt='' />
                    <h5> No Product In cart.</h5>
                    <Link to={Routes.Home}>Continue Shopping</Link>
                </div>
            ) : (
                filteredCartData?.map((product, index) => (
                    <CheckoutCartProduct
                        key={index}
                        product={product}
                    />
                ))
            )}
        </div>
    );

    const [selectedAddressId, setSelectedAddressId] = React.useState(null);

    useEffect(() => {
        const handleBackNavigation = (event) => {
            navigate(-1);
        };

        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navigate]);


    const handleAddCart = (selectedAddress) => {
        navigate(Routes.Confirmation);
        sessionStorage.setItem('selectedAddress', JSON.stringify(selectedAddress));
        // try {
            // const products = filteredCartData.map(product => ({
            //     product_id: product.id,
            //     product_variant_id: product.p_variant_id,
            //     quantity: product.count,
            //     amount: product.price,
            // }));
        //    const newUpdateCart = buyData?.length > 0 ? buyData : cart
        //     const products =  newUpdateCart.map(product => ({
        //         product_id: product.id,
        //         status:  localCartIds?.length>0?(localCartIds?.includes(product.id) ? 1 : 0):1,
        //     }));
            // console.log('products',products);
            // const responseAddCartData = await addCart(products, user.token);
            // const responseAddCartData=await updateCartStatus(products,user.token)
            // // console.log()
            // if (responseAddCartData.status === '1') {
            //     navigate(Routes.Payment, { state: { from: selectedAddress } });
            //     sessionStorage.setItem('selectedAddress', JSON.stringify(selectedAddress));
            // } else {
            //     toast.error('Please try again');
            // }
        // } catch (error) {
        //     console.error('API Request Error:', error);
        //     // Handle other errors
        // }
    };

    const showDrawer = async (edit, data) => {
        // console.log('data',data);
        setEditMode(edit);
        // console.log('useraddress Id :',data);
        if (edit) {
            try {
                const formData = new URLSearchParams();
                formData.append('id', data);
                // Fetch data associated with the address using an API call
                const addressData = await getSingleAddress(formData, user.token); // Replace with the actual function to fetch data
                // console.log('getSingleAddress', addressData);
                if (addressData.status === '1') {
                    // console.log('setExistingData',addressData.data);
                    setExistingData(addressData.data);
                }
            } catch (error) {
                console.error('Error fetching address data:', error);
                // Handle error, show message, etc.
            }
        } else {
            setExistingData(null);
            setShowBillingForm(false);
            // resetFields();
        }

        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (key) => {
        // console.log(key);
    };

    const genExtra = () => (
        <div className='extra_title'>
            {cartLength === 0 ? 'No Items' : `${total?.totalCount} Items`}
        </div>
    );



    const items = [
        {
            key: '1',
            label: 'Bag',
            children: <div>{text_bag}</div>,
            extra: genExtra(),
        },
        {
            key: '2',
            label: 'Price Details',
            children: <PriceDetails />,
            // extra:  <div className='extra_title_sec'>₹{totalAmount.totalPrice}</div>,
        },
    ];
    const excRate = useSelector(state => state.excrate);
    const isINR = excRate?.currencyCode === "INR";
    const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || '$';
    
    return (
        <>
            <div className='checkout_main'>
                <div className='checkout_steper'>
                    <Steps
                        current={0}
                        progressDot={customDot}
                        items={[
                            {
                                title: 'Shipping',
                            },
                            {
                                title: 'Payment',
                            },
                            {
                                title: 'Confirmation',
                            },
                        ]}
                    />
                </div>
                <div className='checkout_heading'>
                    <div>
                        <span>Choose Address</span>
                        <p>Detailed address will help our delivery partner reach your doorstep quickly</p>
                    </div>
                    <div className={selectedAddressId?.zip_code!==null &&userAddress.length !== 0 && selectedAddressId !== null && deliveryError !== true ? 'payBtn' : 'disablepayBtn'}>
                        <button disabled={selectedAddressId?.zip_code===null||userAddress.length === null || selectedAddressId === null || deliveryError === true} onClick={() => handleAddCart(selectedAddressId)}>
                            Continue to proceed
                        </button>
                    </div>
                </div>
                <div className='checkoutt_card_itemInfo'>
                    <Row>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className='checkout_address'>
                                <div className='checkout_addAddrees' onClick={() => showDrawer(false, 6)}>
                                    <PlusOutlined width={25} />
                                    <span>Add New Address</span>
                                </div>
                                {/* Add To Cart and userAddress update  */}
                                <AddressDetails showDrawer={showDrawer} userAddress={userAddress} total={total} setSelectedAddressId={setSelectedAddressId} selectedAddressId={selectedAddressId} setDeliveryError={setDeliveryError} />

                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className='checkout_items_accordian'>
                                <Collapse
                                    onChange={onChange}
                                    items={items}
                                />
                            </div>
                            <div className='cehckout_bag_info'>
                                <span>You are saving {isINR ? "₹" : currencySymbol} {savingAmnt.toFixed(2)} </span>
                                {/* <p>Yay! You will earn 1000 reward points</p> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
            <div className='checkout_footer'>
                {/* <Link to={Routes.Terms} state={{ from: "#returnCancel" }}><span>Refund policy  </span></Link>
                <div className='profile_dashed'>|</div> */}
                <Link to={Routes.Privacy}><span>Privacy policy </span></Link>
                <div className='profile_dashed'>|</div>
                <Link to={Routes.TermCondition}><span>Terms & Conditions</span></Link>
            </div>
            <CustomDrawer onClose={onClose} open={open} featchUserAddress={featchUserAddress} editMode={editMode} existingData={existingData} userAddress={userAddress} setShowBillingForm={setShowBillingForm} showBillingForm={showBillingForm} />
        </>
    )
}

export default Category