// import 'antd/dist/antd.css';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Form, message, Tabs,Spin, Modal } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginForm, resendLoginOtp, sendLoginOtp,verifyLoginOtp } from '../../services/auth/login';
import { toast } from 'react-toastify';
import { addToUser, clearUser } from '../../reducers/userSlice';
import Routes from '../../Routes/Routes';
import { addCart, getCart } from '../../services/cart/addCart';
import { addToCart } from '../../reducers/cartSlice';

import { applyCoupon } from '../../reducers/couponSlice';
// import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const { from } = location.state || { from: Routes.Home };
  // console.log('fromfromfromfromfromfromfrom',from);
  const cart = useSelector((state) => state.cart);
  const {id} = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileOtp, setMobileOtp] = useState([]);
  const [timer, setTimer] = useState();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [modalValue, setModalValue] = useState()
  const [sendId, setSendId] = useState()
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        const updatedUser = JSON.parse(event.newValue) || {};
        if (updatedUser.token) {
          // alert('updatedUser');
          navigate(from || Routes.Home);
        }
      }
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate, from]);
  useEffect(() => {
    if (id) {
      const accountRoutes = ["Profile", "addresses", "coupons", "orders", "gstInfo", "favourites"];
      if (accountRoutes.includes(from)) {
        navigate(Routes.AccountProfile, { state: { from } });
      } else if (from === "viewed") {
        navigate(Routes.Viewed, { state: { from } });
      } else {
        navigate(from || Routes.Home);
      }
    }
  }, []);

  const emailRules = [
    { required: true, message: 'Email is required' },
    { type: 'email', message: 'Please enter a valid email' },
  ];

  const passwordRules = [
    { required: true, message: 'Password is required' },
  ];

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    const trimmedData = pastedData.trim(); // Remove leading and trailing white spaces
    document.execCommand('insertText', false, trimmedData);
  };

  const [errorOccurred, setErrorOccurred] = useState(false);
  const emailName = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (emailName.current.value) {
      setTimeout(() => {
        emailName.current.setSelectionRange(emailName.current.value.length, emailName.current.value.length);
      }, 0);
    } else {
      emailName.current.focus();
    }
  }, [from]);

  const getCartDataFromDB = async (token) => {
    try {
      const result = await getCart(token);
      // console.log('getCartDataFromDB',result);

      if (result?.data?.length > 0) {
        const cartItems = result.data.map((item) => {
          const { id, product_name, product_slug, is_wishlist, price,compare_price,stock_quantity} = item.product;
          const image = `${result.path}${item.product.productimages[0].file_name}`;
          const newItem = { id, product_name, product_slug, is_wishlist, image, price, compare_price, count: item.quantity, p_variant_id:item.product_variant_id,stock_quantity};

          return newItem;
        });
        let updatedCart = [...cart, ...cartItems];

        // To prevent by duplicate product in cart
        let uniqueIds = {};
        updatedCart = updatedCart.filter(obj => {
          if (!uniqueIds[obj.id]) {
            uniqueIds[obj.id] = true;
            return true;
          }
          return false;
        });
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        dispatch(addToCart(updatedCart));
      }
    } catch (error) {
      // console.log('get cart api error', error);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const { username, password } = values;

    if (!username || !password) {
      message.error('Please enter both username and password.');
      setLoading(false);
      return;
    }
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    try {
      const responseData = await loginForm(formData);

      if (responseData.status === '1') {
        const couponnull = {};
        dispatch(applyCoupon(couponnull));
        localStorage.setItem('coupon', JSON.stringify(couponnull));
        dispatch(clearUser());
        const { id, email, userfullname, lastname } = responseData.data;
        const userInfo = { id, email, userfullname, lastname, token: responseData.token };

        localStorage.setItem('user', JSON.stringify(userInfo));
        dispatch(addToUser(userInfo));

        if (cart.length > 0) {
          const products = cart.map((product) => ({
            product_id: product.id,
            product_variant_id: product.p_variant_id,
            quantity: product.count,
            amount: product.price,
          }));
          await addCart(products, responseData.token);
        }

        getCartDataFromDB(userInfo.token);
        switch (from) {
          case "Profile":
          case "addresses":
          case "coupons":
          case "orders":
          case "gstInfo":
          case "favourites":
            navigate(Routes.AccountProfile, { state: { from } });
            break;
          case "viewed":
            navigate(Routes.Viewed, { state: { from } });
            break;
          default:
            navigate(from || Routes.Home);
        }
        toast.success(responseData.message, { toastId: '' });
      }
      
    } catch (error) {
      console.error('API Request Error:', error);

      if (!errorOccurred) {
        toast.error('Invalid Username / Invalid Password.');
        setErrorOccurred(true);
      }
    }finally{
      setLoading(false);
    }
  };

  const handleFormValuesChange = () => {
    setErrorOccurred(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const handleKeyPressForMobile = (e) => {
    const key = e.key;
    if (!(key >= '0' && key <= '9') && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      // If the pressed key is not a number (0-9), the backspace key, or the tab key
      e.preventDefault(); // Prevent typing
    }
  };

  const handleSendOtp=async(values)=>{
    setModalValue(values)
    try {
      const formData = new URLSearchParams();
      formData.append('mobile_number', values?.mobile_number);
      const responseData = await sendLoginOtp(formData);
      if (responseData.status === '1') {
        alert(responseData?.otp)
        setIsModalOpen(true)
        setTimer(120)
        setSendId(responseData?.data?.id)
      }
    } catch (error) {
      console.error('API Request Error:', error);
    }
  }

  const handleOk = async () => {
    const formData = new URLSearchParams();
    formData.append("id", sendId);
    formData.append("otp", mobileOtp);

    try {
      const response = await verifyLoginOtp(formData);
      if (response.status === "1") {
        setIsModalOpen(false);
        dispatch(clearUser());
        const { id, email, userfullname } = response.data;
        const userInfo = { id, email, userfullname, token: response.token };

        localStorage.setItem('user', JSON.stringify(userInfo));
        dispatch(addToUser(userInfo));

        if (cart.length > 0) {
          const products = cart.map((product) => ({
            product_id: product.id,
            product_variant_id: product.p_variant_id,
            quantity: product.count,
            amount: product.price,
          }));
          await addCart(products, response.token);
        }

        getCartDataFromDB(userInfo.token);
        navigate(from);
        toast.success(response.message, { toastId: '' });
      }
    } catch (error) {
      toast.error(error?.message);
    }
    setIsModalOpen(false);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTimer(0);
  };

  const handleResend=async()=>{
    try {
      const formData = new URLSearchParams();
      formData.append('mobile_number', modalValue?.mobile_number);
      const responseData = await resendLoginOtp(formData);
      if (responseData.status === '1') {
        alert(responseData?.otp)
        setIsModalOpen(true)
        setTimer(120)
      }
    } catch (error) {
      console.error('API Request Error:', error);
    }
  }

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer <= 0) {
      setTimer(0);
      setResendDisabled(false);
    } else {
      setResendDisabled(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <>
    <div className="BD_master_wrapper">
        {loading ?  <Spin /> : (
      <div className="bd_model_left">
        <h1>Sign In!</h1>
            <Form name="control-hooks" layout="vertical" onFinish={handleSubmit} autoComplete="off" onValuesChange={handleFormValuesChange}>
              <Form.Item label={<>Email</>} name="username" rules={emailRules} className="emailInput">
                <Input className="input_signin" onKeyDown={handleKeyPress} onPaste={handlePaste} ref={emailName} />
              </Form.Item>

              <Form.Item label={<>Password</>} name="password" rules={passwordRules}>
                <Input.Password className="input_signin" onKeyDown={handleKeyPress} maxLength={16} />
              </Form.Item>

              <Link to="/forgot-password" className='forget-password'>
                <div>
                  <span>Forgot password?</span>
                </div>
              </Link>

              <div className="bd_model_button">
                <button key="submit">Submit</button>
              </div>
            </Form>
        <div className="notice">
          <p>
            By continuing, you agree to Kairaus's <Link to={Routes.TermCondition}><span>Conditions</span></Link> of Use and
            {/* By continuing, you agree to Kairaus.'s{' '} */}
            {/* <Link to={Routes.Terms}>Conditions of Use</Link> and{' '} */}
            <Link to={Routes.Privacy}>Privacy Policy</Link>.
          </p>
        </div>

        <div className="Storepedia">
          <p>New to Kairaus? <Link to={Routes.SignUp} className="accountHover"><span>Create an Account</span></Link></p>
          {/* <p>New to Kairaus.? <Link to={Routes.SignUp} className="accountHover"><span>Create an Account</span></Link></p> */}
        </div>
       
      </div>
       )}
    </div>
   </>
  );
};

export default SignIn;
