import { createSlice } from '@reduxjs/toolkit';

const rewardSlice = createSlice({
  name: 'rewaedPoints',
  initialState: {},  // You can define an initial state if needed
  reducers: {
    applyReward: (state, action) => action.payload,
  },
});

export const { applyReward } = rewardSlice.actions;

export default rewardSlice.reducer;
